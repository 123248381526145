import * as React from 'react';
import Link from 'next/link';
import Gleap from 'gleap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { useUser } from 'Client/utils/hooks';
import { MastheadMenu as Menu } from 'Organisms/Masthead/Menu';
import { cpBrandName } from 'Client/constants/brand';
import { SimpleSelect } from 'Client/components/molecules';
import {
  setCustomer,
  setCustomerProjectList,
  setLoadingCustomers,
  setTeamMembers,
} from 'Client/utils/reduxReducers/projectCentre/projectCenterReducer';
import { RootState } from 'Client/redux-store';
import { OptionItem } from 'Client/types';
import { GET_PROJECTS_LIST_QUERY } from 'Client/pages/project-centre/components/ProjectsTable/ProjectsTable.gql';
import {
  decodeCustomerNameFromURL,
  formatCustomerNameForURL,
} from 'Client/components/organisms/Masthead/utils/formatCustomerNameForURL';
import { HelpIcon } from 'Atoms/Icons/Help/HelpIcon';
import { displayUserNameOrEmail, displayUserRole } from './utils';
import {
  Logo,
  LogoWrapper,
  Login,
  Wrapper,
  MenuIcon,
  RightWrapper,
  MuiButton,
  TextWrapper,
  Text,
  RoleText,
  HelpButton,
  AccessibilityHiddenHelpButton,
} from './OnboardingMasthead.styles';
import {
  GET_ALL_TEAM_MEMBERS_QUERY,
  GET_CUSTOMERS_LIST_QUERY,
} from './OnboardingMasthead.gql';
import { projectCentreSuperRoles } from './constants/superRoles';

export const OnboardingMasthead: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [gleapOpened, setGleapOpened] = React.useState<boolean>(false);
  const [firstRender, setFirstRender] = React.useState(true);
  const [customerOptions, setCustomerOptions] = React.useState<
    Array<OptionItem>
  >([]);

  const { user } = useUser();
  const router = useRouter();
  const { query } = router;
  const dispatch = useDispatch();
  const { selectedCustomer, currentPage } = useSelector(
    (state: RootState) => state.projectCentre
  );
  const { t } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const userProjects = projectCentreSuperRoles.includes(user.superRole)
    ? []
    : user.teamMemberFromUser.projects.map((p) => {
        if (
          [
            'Admin',
            'Lead admin',
            'Communications manager',
            'Data analyst',
          ].includes(p.role)
        )
          return p._id;
      });
  const { data: customerData, loading } = useQuery(GET_CUSTOMERS_LIST_QUERY, {
    variables: {
      getCustomersFromUserInput: {
        userId: user._id,
        isSuperRole: projectCentreSuperRoles.includes(user.superRole),
        projects: userProjects ? userProjects : [],
      },
    },
  });

  const { data: projectListData, refetch: ProjectListRefetch } = useQuery(
    GET_PROJECTS_LIST_QUERY,
    {
      variables: {
        getProjectsFromAdmin: {
          userId: user._id,
          customer: selectedCustomer,
          limit: 1000,
          lastIndex: 0,
          filters: {
            sort: {
              field: 'Created date',
              order: 'descending',
            },
          },
        },
      },
    }
  );

  const { data: teamMembersData, refetch: teamMembersRefetch } = useQuery(
    GET_ALL_TEAM_MEMBERS_QUERY,
    {
      variables: {
        customerId: selectedCustomer.hubspot_id,
      },
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
    }
  );

  const setInitialData = () => {
    const customers = customerData.getCustomersFromUser;
    if (!customers) {
      return;
    }
    const optionsArray = [];
    if (projectCentreSuperRoles.includes(user.superRole)) {
      optionsArray.push({
        label: 'All customers',
        value: { customer: 'All customers', hubspot_id: 'All customers' },
      });
    }
    customers.forEach((c) => {
      if (!c.customer) {
        return;
      }
      optionsArray.push({
        value: { hubspot_id: c.hubspot_id, customer: c.customer },
        label: c.customer,
      });
    });
    if (query.customer) {
      const decodedSelected = decodeCustomerNameFromURL(query.customer);
      const selected = optionsArray.filter((option) => {
        return option.value.customer === decodedSelected;
      });

      if (!selected[0]) {
        // customer not found
        router.replace({
          pathname: '/project-centre',
          query: {},
        });
        return;
      }

      dispatch(setCustomer(selected[0].value));
    }

    setCustomerOptions(optionsArray);
    if (
      ['Project list', 'Team management'].includes(currentPage) &&
      !selectedCustomer.customer &&
      !query.customer
    ) {
      handleOnCustomerChange(optionsArray[0]);
    }
  };

  const handleClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCustomerProjectList = () => {
    const { projects } = projectListData.getProjectsFromAdminV2;
    if (!customerData || !projects) {
      dispatch(setCustomerProjectList([]));
      return;
    }

    dispatch(setCustomerProjectList(projects));
  };

  const handleTeamMembers = () => {
    const teamMembers = teamMembersData.getTeamMembers;
    if (!customerData || !teamMembersData) {
      dispatch(setTeamMembers([]));
      return;
    }

    dispatch(setTeamMembers(teamMembers));
  };

  const handleOnCustomerChange = (e) => {
    router.replace({
      pathname: window.location.pathname,
      query: { customer: formatCustomerNameForURL(e.value.customer) },
    });
  };

  const handleGleapToggle = () => setGleapOpened((prev) => !prev);

  React.useEffect(() => {
    if (customerData) {
      setInitialData();
    }
  }, [customerData, currentPage, query.customer]);

  React.useEffect(() => {
    if (projectListData) {
      handleCustomerProjectList();
    }
  }, [projectListData]);

  React.useEffect(() => {
    if (teamMembersData) {
      handleTeamMembers();
    }
  }, [teamMembersData]);

  React.useEffect(() => {
    dispatch(setLoadingCustomers(loading));
  }, [loading]);

  React.useEffect(() => {
    if (selectedCustomer.customer !== 'All customers') {
      ProjectListRefetch();
      teamMembersRefetch();
      return;
    }
    dispatch(setCustomerProjectList([]));
    dispatch(setTeamMembers([]));
  }, [selectedCustomer]);

  React.useEffect(() => {
    if (firstRender) return;

    if (Gleap.isOpened()) Gleap.close();
    else Gleap.open();
  }, [gleapOpened]);

  React.useEffect(() => {
    if (!firstRender) return;
    setFirstRender(false);
  }, []);

  return (
    <Wrapper fixedPosition={isMobile}>
      <Link href="/" passHref={true}>
        <a>
          <LogoWrapper>
            <Logo
              src="https://res.cloudinary.com/commonplace-digital-limited/image/upload/v1594390595/Commonplace/commonplace-logo-horizontal-rgb.png"
              alt={t('{{cpBrandName}} logo', { cpBrandName })}
            />
          </LogoWrapper>
        </a>
      </Link>
      <RightWrapper>
        {customerOptions.length > 1 && !loading && (
          <div data-testid="simple-select-styled-react-select">
            <SimpleSelect
              options={customerOptions}
              isClearable={false}
              handleChange={(e) => handleOnCustomerChange(e)}
              placeholder="Customer"
              value={{
                value: selectedCustomer,
                label: selectedCustomer?.customer,
              }}
            />
          </div>
        )}
        <AccessibilityHiddenHelpButton onClick={handleGleapToggle}>
          <HelpButton
            icon={
              <HelpIcon
                width={22}
                height={22}
                color={'black'}
                onClick={handleGleapToggle}
              />
            }
            onClick={handleGleapToggle}
            tooltipText={t('Help')}
            data-testid="see-help-button"
            data-onboarding="helpButton"
          />
        </AccessibilityHiddenHelpButton>
        <Login>
          <MuiButton
            disableRipple
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <TextWrapper>
              <Text variant="h2">{displayUserNameOrEmail(user)}</Text>
              <RoleText variant="h2">{displayUserRole(user)}</RoleText>
            </TextWrapper>
            <MenuIcon data-onboarding="hamburgerMenu" />
          </MuiButton>
          <Menu anchorEl={anchorEl} handleClose={handleClose} />
        </Login>
      </RightWrapper>
    </Wrapper>
  );
};
