import axios from 'axios';
import {
  PlanningAppContent,
  PlanningAppPageAndContent,
} from 'Shared/types/planningapp';

export const fetchCommentsCountOfPlanApps = async ({
  projectId,
  planApps,
}: {
  projectId: string;
  planApps: PlanningAppContent[];
}): Promise<PlanningAppPageAndContent[]> => {
  try {
    const { data } = await axios.post('/api/planningapps/comments/count', {
      projectId,
      planApps,
    });

    return data?.planApps;
  } catch (error) {
    return [];
  }
};
