import { css } from 'styled-components';
import { theme as StyledTheme } from 'Client/components/theme';

export interface StyledButtonProps {
  color?: 'default' | 'purple';
}

export const getButtonColors = (
  theme: typeof StyledTheme,
  color: StyledButtonProps['color']
) => {
  return css`
    background-color: ${color === 'purple'
      ? theme.colors.purple[600]
      : theme.colors.green[500]};
    color: ${theme.colors.white[500]};
    &:hover {
      background-color: ${color === 'purple'
        ? theme.colors.purple[500]
        : theme.colors.green[600]};
    }
    &:focus {
      outline: 2px solid
        ${color === 'purple'
          ? theme.colors.purple[900]
          : theme.colors.green[900]};
    }
  `;
};
