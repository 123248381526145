import * as React from 'react';
import styled, { css } from 'styled-components';
import { LogoTransparent } from 'Atoms/Icons';

export const Wrapper = styled.div`
  width: 100%;
  height: 5rem;
  display: flex;
  box-shadow: 0 -0.375rem 0.6875rem 0 #00000017;
  background: ${({ theme }) => theme.colors.white[500]};

  span {
    display: none;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      height: auto;
      padding-bottom: 1.5rem;
    }

    ${theme.breakpoints.down('xs')} {
      flex-direction: column;
      justify-content: initial;
      height: auto;
      padding-bottom: 0.25rem;

      span {
        display: flex;
        justify-content: space-between;
        margin: 0 0.5rem 1rem;
      }
    }
  `};
`;

export const SocialIconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 10%;
  min-width: 6.5625rem;
  max-width: 9.5625rem;
  a {
    width: 2.1875rem;
    height: 2.1875rem;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      justify-content: space-between;
      width: 11.75rem;
      max-width: 11.75rem;
    }
    ${theme.breakpoints.down('xs')} {
      justify-content: space-between;
      width: 7.75rem;
    }
  `};
`;

export const LinksWrapper = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5625rem;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      align-items: baseline;
      padding: 0;
      font-size: 0.8rem;

      a {
        padding-bottom: 0.5rem;
      }
    }
  `};
`;
export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 3rem;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      margin: 0;
      justify-content: space-between;
      padding: 1rem;
    }
  `};
`;

export const CommonplaceLogo = styled(({ ...props }) => (
  <LogoTransparent {...props} width={154} height={59} viewBox="0 0 154 33" />
))`
  cursor: pointer;
`;
export const LogoLink = styled.a`
  margin: 0;
  padding: 0;
  display: flex;
`;

export const LinksDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      flex-direction: row;
      height: auto;
      align-items: flex-end;
      justify-content: space-between;
      padding: 0 1rem 2rem 1rem;

      a {
        font-weight: 600;
      }
    }
  `};
`;
