import * as React from 'react';
import styled, { css } from 'styled-components';

export const Content = styled(({ cta, ...props }) => <div {...props} />)`
  ${({ cta, theme }) => css`
    margin: 1rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    ${theme.breakpoints.up('sm')} {
      margin: 1rem 0.25rem;
      margin-bottom: 0;
      flex-direction: ${cta ? 'column' : 'row'};

      > div + div {
        margin-left: 2rem;
      }
    }

    ${theme.breakpoints.down('sm')} {
      margin: 1rem 0;
      flex-direction: ${cta ? 'column' : 'row'};
    }
  `}
`;
