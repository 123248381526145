import styled, { css } from 'styled-components';
import { readableColor } from 'polished';
import {
  CardTitle as ProjectCardTitle,
  CardContent,
  CardFocusLink,
} from 'Atoms/Card/ProjectCard/ProjectCard.styles';
import { editModeMobile } from 'Client/components/theme/utils';

export const HorizontalProposalsReelWrapper = styled.div`
  margin: 0;
  padding-top: 20px;
`;

export const ItemsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    ${editModeMobile(
  theme.breakpoints.down('xs'),
  css`
        white-space: nowrap;
      `
)}

    .ScrollbarsCustom-Scroller {
      position: relative !important;
      padding-top: 0.5rem;
    }

    .ScrollbarsCustom-Wrapper {
      position: relative !important;
    }

    .ScrollbarsCustom-Content {
      min-width: auto !important;
    }
  `};
`;

export const CardWrapper = styled.div`
  position: relative;
`;

export const CardTopSection = styled.div`
  display: flex;
  flex-direction: row;
  height: 6.25rem;

  gap: 0.5rem;
  position: relative;
  overflow: hidden;
`;

export const Card = styled.a`
  height: unset;
  z-index: 99;
  border-radius: 0.4rem;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0.5rem 0px;
  &:hover {
    cursor: pointer;
  }
`;

export const CardTitle = styled(ProjectCardTitle)`
  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      font-size: 0.9rem;
    }
  `}
`;

export const PlanningAppImage = styled.img`
  height: 100px;
  object-fit: cover;

  ${({ theme }) => css`
    ${theme.breakpoints.up('xs')} {
      max-width: 150px;
      min-width: 150px;
    }
    ${theme.breakpoints.down('xs')} {
      min-width: 95px;
      max-width: 95px;
    }
  `}
`;

export const PlanningAppCardLeftSide = styled(CardContent) <{
  hasDescription: boolean;
}>`
  ${({ theme, hasDescription }) => css`
    height: 100%;
    display: flex;
    flex-direction: column;
    ${theme.breakpoints.up('xs')} {
      width: ${hasDescription ? '30%' : '70%'};
      gap: 0.2rem;
      padding: 1rem 0;
      padding-left: 1.5rem;
    }
    ${theme.breakpoints.down('xs')} {
      width: 65%;
      gap: 0.1rem;
      padding: 0.5rem 0;
    }

    margin: 0;
    :after {
      content: none;
    }
    & > h3 {
      position: relative;
      pointer-events: none;
      :after {
        background: none;
      }
      ${theme.breakpoints.up('xs')} {
        white-space: normal;
      }
      ${theme.breakpoints.down('xs')} {
        white-space: pre-line;
        max-height: 4rem;
        overflow: hidden;
        :after {
          content: '';
          height: 2.5rem;
          position: absolute;
          left: 0;
          bottom: 0;
          right: -1rem;
          background: linear-gradient(
            -90deg,
            ${theme.colorMappings.white},
            rgba(255, 255, 255, 0)
          );
        }
      }
    }
  `}
`;

export const PlanningAppCardRightSide = styled.div<{ hasDaysLeft?: boolean }>`
  ${({ theme, hasDaysLeft }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${theme.breakpoints.up('xs')} {
      width: 20%;
      position: relative;
      padding: 1rem 0;
    }
    ${theme.breakpoints.down('xs')} {
      width: 35%;
      position: unset;
      padding: ${hasDaysLeft ? '3rem' : '1rem'} 0.5rem 1rem 0;
    }
  `}
`;

export const AddressContainer = styled.p<{
  hasDescription: boolean;
}>`
  width: ${({ hasDescription }) => (hasDescription ? '80%;' : '100%')};
  margin: 0;
`;

export const DescriptionContainer = styled(CardContent) <{
  hasDescription?: boolean;
}>`
  ${({ theme, hasDescription }) => css`
    margin: 0;
    padding-left: 0.5rem;
    white-space: pre-line;

    ${theme.breakpoints.up('xs')} {
      width: ${hasDescription ? '40%;' : '0'};
      padding: 1rem 0;
      height: 100%;
      display: unset;
    }
    ${theme.breakpoints.down('xs')} {
      border-top: 1px solid #e8e8e8;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      width: 100%;
      padding: 0.5rem 1rem;
      height: fit-content;
    }
  `}
`;

export const DaysLeft = styled.div<{ color: string }>`
  ${({ theme, color }) => css`
    font-size: 0.75rem;
    background-color: ${color};
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 1.5rem;
    padding: 0.5rem;
    gap: 0.2rem;
    position: absolute;
    ${theme.breakpoints.up('xs')} {
      top: 1rem;
      right: unset;
      border-radius: 0.25rem;
    }
    ${theme.breakpoints.down('xs')} {
      top: 0;
      right: 0;
      border-radius: 0 0 0.25rem 0.25rem;
    }

    & > strong,
    p {
      margin: 0;
      color: ${readableColor(color)};
    }
  `}
`;

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  ${({ theme }) => css`
    ${theme.breakpoints.up('xs')} {
      gap: 0.2rem;
    }
  `}
`;

export const StatusLabel = styled.p`
  ${({ theme }) => css`
    margin: 0;
    font-size: 0.8rem;
    color: ${theme.colors.black[500]};
    align-self: center;
    ${theme.breakpoints.up('xs')} {
      width: unset;
    }

    ${theme.breakpoints.down('xs')} {
      white-space: pre-line;
      text-align: center;
      width: 100%;
    }
  `}
`;

export const CommentSection = styled(CardFocusLink)`
  ${({ theme }) => css`
    padding: 1rem 0;
    ${theme.breakpoints.up('xs')} {
      width: 10%;
    }
    ${theme.breakpoints.down('xs')} {
      width: 15%;
    }
  `}
`;

export const CardCommentContainer = styled.div`
  height: 100%;
  border: 0;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: ${({ theme }) => theme.colorMappings.primaryButtonBackground};
    margin: 0;
    font-size: 0.7rem;
    font-weight: bold;
    color: black;
    text-align: center;
    ${({ theme }) => theme.breakpoints.down('xs')} {
      width: unset;
    }
  }
`;

export const CommentsWrapper = styled.div<{ withText: boolean }>`
  display: flex;
  flex-direction: ${({ withText }) => (withText ? 'column' : 'row')};
  align-items: center;
  text-align: center;

  svg {
    margin-right: 0.5rem;
  }
`;

export const CommentsText = styled.p`
  margin: 0;
  font-size: 0.7rem;
  font-weight: bold;
  color: black;
`;

export const CategoryWrapper = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  width: 100%;
  height: 2.688rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

export const CardCategory = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    ${theme.breakpoints.up('xs')} {
      width: 25%;
    }
    ${theme.breakpoints.down('xs')} {
      width: 85%;
    }
    position: relative;
    gap: 0.5rem;
  `}
`;

export const IconContainer = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  margin-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  ${({ theme }) => css`
    ${theme.breakpoints.up('xs')} {
      width: 20%;
    }
    ${theme.breakpoints.down('xs')} {
      width: 15%;
    }
  `}
`;

export const AdditionalInfo = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    ${theme.breakpoints.up('xs')} {
      width: 25%;
    }
    ${theme.breakpoints.down('xs')} {
      width: 35%;
    }
  `}
`;

export const StageInfo = styled.div`
  ${({ theme }) => css`
    ${theme.breakpoints.up('xs')} {
      width: 30%;
    }
    ${theme.breakpoints.down('xs')} {
      width: 100%;
    }

    & > strong {
      display: flex;
      align-items: center;
      ${theme.breakpoints.up('xs')} {
        color: black;
        font-weight: 700;
        justify-content: center;
      }
      ${theme.breakpoints.down('xs')} {
        color: #585858;
        font-weight: 500;
        justify-content: flex-start;
        flex-wrap: wrap;
      }
    }
    & > strong > p {
      margin: 0;
      font-weight: 500;
      color: black;
    }
  `};
`;

export const ReferenceInfo = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    ${theme.breakpoints.up('xs')} {
      width: 30%;
      color: black;
      font-size: 0.875rem;
    }

    ${theme.breakpoints.down('xs')} {
      font-size: 0.7rem;
      color: grey;
      width: 100%;
    }
  `}
`;
