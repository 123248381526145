import { MilestoneStageType } from '..';

type T = (key: string, options?: Record<string, unknown>) => string;

export const getDefaultEndDate =
  (t: T) =>
  (stage: string): string => {
    switch (stage) {
      case MilestoneStageType.ACTIVE:
        return t('In Progress');
      case MilestoneStageType.COMPLETED:
        return t('Complete');
      case MilestoneStageType.PLANNED:
        return t('Upcoming');
      default:
        return '';
    }
  };
