import { User } from 'Shared/types/user';

export const displayUserNameOrEmail = (user: User): string => {
  const { firstName, lastName, email } = user;
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }

  if (firstName) {
    return firstName;
  }

  return email;
};
