import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  min-width: 78rem;
  height: 7.25rem;
  background-color: ${({ theme }) => theme.colors.white[500]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6875rem 2.8125rem 1.6875rem 3.9375rem;
`;

export const TitleContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const Title = styled.p`
  margin: 0;
  padding: 0;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.5rem;
  color: ${({ theme }) => theme.colors.black[500]};
`;
export const Subtitle = styled.p`
  margin: 0;
  padding: 0;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.375rem;
  color: ${({ theme }) => theme.colors.green.A700};
`;
export const Text = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.4375rem;
`;
