import styled, { css } from 'styled-components';
import { meetsContrastGuidelines } from 'polished';
import { TextField as TextFieldBase, Button } from 'Molecules';
import { editModeMobile } from 'Client/components/theme/utils';

export const NewsSubscription = styled.div`
  z-index: 1000;
  margin-top: 0.5625rem;
  width: fit-content;
  .MuiInputBase-root {
    width: 18.75rem;
    height: 3.1875rem;
  }
  .MuiTextField-root,
  .MuiFormControl-root,
  .MuiInputBase-root {
    ${({ theme }) =>
      editModeMobile(
        theme.breakpoints.down('xs'),
        css`
          width: 100%;
          z-index: 1;
        `
      )}
  }
`;

export const TextField = styled(TextFieldBase)`
  ${({ theme }) => {
    const { brand, error } = theme.colorMappings;
    const isAccessible = meetsContrastGuidelines(brand, error);

    if (!isAccessible.AA) {
      return css`
        border-bottom-right-radius: 0.3125rem;
        border-bottom-left-radius: 0.3125rem;

        .MuiFormHelperText-root.Mui-error {
          color: ${theme.colors.black[500]};
        }
      `;
    }
  }}
`;

export const SubscriptionButton = styled(Button)`
  ${({ theme }) => css`
    margin: 0 0.5625rem;
    border-radius: 0.1875rem;
    z-index: 5;
    font-size: 1.125rem;
    padding: 0.5rem 1.875rem;

    &:disabled {
      cursor: not-allowed;
      pointer-events: all;
      &:hover {
        background-color: ${theme.colorMappings.disabledButtonBackground};
      }
    }
  `}
  ${({ theme }) =>
    editModeMobile(
      theme.breakpoints.down('xs'),
      css`
        width: 100%;
        margin: 0.5625rem 0;
      `
    )}
`;
