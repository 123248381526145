import * as React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useEditModeLayout } from 'Client/context/editModeLayoutContext';
import { useEditModeContext } from 'Client/utils/hooks';
import { VisualizerComponentProps } from './types';
import { ImageAlignmentTypes, ImageOrientationTypes } from '../../types';
import {
  DescriptionContainer,
  ImageContainer,
  Wrapper,
} from './ImageAndTextVisualizer.styles';
import { getImageUrlWidthBased } from './utils';

interface ImageAndTextVisualizeProps extends VisualizerComponentProps {
  alignment: ImageAlignmentTypes;
}

export const ImageAndTextVisualizer: React.FC<ImageAndTextVisualizeProps> = ({
  windowHeight,
  files,
  alignment,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [isEditModeLayout] = useEditModeLayout();
  const [editModeState] = useEditModeContext();
  const editModeMobileLayout =
    isEditModeLayout && editModeState.editModeLayout.previewMode === 'mobile';

  const imageContainerRef = React.useRef(null);

  const file = files[0];
  if (!file) return null;
  const { alt, url, description, orientation } = file;

  const mobileView = editModeMobileLayout || isMobile;
  const isPortrait = orientation === ImageOrientationTypes.PORTRAIT;
  const isVerticallyAligned =
    mobileView ||
    alignment === ImageAlignmentTypes.TOP ||
    alignment === ImageAlignmentTypes.BOTTOM;

  /* 30% of the screen height */
  const mobileWindowHeight = windowHeight - windowHeight * 0.7;
  /* The size of the description height with 1.5k characters (our max height for images) in px */
  const textMaxCharactersHeight = isVerticallyAligned ? 450.2672 : 715.333;

  const imgWidth = isPortrait ? null : imageContainerRef?.current?.clientWidth;
  const imgHeight = isPortrait
    ? mobileView
      ? mobileWindowHeight
      : textMaxCharactersHeight
    : null;

  const horizontalColumnHeight = mobileView
    ? mobileWindowHeight
    : textMaxCharactersHeight;

  const verticalColumnHeight = mobileView ? imgHeight : textMaxCharactersHeight;

  const columnHeight = mobileView
    ? null
    : isVerticallyAligned
    ? horizontalColumnHeight / 16
    : verticalColumnHeight / 16;

  return (
    <Wrapper
      alignment={alignment}
      isMobile={mobileView}
      columnHeight={columnHeight}
    >
      <ImageContainer
        ref={imageContainerRef}
        orientation={orientation}
        columnHeight={columnHeight}
      >
        <img
          alt={alt}
          src={getImageUrlWidthBased({
            width: Math.ceil(imgWidth),
            height: Math.ceil(imgHeight),
            url,
          })}
        />
      </ImageContainer>
      <DescriptionContainer>{description}</DescriptionContainer>
    </Wrapper>
  );
};
