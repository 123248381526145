import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: 100%;
  background-color: ${({ theme }) => theme.colorMappings.white};
`;

export const Content = styled.div`
  margin: 0 auto;
  width: 100%;
  min-height: 100%;
`;

export const Header = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.proposals.questionsHeader.height};
  background-color: ${({ theme }) =>
    theme.proposals.questionsHeader.backgroundColor};
  padding: 0.5rem 2rem;
  display: flex;

  span {
    align-self: center;
  }

  button {
    align-self: center;
    margin-left: auto;
  }
`;
