import { theme } from 'Client/components/theme';
import { ReadableHeroLayout, ReadableHeroTypes } from './types';

export const setStylesToHighlightedText = (layout: ReadableHeroLayout) => {
  const descriptionElement = document.querySelector('.hub-hero-description');
  const textNodes = [];
  const getTextNodes = (node: Element) => {
    if (!node) return;
    if (node?.nodeType === Node.TEXT_NODE) return textNodes.push(node);
    const childNodes = node?.childNodes || [];
    return Array.from(childNodes).map((childNode) =>
      getTextNodes(childNode as Element)
    );
  };
  const addBackgroundColorToElement = (element) => {
    element.style.backgroundColor =
      layout.type === ReadableHeroTypes.IMAGE_AND_HIGHLIGHTED_TEXT
        ? theme.colorMappings.brand
        : 'transparent';
    element.style.opacity = layout.transparentBackground ? '0.9' : '1';
  };
  getTextNodes(descriptionElement);
  return textNodes.forEach((textNode) => {
    const { parentElement } = textNode;
    return addBackgroundColorToElement(parentElement);
  });
};
