import * as React from 'react';
import styled, { css } from 'styled-components';

export const Content = styled(({ isDesktop, ...props }) => <div {...props} />)`
  margin-top: ${({ isDesktop }) => (isDesktop ? '' : '4.375rem')};
  height: calc(100vh - 4.735rem - 4.375rem);
  overflow: auto;
  ${({ theme }) => css`
    background-color: ${theme.colors.white[500]};
  `}
`;
