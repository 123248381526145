import * as React from 'react';
import { projectStagesForBanner } from 'Atoms/StageBanner';
import { Masthead } from 'Organisms';
import { useProject, useUser } from 'Client/utils/hooks';
import { defaultRoleArray, noAdminRoleArray } from 'Client/constants/roles';
import { useBanner } from 'Client/utils/hooks/useBanner';
import { Wrapper, Content } from './EditorPages.styles';
import { EditorPagesTemplateProps } from '.';

export const EditorPagesTemplate: React.FC<EditorPagesTemplateProps> = ({
  greyBackground,
  children,
}: EditorPagesTemplateProps) => {
  const { user } = useUser();
  const project = useProject();

  const isBannerShown =
    projectStagesForBanner.includes(project?.stage) &&
    (defaultRoleArray.includes(user?.role?.name) ||
      noAdminRoleArray.includes(user?.role?.name));

  const { errorBannerHeight, stageBannerHeight } = useBanner();

  return (
    <Wrapper greyBackground={greyBackground} dir="ltr">
      <Masthead project={project} isEditMode />
      <Content
        isBannerShown={isBannerShown}
        errorBannerHeight={errorBannerHeight}
        stageBannerHeight={stageBannerHeight}
      >
        {children}
      </Content>
    </Wrapper>
  );
};

export default EditorPagesTemplate;
