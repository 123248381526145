import * as React from 'react';
import { MenuIcon } from 'Atoms/Icons';
import { Container, Indicator } from './styles';

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  filtersActive: number;
  children?: React.ReactNode;
}

export const FiltersButton: React.FC<Props> = ({
  filtersActive,
  children,
  ...props
}) => {
  const isActive = filtersActive > 0;

  return (
    <Container active={isActive} {...props}>
      {isActive && <Indicator>{filtersActive}</Indicator>}
      <MenuIcon />
      {children}
    </Container>
  );
};
