import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  height: 100%;
  padding: 2rem 0;
  width: 100%;
`;

export const DisplayContainer = styled.div<{
  width: number;
  height: number;
  editMode: boolean;
}>`
  ${({ width }) => `width: ${width}px;`}
  ${({ height }) => `height: ${height}px;`}
  position: relative;
  &:hover {
    & > span {
      ${({ theme, editMode }) => css`
        ${theme.breakpoints.down('sm')} {
          background-color: transparent;
          & > svg {
            background-color: rgba(0, 0, 0, 0.8);
          }
        }
        ${theme.breakpoints.up('sm')} {
          ${!editMode &&
          `
          background-color: rgba(217, 217, 217, 0.6);
          & > svg {
            background-color: transparent;
          }
          `}
        }
      `};
    }
  }
`;

export const FilesContainer = styled.div<{
  marginRight: number;
  imagesGap: number;
}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  left: ${({ marginRight }) => -marginRight || 0}px;
  position: absolute;
  gap: ${({ imagesGap }) => imagesGap || 2.5}rem;
  transition: left 0.3s ease-in-out;
`;

export const CarouselItem = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;

export const ImageButton = styled.span<{
  side: string;
  height: number;
  disabled: boolean;
}>`
  ${({ theme, height, disabled, side }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.4s ease;
    height: ${height}px;
    ${disabled && 'display: none;'}
    ${side === 'left'
      ? `left: 0; & > svg {transform: scaleX(-1);}`
      : `right: 0;`}
  top: 0;
    z-index: 1;

    cursor: pointer;
    position: absolute;
    background-color: transparent;
    text-align: center;

    ${theme.breakpoints.down('sm')} {
      width: 3rem;
    }
    ${theme.breakpoints.up('sm')} {
      width: 5rem;
    }

    & > svg {
      transition: 0.4s ease;
      border-radius: 50%;
      padding: 5px;

      ${theme.breakpoints.down('sm')} {
        background-color: rgba(0, 0, 0, 0.8);
      }
      ${theme.breakpoints.up('sm')} {
        background-color: rgba(0, 0, 0, 0.6);
      }
    }
  `};
`;

export const DisplayCircles = styled.div`
  background-color: #f1f1f1;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.3125rem;
  padding: 10px;
`;

export const Circle = styled.div<{ filled: boolean }>`
  ${({ theme, filled }) => css`
    border-radius: 50%;
    width: 15px;
    height: 15px;
    border: 2px solid ${theme.colorMappings.brand};
    background-color: ${filled ? theme.colorMappings.brand : 'transparent'};
    &:hover {
      cursor: pointer;
    }
  `}
`;
