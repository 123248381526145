import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { userHasPermission } from 'Client/utils/user';
import { useUser } from 'Client/utils/hooks';
import { Permissions } from 'Client/constants/permissions';
import { NewsPostListItem } from 'Molecules';
import { HubHeader } from 'Client/components/organisms/HubHeader';
import { NewsPostAcorn, NewsPostGaudi } from 'Shared/types/news';
import { NewsSectionProps } from './types';

const News: React.FC<NewsSectionProps> = ({
  newsPosts,
  isHub,
}: NewsSectionProps) => {
  const { t } = useTranslation();
  const { user } = useUser();

  const showPanel =
    userHasPermission(user, Permissions.UPDATE_NEWS) ||
    userHasPermission(user, Permissions.ADD_NEWS);

  return (
    <>
      <Header>
        <HubHeader
          title={t('Latest news')}
          anchorText={t('More news')}
          path="news"
        />
      </Header>
      {newsPosts?.map((newsPost) => (
        <NewsPostListItem
          key={newsPost._id}
          id={newsPost._id}
          date={newsPost.date}
          slug={newsPost.slug}
          headline={
            (newsPost as NewsPostGaudi).headline ||
            (newsPost as NewsPostAcorn).content?.headline
          }
          copy={
            (newsPost as NewsPostGaudi).copy ||
            (newsPost as NewsPostAcorn).content?.content
          }
          author={
            (newsPost as NewsPostGaudi).by ||
            (newsPost as NewsPostAcorn).content?.author
          }
          sentEmailCount={
            (newsPost as NewsPostGaudi).sentUpdateEmailCount?.count ||
            (newsPost as NewsPostAcorn).sentEmailCount?.count
          }
          newsImage={newsPost.newsImage}
          newsImageAlt={newsPost.newsImageAlt}
          isHub={isHub}
          showEditPanel={showPanel}
          showExcerptOnMobile
          preview
        />
      ))}
    </>
  );
};

const Header = styled.div`
  margin-bottom: 1.8rem;
`;

export { News };
