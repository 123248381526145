import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'Atoms';
import { FaqIcon } from 'Icons';
import { Wrapper } from './Heading.styles';
import { BannerHeadingProps as Props } from '../types';

export const Heading: React.FC<Props> = ({ cta, agree, title }: Props) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      {cta && <FaqIcon bold />}
      <Typography variant="h2">
        {cta
          ? agree
            ? t('No comments or agreements yet')
            : t('No comments yet')
          : t('{{ title }} contributions', {
              title,
            })}
      </Typography>
    </Wrapper>
  );
};
