import * as React from 'react';
import styled, { css } from 'styled-components';
import MuiCardMedia from '@material-ui/core/CardMedia';
import { editModeMobile } from 'Client/components/theme/utils';

export const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  background-color: ${({ theme }) => theme.colors.white[500]};
  border-style: solid;
  border-color: ${({ theme }) => theme.colorMappings.greyBorderLight};
  border-width: 0.0625rem 0.0625rem 0 0.0625rem;

  &:last-child {
    border-bottom: 0.0625rem solid
      ${({ theme }) => theme.colorMappings.greyBorderLight};
  }
`;

export const MediaWrapper = styled.div`
  min-width: 6.25rem;
  cursor: pointer;
`;

export const DocumentInfo = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  padding-right: 1rem;

  * + * {
    margin-left: 1rem;
    margin-right: 0;
  }

  ${({ theme }) =>
    editModeMobile(
      theme.breakpoints.down('xs'),
      css`
        padding-right: 0.5rem;
        justify-content: space-between;
      `
    )}
`;

export const InfoWrapper = styled.div<{
  percentage?: number;
  align: 'left' | 'right';
}>`
  display: flex;
  justify-content: ${({ align }) =>
    align == 'left' ? 'flex-start' : 'flex-end'};

  & + & {
    margin-left: 1rem;
  }

  ${({ percentage }) =>
    percentage &&
    css`
      width: ${percentage}%;
    `}

  ${({ theme }) =>
    editModeMobile(
      theme.breakpoints.down('xs'),
      css`
        display: none;
      `
    )}
`;

export const Title = styled.b`
  word-break: break-all;
  font-size: 0.875rem;
  width: 8rem;
`;

export const DescriptionWrapper = styled.div<{
  percentage?: number;
  noThumbnail?: boolean;
}>`
  flex-wrap: 1;
  margin: 0 0 0 1.75rem;
  padding: 0.5rem 0;
  cursor: pointer;
  ${({ percentage }) =>
    percentage &&
    css`
      width: ${percentage}%;
    `}

  & p {
    word-break: break-all;
    text-align: start;
    font-weight: bold;
  }

  ${({ noThumbnail }) =>
    noThumbnail &&
    css`
      margin-right: 4rem;
    `}

  ${({ theme }) =>
    editModeMobile(
      theme.breakpoints.down('xs'),
      css`
        margin: 0 0 0 0.75rem;
        padding-right: unset;
      `
    )}
`;

export const ButtonWrapper = styled.div`
  cursor: pointer;
  ${({ theme }) =>
    editModeMobile(
      theme.breakpoints.down('xs'),
      css`
        display: none;
      `
    )}
`;

export const ButtonWrapperMobile = styled.div<{ isOpen: boolean }>`
  display: none;
  padding: 1rem;

  ${({ theme }) =>
    editModeMobile(
      theme.breakpoints.down('xs'),
      css`
        display: flex;
        align-items: center;
        cursor: pointer;
      `
    )}

  ${({ isOpen }) =>
    isOpen
      ? css`
          transform: rotate(90deg);
        `
      : css`
          transform: rotate(-90deg);
        `}
`;

export const Text = styled.b`
  word-break: break-all;
`;

export const CardMedia = styled((props) => <MuiCardMedia {...props} />)`
  width: 6.25rem;
  height: 4.375rem;
`;

export const PDFIframe = styled.iframe`
  width: 100%;
`;
