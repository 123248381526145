import styled, { createGlobalStyle, css } from 'styled-components';

export const GlobalStyle = createGlobalStyle<{ isMenuOpen?: boolean }>`
${({ isMenuOpen }) => css`
  body {
    height: 100vh;
    overflow: ${isMenuOpen ? 'hidden' : 'unset'};
    touch-action: ${isMenuOpen ? 'none' : 'unset'};
  }
`}
`;

export const FiltersWrapper = styled.div<{ isMenuOpen?: boolean }>`
  ${({ isMenuOpen }) => css`
    display: flex;
    flex-direction: ${isMenuOpen ? 'column' : 'row'};
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.5rem;
    width: 100%;

    @media (max-width: 1024px) {
      justify-content: space-between;
      align-items: flex-end;
    }
  `}
`;

export const ClearFilters = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    border-radius: 0.25rem;
    padding: 0.625rem 1.25rem;
    border: none;
    background: none;
    cursor: pointer;

    color: ${theme.colorMappings.greyButtonLabel};
    font-size: 0.938rem;
    font-weight: 400;
    line-height: 1.25rem; /* 133.333% */

    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: #f4f6fa;
    }
  `};
`;

export const ApplyFilters = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    border: none;
    background: ${theme.colorMappings.brand};
    cursor: pointer;
    padding: 0.625rem 1.25rem;
    border-radius: 0.25rem;

    color: ${theme.colorMappings.greyButtonLabel};
    text-align: center;
    font-size: 0.938rem;
    font-weight: 600;
    line-height: 1.25rem; /* 133.333% */
  `};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 2.5rem;
`;

export const FiltersContainer = styled.div<{ isMenuOpen?: boolean }>`
  ${({ theme, isMenuOpen }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.5rem;
    margin-bottom: 0.5rem;

    @media (max-width: 1024px) {
      align-items: unset;
      ${isMenuOpen
        ? css`
            display: flex;
            -webkit-box-pack: start;
            justify-content: center;
            overflow-y: scroll;
            -webkit-box-align: center;
            align-items: stretch;
            z-index: 1201;
            background-color: ${theme.colors.white[500]};
            border-radius: 1.5rem;
            box-shadow: 0rem -0.375rem 1rem 10rem rgba(0, 0, 0, 0.5);
            width: 100%;
            border-radius: 1.875rem 1.875rem 0rem 0rem;
            position: fixed;
            height: 80vh;
            top: 20vh;
            left: 0rem;
          `
        : css`
            padding: 0 1.25rem;
          `}
    }
  `}
`;

export const FiltersHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  > svg {
    cursor: pointer;
  }

  @media (max-width: 1024px) {
    align-items: unset;
  }
`;

export const DrawerDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.25rem;
  margin-bottom: 50px;
  margin-top: 80px;
`;
