import * as React from 'react';
import { useRouter } from 'next/router';
import { captureException } from '@sentry/react';
import { useLazyQuery } from '@apollo/client';
import { useProject } from 'Client/utils/hooks';
import { HubHeader } from 'Organisms';
import { filterProgrammes } from 'Client/services/programme';
import { OptionItem } from 'Client/types';
import { GET_PROGRAMMES } from 'Client/services/programme/getProgrammes.gql';
import { ProgrammeReel } from './ProgrammeReel';
import { CustomFilters, ProgramWrapperProps, ProgrammeFilters } from './types';

export const ProgrammeWrapper: React.FC<ProgramWrapperProps> = (
  props: ProgramWrapperProps
) => {
  const [getProgrammes] = useLazyQuery(GET_PROGRAMMES, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });

  const { locale } = useRouter();
  const {
    content: { showCardsCounter, rules, order, label, categories },
  } = props;
  const { features, customer } = useProject();
  const [projects, setProjects] = React.useState([]);
  const [filters, setFilters] = React.useState<ProgrammeFilters>({
    ward: null,
    status: null,
    postcode: null,
  });
  const [projectsWards, setProjectsWards] = React.useState<OptionItem[]>([]);
  const [customFiltersValues, setCustomFiltersValues] = React.useState<
    Record<string, OptionItem>
  >({});
  const [allFiltersValuesFromProjects, setAllFiltersValuesFromProjects] =
    React.useState<string[]>([]);

  const convertFiltersToOptions = React.useCallback(
    (filters: CustomFilters) => {
      return Object.entries(filters).map(([key, value]) => ({
        name: key,
        options: value
          .filter((item) => allFiltersValuesFromProjects.includes(item))
          .map((item) => ({
            label: item,
            value: item,
          })),
      }));
    },
    [allFiltersValuesFromProjects]
  );

  const customFilterOptions = React.useMemo(() => {
    if (!categories) {
      return [];
    }

    return convertFiltersToOptions(categories);
  }, [categories, convertFiltersToOptions]);

  const handleChanges = (name: string, option?: OptionItem) => {
    setFilters({
      ...filters,
      [name]: option,
    });
    setCustomFiltersValues({
      ...customFiltersValues,
      [name]: option,
    });
  };

  const handleClearFilters = () => {
    setFilters({
      ward: null,
      status: null,
      postcode: null,
    });
    setCustomFiltersValues(
      Object.keys(customFiltersValues)?.reduce((acc, key) => {
        acc[key] = null;
        return acc;
      }, {})
    );
  };

  const getRules = () => {
    const newRules = {
      ...rules,
    };

    if (filters?.status?.value) {
      newRules.stage = {
        condition: 'is-equal-to',
        value: filters?.status?.value,
      };
    }

    if (filters?.ward?.value) {
      newRules['newGeocode.admin_ward'] = {
        condition: 'is-equal-to',
        value: filters?.ward?.value,
      };
    }

    const customFiltersValuesKeys = Object.keys(customFiltersValues);

    if (customFiltersValuesKeys.length) {
      customFiltersValuesKeys.forEach((key) => {
        if (customFiltersValues[key]?.value) {
          newRules[`categories.${key}`] = {
            condition: 'is-in-array',
            value: customFiltersValues[key]?.value,
          };
        }
      });
    }

    return newRules;
  };

  const handleData = async () => {
    try {
      const customFilters = categories
        ? Object.keys(categories).map((filter) => `categories.${filter}`)
        : [];

      const initialLength = projects.length;
      let projectsLength = projects.length;
      let requestNumber = 0;
      do {
        const { data } = await getProgrammes({
          variables: {
            getProgrammesInput: {
              rules: getRules(),
              order,
              lang: locale,
              customFilters,
              customerName: customer,
            },
          },
        });
        projectsLength = data.getProgrammes.length;
        requestNumber++;
      } while (projectsLength !== initialLength && requestNumber <= 2);
      requestNumber = 0;

      const { data } = await getProgrammes({
        variables: {
          getProgrammesInput: {
            rules: getRules(),
            order,
            lang: locale,
            customFilters,
            customerName: customer,
          },
        },
      });
      const _projects = data.getProgrammes;
      const isAnyFilterActive =
        filters.ward || filters.status || filters.postcode;
      const isAnyCustomFilterActive = Object.values(customFiltersValues)?.some(
        (filter) => filter?.value
      );

      if (!isAnyFilterActive && !isAnyCustomFilterActive) {
        const wards = _projects.map(
          (project) => project?.newGeocode?.admin_ward
        );
        const uniqueWards = [...new Set(wards)].filter(Boolean).sort();
        const wardOptions = uniqueWards.map((ward) => ({
          label: ward,
          value: ward,
        })) as OptionItem[];
        setProjectsWards(wardOptions);

        const allCategoryValuesArray = _projects
          .filter((project) => project.categories)
          .map((project) => Object.values(project?.categories))
          .flat(2) as string[];

        setAllFiltersValuesFromProjects(
          [...new Set(allCategoryValuesArray)].sort()
        );
      }

      const filteredProjects = await filterProgrammes(filters, _projects);
      setProjects(filteredProjects);
    } catch (error) {
      captureException(error);
    }
  };

  React.useEffect(() => {
    handleData();
  }, [rules, filters]);

  const hasAnyFilterFeatureActive =
    features?.projectsFiltersWard ||
    features?.projectsFiltersStatus ||
    features?.projectsFiltersPostcode;

  return (
    <div>
      <HubHeader title={label} anchorText="" path="" />
      <ProgrammeReel
        showCardsCounter={showCardsCounter}
        projects={projects}
        displayFilters={hasAnyFilterFeatureActive}
        handleChanges={handleChanges}
        handleClearFilters={handleClearFilters}
        filters={filters}
        projectsWards={projectsWards}
        customFiltersValues={customFiltersValues}
        customFiltersOptions={customFilterOptions}
      />
    </div>
  );
};
