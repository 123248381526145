import * as React from 'react';
import { EditIcon, TrashSimpleIcon } from 'Atoms/Icons';
import { Permissions } from 'Client/constants/permissions';
import { User } from 'Shared/types';
import {
  ActionParams,
  PermissionsParams,
} from 'Client/pages/project-centre/types';
import { makeRedirectDomain } from 'Client/utils/url';
import { ShareIcon } from '../components/ProjectRow/ProjectRow.styles';

export const teamMemberPermissionsCheck = (
  user: User,
  project: { _id: string; stage?: string },
  permission: string
) => {
  const currentProject = user?.teamMemberFromUser?.projects.find(
    (p) => String(p._id) === String(project._id)
  );
  return Boolean(currentProject?.permissions?.includes(permission));
};

export const actions = [
  {
    name: 'Edit',
    icon: <EditIcon />,
    action: ({ projectId }: ActionParams) => {
      const newWindow = window.open(
        `https://${makeRedirectDomain(projectId)}/edit`,
        '_blank',
        'noopener,noreferrer'
      );
      if (newWindow) newWindow.opener = null;
    },
    permissionsCheck: (permissions: PermissionsParams) => {
      const { user, project } = permissions;

      if (
        ['technical-support', 'customer-success', 'sales'].includes(
          user.superRole
        ) ||
        teamMemberPermissionsCheck(user, project, Permissions.EDIT_PROJECT)
      ) {
        return true;
      }
    },
  },
  {
    name: 'Share',
    icon: <ShareIcon />,
    action: ({ handleShareModal }: ActionParams) => {
      handleShareModal();
    },
    permissionsCheck: () => true,
  },
  {
    name: 'Delete',
    icon: <TrashSimpleIcon />,
    action: ({ handleDeleteModal }: ActionParams) => {
      handleDeleteModal();
    },
    permissionsCheck: (permissions: PermissionsParams) => {
      const { user, project } = permissions;

      if (
        project.stage === 'testing' &&
        (['technical-support', 'customer-success', 'sales'].includes(
          user.superRole
        ) ||
          teamMemberPermissionsCheck(user, project, Permissions.DELETE_PROJECT))
      ) {
        return true;
      }
    },
  },
];
