import { gql } from '@apollo/client';

export const GET_CUSTOMERS_LIST_QUERY = gql`
  query GetCustomersFromUser(
    $getCustomersFromUserInput: GetCustomersFromUserInput
  ) {
    getCustomersFromUser(
      getCustomersFromUserInput: $getCustomersFromUserInput
    ) {
      hubspot_id
      customer
      projects {
        _id
        name
      }
    }
  }
`;

export const GET_ALL_TEAM_MEMBERS_QUERY = gql`
  query GetTeamMembers($customerId: String) {
    getTeamMembers(customerId: $customerId) {
      _id
      email
      firstName
      lastName
      projects {
        _id
        role
        scope
      }
      customers {
        _id
        role
        scope
      }
      invite
      status
      dateAdded
    }
  }
`;
