import { captureException } from '@sentry/node';
import { ProgrammeFilters } from 'Client/pages/hub/components/Programme/types';
import { ProjectGaudi } from 'Shared/types';

/**
 * @param {string} postcode - The `postcode` parameter is a string that represents a postal code.
 * @param {string[]} projectIds - The `projectIds` parameter is an array of project _ids.
 * @returns list of projects near the given postcode
 */
export const getProjectsNearPostcode = async (
  postcode: string,
  projectIds: string[]
) => {
  try {
    const res = await fetch(`/api/postcode`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        postcode,
        projectIds,
      }),
    });
    const postcodeRaw = await res.json();
    return postcodeRaw?.data;
  } catch (e) {
    captureException(e);
    return [];
  }
};

/**
 * @param filters - The `filters` parameter is an object containing the filters to be applied.
 * @param projects - The `projects` parameter is an array of projects to be filtered.
 * @returns list of projects filtered by the given filters
 */
export const filterProgrammes = async (
  filters: ProgrammeFilters,
  projects: ProjectGaudi[]
) => {
  try {
    if (filters?.postcode?.value) {
      const projectIds = projects.map((project) => project._id);

      const projectsNear = await getProjectsNearPostcode(
        filters?.postcode?.value,
        projectIds
      );
      const projectsNearIds = projectsNear.map((project) => project._id);
      return projects.filter((project) =>
        projectsNearIds.includes(project._id)
      );
    }

    return projects;
  } catch (e) {
    captureException(e);
  }
};
