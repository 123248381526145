import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@material-ui/core';
import { CloseIcon } from 'Atoms/Icons';
import { OptionItem } from 'Client/types';
import { usePermissions, useProject } from 'Client/utils/hooks';
import { projectStages } from 'Shared/constants/projectStages';
import { Permissions } from 'Client/constants/permissions';
import { ProjectStage } from 'Shared/types';
import { capitalize } from 'Client/pages/planningapps/components/NavBar/utils';
import { FilterDropdown } from '../FilterDropdown';
import { FiltersButton } from '../FiltersButton';
import { PostcodeField } from '../PostcodeField';
import { ResultsTitle } from '../../ProgrammeReel.styles';
import { CustomFiltersOptions, ProgrammeFilters } from '../../types';
import {
  ApplyFilters,
  ButtonWrapper,
  ClearFilters,
  DrawerDiv,
  FiltersContainer,
  FiltersHeader,
  FiltersWrapper,
  GlobalStyle,
} from './styles';

interface Props {
  handleChanges: (key: string, option: OptionItem) => void;
  handleClearFilters: () => void;
  filters: ProgrammeFilters;
  projectsWards?: OptionItem[];
  customFiltersValues?: Record<string, OptionItem>;
  customFiltersOptions?: CustomFiltersOptions[];
}

export const FiltersMenu: React.FC<Props> = ({
  handleChanges,
  handleClearFilters,
  filters,
  projectsWards,
  customFiltersValues,
  customFiltersOptions,
}) => {
  const { can } = usePermissions();
  const { t } = useTranslation();
  const { features } = useProject();

  const [isFiltersMenuOpen, setIsFiltersMenuOpen] = React.useState(false);

  const activeFilters = filters && Object.values(filters)?.filter(Boolean);

  const isMobile = useMediaQuery('(max-width: 1024px)');

  const handleToggleFiltersMenu = () => {
    setIsFiltersMenuOpen((prevState) => !prevState);
  };

  const statusOptions = React.useMemo(() => {
    if (can(Permissions.SEE_ALL_STATUS_OPTIONS)) {
      return projectStages;
    }

    return projectStages.filter(
      (stage) =>
        stage.value === ProjectStage.ACTIVE ||
        stage.value === ProjectStage.COMPLETED
    );
  }, [can]);

  React.useEffect(() => {
    if (!isMobile) setIsFiltersMenuOpen(false);
  }, [isMobile]);

  const renderFiltersMenu = () => {
    return (
      <DrawerDiv>
        <FiltersHeader>
          <ResultsTitle>{t('Filter by')}</ResultsTitle>
          <CloseIcon onClick={handleToggleFiltersMenu} width={18} height={18} />
        </FiltersHeader>
        <FiltersWrapper isMenuOpen={isFiltersMenuOpen}>
          {features.projectsFiltersPostcode && (
            <PostcodeField
              handleChange={(option) => handleChanges('postcode', option)}
              isMenuOpen={isFiltersMenuOpen}
              value={filters?.postcode}
              label={t('Search')}
              placeholder={t('Postcode')}
              searchFor={['postcode']}
            />
          )}
          {features.projectsFiltersWard && (
            <FilterDropdown
              label={t('Ward')}
              isClearable={false}
              placeholder={t('Any')}
              isMenuOpen={isFiltersMenuOpen}
              options={projectsWards}
              value={filters?.ward}
              handleChange={(option: OptionItem) =>
                handleChanges('ward', option)
              }
            />
          )}
          {features.projectsFiltersStatus && (
            <FilterDropdown
              label={t('Status')}
              placeholder={t('Any')}
              isMenuOpen={isFiltersMenuOpen}
              options={statusOptions}
              value={filters?.status}
              handleChange={(option: OptionItem) =>
                handleChanges('status', option)
              }
            />
          )}
          {customFiltersOptions.map((filter) => (
            <FilterDropdown
              key={filter.name}
              label={capitalize(filter.name)}
              placeholder={t('Any')}
              isMenuOpen={isFiltersMenuOpen}
              options={filter.options}
              value={customFiltersValues?.[filter.name]}
              handleChange={(option: OptionItem) =>
                handleChanges(filter.name, option)
              }
            />
          ))}
        </FiltersWrapper>
        <ButtonWrapper>
          <ClearFilters onClick={handleClearFilters}>
            <CloseIcon width={8} height={8} /> {t('Clear filters')}
          </ClearFilters>

          <ApplyFilters onClick={handleToggleFiltersMenu}>
            {t('Apply filters')}
          </ApplyFilters>
        </ButtonWrapper>
      </DrawerDiv>
    );
  };

  return (
    <FiltersContainer isMenuOpen={isFiltersMenuOpen}>
      <GlobalStyle isMenuOpen={isFiltersMenuOpen} />

      {isFiltersMenuOpen ? (
        renderFiltersMenu()
      ) : (
        <FiltersWrapper>
          {isMobile ? (
            <FiltersButton
              filtersActive={activeFilters?.length}
              onClick={handleToggleFiltersMenu}
            >
              {t('Filters')}
            </FiltersButton>
          ) : (
            <>
              {customFiltersOptions.map((filter) => (
                <FilterDropdown
                  key={filter.name}
                  label={capitalize(filter.name)}
                  placeholder={t('Any')}
                  isMenuOpen={isFiltersMenuOpen}
                  options={filter.options}
                  width={12.5}
                  value={customFiltersValues?.[filter.name]}
                  handleChange={(option: OptionItem) =>
                    handleChanges(filter.name, option)
                  }
                />
              ))}
              {features.projectsFiltersWard && (
                <FilterDropdown
                  label={t('Ward')}
                  placeholder={t('Any')}
                  width={12.5}
                  isMenuOpen={isFiltersMenuOpen}
                  options={projectsWards}
                  value={filters?.ward}
                  handleChange={(option: OptionItem) =>
                    handleChanges('ward', option)
                  }
                />
              )}
              {features.projectsFiltersStatus && (
                <FilterDropdown
                  label={t('Status')}
                  placeholder={t('Any')}
                  width={12.5}
                  isMenuOpen={isFiltersMenuOpen}
                  options={statusOptions}
                  value={filters?.status}
                  handleChange={(option: OptionItem) =>
                    handleChanges('status', option)
                  }
                />
              )}
            </>
          )}
          {features.projectsFiltersPostcode && (
            <PostcodeField
              handleChange={(option) => handleChanges('postcode', option)}
              value={filters?.postcode}
              label={t('Search')}
              placeholder={t('Postcode')}
              searchFor={['postcode']}
              width={12.5}
            />
          )}
        </FiltersWrapper>
      )}

      {isMobile ? null : (
        <ClearFilters onClick={handleClearFilters}>
          <CloseIcon width={10} height={10} /> {t('Clear filters')}
        </ClearFilters>
      )}
    </FiltersContainer>
  );
};
