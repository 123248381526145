import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { HubPagesTemplate } from 'Templates';
import { Container, Typography as TypographyBase } from 'Atoms';

export const NoPermissionPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <HubPagesTemplate>
      <Container>
        <Typography>
          {t(`Sorry. You don't have permission to view this page.`)}
        </Typography>
      </Container>
    </HubPagesTemplate>
  );
};

const Typography = styled(TypographyBase)`
  margin-top: 1rem;
`;
