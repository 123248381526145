import { useMediaQuery, useTheme } from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useEditModeLayout } from 'Client/context/editModeLayoutContext';
import { useEditModeContext } from 'Client/utils/hooks';
import { FullWidthWrapper } from './FullWidthVisualizer.styles';
import { VisualizerComponentProps } from './types';
import { getImageUrlWidthBased } from './utils';

export const FullWidthVisualizer: React.FC<VisualizerComponentProps> = ({
  files,
  windowWidth,
  windowHeight,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [isEditModeLayout] = useEditModeLayout();
  const [state] = useEditModeContext();
  const previewMode = state?.editModeLayout?.previewMode;
  const editModeDesktopZoomOut = true;

  const scaledDownLayout = previewMode === 'desktop' && editModeDesktopZoomOut;
  const WrapperRef = React.useRef(null);

  const [pageFullyLoaded, setPageFullyLoaded] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState('');

  React.useEffect(() => {
    setPageFullyLoaded(windowHeight > 0 && windowWidth > 0);
  }, [windowHeight, windowWidth]);

  if (!files.length) return <p>{t('Full width')}</p>;
  const file = files[0];

  const wrapperWidth = WrapperRef?.current?.clientWidth;

  const imageCfHeight = windowHeight - windowHeight * (isMobile ? 0.65 : 0.35);
  const imageCfWidth = windowWidth;

  const imageEditModeWidth = wrapperWidth;
  const imageEditModeHeight = windowHeight - windowHeight * 0.65;

  const imageWidth = isEditModeLayout ? imageEditModeWidth : imageCfWidth;
  const imageHeight = isEditModeLayout ? imageEditModeHeight : imageCfHeight;

  /* Needed to get size after changes (container resize) */
  setTimeout(() => {
    setImageUrl(
      getImageUrlWidthBased({
        width: Math.ceil(imageWidth),
        height: Math.ceil(imageHeight),
        url: file.url,
      })
    );
  }, 1000);

  return (
    <FullWidthWrapper
      key={file.url}
      ref={WrapperRef}
      scaledDownLayout={scaledDownLayout}
    >
      {pageFullyLoaded && <img alt={file.alt} src={imageUrl} />}
      {file.description && <p>{file.description}</p>}
    </FullWidthWrapper>
  );
};
