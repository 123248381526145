import * as React from 'react';
import { useRouter } from 'next/router';
import styled, { css } from 'styled-components';
import { useProposalContext } from 'Client/utils/hooks';
import { PROPOSAL_ACTION_TYPES } from 'Client/context/proposalReducer';

interface SectionWrapperProps {
  children: React.ReactNode;
}

const SectionWrapper: React.FC<SectionWrapperProps> = ({
  children,
}: SectionWrapperProps) => {
  // The logic of what section to show/hide is here
  const { query } = useRouter();
  const [{ currentStep }, dispatch] = useProposalContext();
  const visibleStep = query?.slug[1]; // 0: proposalSlug, 1: step1
  const proposalSlug = query?.slug[0]; // 0: proposalSlug, 1: step1

  React.useEffect(() => {
    // update current step based on router query (eg after a refresh)
    const stepNo = parseInt(visibleStep.substring(4), 10);
    if (stepNo !== currentStep) {
      dispatch({
        type: PROPOSAL_ACTION_TYPES.SET_CURRENT_STEP,
        currentStep: stepNo,
      });
    }
  }, [currentStep, visibleStep]);

  return (
    <StyledSectionWrapper visibleStep={visibleStep} proposalSlug={proposalSlug}>
      {children}
    </StyledSectionWrapper>
  );
};

export { SectionWrapper };

const StyledSectionWrapper = styled(
  ({ visibleStep, proposalSlug, ...props }) => <div {...props} />
)`
  ${({ visibleStep, proposalSlug }) =>
    visibleStep &&
    proposalSlug &&
    css`
      > div {
        width: 100%;
        height: fit-content;
        padding-bottom: 3.5rem;

        @media (max-width: 959px) {
          padding-bottom: 2.5rem;
        }
      }

      > div:not(#${visibleStep}.${proposalSlug}) {
        z-index: -100;
        display: none;
      }

      > div#${visibleStep}.${proposalSlug} {
        z-index: 100;
      }
    `}
`;
