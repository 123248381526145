import * as React from 'react';
import { Router, useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@material-ui/core';
import PenIcon from '@material-ui/icons/CreateOutlined';
import { useProject, useUser } from 'Client/utils/hooks';
import { userHasPermission } from 'Client/utils/user';
import { Permissions } from 'Client/constants/permissions';
import { FloatingButtonWrapper } from 'Molecules';
import { SharingButtonRoutes } from 'Client/components/organisms/FloatingShareButton/types';
import { getEditModePath } from './getEditModePath';
import { ButtonAnchor } from './EditModeButton.styles';

const EditModeButton: React.FC = () => {
  const { asPath, query, locale } = useRouter();
  const project = useProject();
  const { user } = useUser();
  const router = useRouter();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const isShown =
    !isMobile &&
    project?.features.editMode &&
    userHasPermission(user, Permissions.EDIT_CONTENT);

  const { route: _route } = router as Router;
  const route = _route as SharingButtonRoutes;
  const baseXValue = route === SharingButtonRoutes.PROPOSALS ? 4.7 : 8.2;
  return (
    isShown && (
      <FloatingButtonWrapper baseX={baseXValue} baseY={2.6} route={route}>
        <ButtonAnchor
          data-onboarding="edit-mode-button"
          to={getEditModePath(asPath, query, locale)}
        >
          <PenIcon fontSize="inherit" />
          <span>{t('Edit page')}</span>
        </ButtonAnchor>
      </FloatingButtonWrapper>
    )
  );
};

export { EditModeButton };
