import * as React from 'react';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import { HubHeader } from 'Organisms/HubHeader';
import { MilestoneStageType } from 'Client/pages/timeline';
import { MilestoneItem } from './MilestoneItem';
import { MilestonesProps } from './types';
import {
  TimelineContainer,
  SideSection,
  MainSection,
  ActiveStageName,
  ActiveStageDate,
  ActiveStageDescription,
} from './Milestones.styles';

const Milestones: React.FC<MilestonesProps> = ({
  milestones,
}: MilestonesProps) => {
  if (!milestones.length) return null;
  const { t } = useTranslation();
  const activeMilestones = milestones.filter(
    (milestone) => milestone.stage === MilestoneStageType.ACTIVE
  );

  const renderMilestones = (type: string) => {
    return milestones
      .filter((milestone) => milestone.stage === type)
      .map((milestone) => (
        <MilestoneItem key={milestone.name} stage={milestone.stage} />
      ));
  };

  return (
    <>
      <HubHeader
        title={t('Project Timeline')}
        anchorText={t('Learn more')}
        path="timeline"
      />
      <TimelineContainer data-testid="Milestones-timeline-container">
        <SideSection left>
          {renderMilestones(MilestoneStageType.COMPLETED)}
        </SideSection>
        <MainSection>{renderMilestones(MilestoneStageType.ACTIVE)}</MainSection>
        <SideSection>
          {renderMilestones(MilestoneStageType.PLANNED)}
        </SideSection>
      </TimelineContainer>
      {Boolean(activeMilestones.length) && (
        <div data-testid="Milestones-links-container">
          <Link href="/timeline">
            <ActiveStageName variant="h3">
              {activeMilestones[0]?.name}
            </ActiveStageName>
          </Link>
          <Link href="/timeline">
            <ActiveStageDate variant="body1">
              {t('{{ date }} • In Progress', {
                date: activeMilestones[0]?.startDate,
              })}
            </ActiveStageDate>
          </Link>
          <Link href="/timeline">
            <ActiveStageDescription>
              {activeMilestones[0]?.description}
            </ActiveStageDescription>
          </Link>
        </div>
      )}
    </>
  );
};

export { Milestones };
