import * as React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { ChevronIcon, DownloadIcon, EyeIcon } from 'Atoms/Icons';
import {
  ButtonWrapper,
  ButtonWrapperMobile,
  CardMedia,
  DescriptionWrapper,
  DocumentInfo,
  InfoWrapper,
  MediaWrapper,
  Row,
  Text,
} from './FileRow.styles';
import { FileRowProps } from '../types';

import { FileRowInfoMobile } from './FileRowInfoMobile';

export const FileRow: React.FC<FileRowProps> = ({
  url,
  name,
  info,
  thumbnail,
  hiddenUrl,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const theme: any = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const iconProps = {
    height: 20,
    color: theme.colorMappings.primaryButtonBackground,
    opacity: '1',
  };

  let remainderWidth = 80;

  if (!isMobile) {
    info.forEach((x) => (remainderWidth -= x.width ? x.width : 0));
  }

  const handleDownload = () => {
    fetch(url).then((res) => {
      res.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
      });
    });
  };

  const handleOpenUrl = () => {
    return window.open(url, '_blank').focus();

    //old logic, keeping here cause we might revert
    // if (url?.startsWith('http')) {
    // return window.open(url, '_blank').focus();
    // }
    // fetch(url).then((res) => {
    //   res.blob().then((blob) => {
    //     const url = window.URL.createObjectURL(blob); //try blob if didn owrk
    //     window.open(url, '_blank');
    //   });
    // });
  };

  return (
    <>
      <Row key={hiddenUrl ? name : url}>
        {thumbnail && (
          <MediaWrapper onClick={handleDownload}>
            <CardMedia
              component="img"
              src={thumbnail}
              alt="Document image preview"
            />
          </MediaWrapper>
        )}
        <DocumentInfo>
          <DescriptionWrapper
            percentage={remainderWidth}
            onClick={handleDownload}
            noThumbnail={!thumbnail}
          >
            <Text>{name}</Text>
          </DescriptionWrapper>
          {info.map(({ width, value, align }, i) => (
            <InfoWrapper key={i} percentage={width} align={align || 'right'}>
              <Text>{value}</Text>
            </InfoWrapper>
          ))}
          <ButtonWrapper onClick={handleDownload}>
            <DownloadIcon {...iconProps} />
          </ButtonWrapper>
          {url?.startsWith('http') && (
            <ButtonWrapper onClick={handleOpenUrl}>
              <EyeIcon {...iconProps} />
            </ButtonWrapper>
          )}
          <ButtonWrapperMobile
            isOpen={isOpen}
            onClick={() => {
              if (isOpen) {
                setIsClosing(true);
                return setTimeout(() => {
                  setIsOpen(false);
                  setIsClosing(false);
                }, 300);
              }

              setIsOpen(true);
            }}
          >
            <ChevronIcon {...iconProps} />
          </ButtonWrapperMobile>
        </DocumentInfo>
      </Row>
      {isOpen && info.length > 0 && (
        <FileRowInfoMobile info={info} url={url} isClosing={isClosing} />
      )}
    </>
  );
};
