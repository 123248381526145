import styled, { css } from 'styled-components';
import { Link } from 'Atoms';
import { BaseFloatingButtonInner } from 'Molecules';

export const ButtonAnchor = styled(Link)`
  ${BaseFloatingButtonInner}
  ${({ theme }) => css`
    background: ${theme.colorMappings.editButtonBackground};
    color: ${theme.colorMappings.white};

    svg {
      color: ${theme.colorMappings.white};
    }
  `}
`;
