import * as React from 'react';
import { DashboardPagesTemplate as BaseTemplate } from '../DashboardPages';
import { Wrapper } from './DashboardDetailsPages.styles';
import { DashboardDetailsPagesProps } from './types';

export const DashboardDetailsPageTemplates: React.FC<
  DashboardDetailsPagesProps
> = ({ currentTab, children }: DashboardDetailsPagesProps) => {
  return (
    <BaseTemplate currentTab={currentTab}>
      <Wrapper>{children}</Wrapper>
    </BaseTemplate>
  );
};
