import * as React from 'react';
import styled, { css } from 'styled-components';
import { editModeMobile } from 'Client/components/theme/utils';
import { Typography } from 'Atoms';
import { HtmlDescription } from 'Molecules';

export const SideSection = styled(({ left, ...props }) => <div {...props} />)`
  justify-content: ${({ left }) => left && 'flex-end'};
  display: flex;
  align-items: center;
  width: 47%;
  min-width: 47%;
  overflow: hidden;

  ${({ theme }) => css`
    ${editModeMobile(
      theme.breakpoints.down('sm'),
      css`
        width: 45%;
        min-width: 45%;
      `
    )}
  `}
`;

export const MainSection = styled.div`
  display: flex;
  align-items: center;
`;

export const TimelineContainer = styled.div`
  transition: visibility 3s;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4.375rem;
`;

export const ActiveStageName = styled(Typography)`
  cursor: pointer;
  margin-top: 3.125rem;
  text-align: center;
  font-size: 1.375rem;
`;

export const ActiveStageDate = styled(Typography)`
  cursor: pointer;
  margin: 0.3125rem auto;
  display: block;
  text-align: center;
  color: ${({ theme }) => theme.colorMappings.accessibleSecondaryText};
`;

export const ActiveStageDescription = styled(HtmlDescription)`
  cursor: pointer;
  margin: 0.625rem auto;
  display: block;
  max-width: 40rem;
  text-align: center;
`;
