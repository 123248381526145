import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ isClosing?: boolean }>`
  border-style: solid;
  border-color: ${({ theme }) => theme.colorMappings.greyBorderLight};
  border-width: 0.063rem 0.063rem 0 0.063rem;
  animation: fadeInAnimation 1.5s;

  ${({ isClosing }) =>
    isClosing &&
    css`
      animation: fadeOutAnimation 0.5s;
    `}

  &:last-child {
    border-bottom: 0.063rem solid
      ${({ theme }) => theme.colorMappings.greyBorderLight};
  }

  @keyframes fadeOutAnimation {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes fadeInAnimation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const InfoContainer = styled.div`
  margin: 1rem;
`;

export const MobileViewLink = styled.a`
  padding: 0.5rem;
  display: block;
  > * {
    width: 100%;
  }
`;

export const Info = styled.div`
  display: flex;
`;

export const Title = styled.b`
  width: 8rem;
`;
