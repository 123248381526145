import styled from 'styled-components';

export const GridVisualizerWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: ${({ theme }) => theme.screenPadding};
  max-width: ${({ theme }) => theme.container.width};
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

export const ImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
`;

export const GridImage = styled.img<{ fullWidth: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'calc(50% - 0.5rem)')};
  object-fit: contain;
`;
