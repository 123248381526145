import * as React from 'react';
import { readableColor, darken } from 'polished';
import styled, { css } from 'styled-components';
import MuiBottomNavigation from '@material-ui/core/BottomNavigation';
import MuiBottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { LoadingButton, LoadingButtonStates } from 'Atoms';

export const BottomNavigationSpacer = styled.div`
  display: none;

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      position: relative;
      display: block;
      height: ${theme.proposals.footer.height};
      content: '';
    }
  `}
`;

export const BottomNavigation = styled(({ ...props }) => (
  <>
    <BottomNavigationSpacer />
    <MuiBottomNavigation {...props} />
  </>
))`
  ${({ theme, smallContainer }) => css`
    &.MuiBottomNavigation-root {
      height: ${theme.proposals.footer.height};
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      z-index: 1099;
      padding: ${theme.screenPadding};
      padding-top: 1rem;
      padding-bottom: 1rem;

      box-shadow: 0.625rem 0 0.625rem ${theme.colorMappings.shadow};

      ${theme.breakpoints.up('lg')} {
        position: relative;
        width: ${smallContainer
          ? theme.container.smallWidth
          : theme.container.width};
        margin: 0 auto;
        box-shadow: none;
      }

      @media print {
        display: none;
      }
    }
  `}
`;

export const BottomNavigationAction = styled(({ ...props }) => (
  <MuiBottomNavigationAction {...props} />
))`
  ${({ left, theme }) => css`
    align-self: center;
    height: 3.125rem;

    &.MuiBottomNavigationAction-root {
      color: ${theme.colorMappings.accessibleBrand};
      ${left &&
      css`
        &:hover {
          .MuiBottomNavigationAction-label,
          .MuiSvgIcon-root {
            color: ${theme.palette.primary.dark};
          }
        }
      `}

      &.Mui-focusVisible {
        &:after {
          content: '';
          position: absolute;
          border-radius: 0;
          top: -0.5rem;
          left: -0.5rem;
          right: -0.5rem;
          bottom: -0.5rem;
          border: 0.18rem solid ${theme.colorMappings.focusHighlight};
          z-index: 1;
        }
      }
      .MuiSvgIcon-fontSizeSmall {
        font-size: 1rem;
      }
      .MuiBottomNavigationAction-wrapper {
        flex-direction: row-reverse;
        ${left &&
        css`
          justify-content: left;
          flex-direction: row;
        `}
      }
      .MuiBottomNavigationAction-label {
        margin: 0 0.5rem;
        font-weight: bold;
        font-size: ${theme.fontSizes.m};
      }
    }
  `};
`;

export const BackNavigationAction = styled(BottomNavigationAction)`
  ${({ theme }) => css`
    padding-left: 0;

    .MuiBottomNavigationAction-label,
    .MuiSvgIcon-root {
      color: ${theme.colorMappings.hyperlink};
    }

    &:hover {
      .MuiBottomNavigationAction-label,
      .MuiSvgIcon-root {
        color: ${darken(0.1, theme.colorMappings.hyperlink)} !important;
      }
    }

    ${theme.direction === 'rtl' &&
    css`
      .MuiSvgIcon-root {
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
      }
    `}
  `}
`;

export const NextNavigationButton = styled(LoadingButton)<{
  disabled?: boolean;
  state: LoadingButtonStates;
}>`
  ${({ theme, disabled }) => css`
    align-self: center;
    height: 3.125rem;
    // padding-${theme.direction === 'rtl' ? 'right' : 'left'}: 3rem;
    border-radius: 0.3125rem;
    opacity: ${disabled ? 0.5 : 1};
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 3rem;
    transition: 0.5s ease;
    ${theme.direction === 'rtl' &&
    css`
      .MuiSvgIcon-root {
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
      }
    `}
  `}
  ${({ state }) => {
    switch (state) {
      case LoadingButtonStates.LOADING:
        return css`
          padding: 0 2.5rem;
        `;
      default:
        return css`
          padding: 0 4rem;
        `;
    }
  }}
`;

export const SubmitButton = styled(BottomNavigationAction)`
  ${({ theme }) => css`
    width: 12.5rem;
    min-width: 10rem;
    padding: 0;
    margin: auto 0;
    border-radius: 0.3125rem;

    .MuiBottomNavigationAction-label {
      color: ${readableColor(theme.colorMappings.primaryButtonBackground)};
    }
  `};
`;
