import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  background-color: ${({ theme }) => theme.colors.grey[200]};
  border-radius: 0.125rem;

  a {
    padding: 0.375rem 1rem;
    display: flex;
  }

  svg {
    align-self: center;
    padding-top: 0.3125rem;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      margin: 0 0.5rem;

      a {
        padding: 0.4375rem 0.25rem;
      }
    }
  `};
`;
