import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.8rem;
`;
export const Description = styled.p``;

export const IconTextItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
  align-items: flex-start;
  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      flex-direction: column !important;
    }
    ${theme.breakpoints.up('xs')} {
      flex-direction: row;
    }
  `};
`;

export const SocialMediaWrapper = styled.div`
  a {
    width: 3.125rem;
    height: 3.125rem;
    margin-right: 1rem;
  }

  svg {
    width: 3.125rem;
    height: 3.125rem;
  }
`;

export const ContactInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  a {
    text-decoration: underline;
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    margin-right: 0.625rem;
  }
  span {
    font-weight: bold;
  }
`;
