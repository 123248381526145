import * as React from 'react';
import { FileProps } from './types';
import { FileRow } from './components/FileRow';
import { FileContainer } from './Files.styles';

export const Files: React.FC<FileProps> = ({ files, hiddenUrl }) => {
  return (
    <FileContainer>
      {files.map((props, index) => (
        <FileRow
          key={hiddenUrl ? `${props.name}-${index}` : props.url}
          hiddenUrl={hiddenUrl}
          {...props}
        />
      ))}
    </FileContainer>
  );
};
