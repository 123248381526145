import * as React from 'react';
import styled from 'styled-components';
import { SurveyPagesTemplateProps as Props } from './types';
import { HeadMeta } from '../components/HeadMeta';
import { Masthead } from './components';

export const SurveyPagesTemplate: React.FC<Props> = ({
  children,
  pageTitle,
  project,
}) => (
  <Wrapper data-testid="SurveyPagesTemplate">
    <HeadMeta />
    <Masthead pageTitle={pageTitle} project={project} />
    <Content>{children}</Content>
  </Wrapper>
);

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white[500]};
`;

const Content = styled.div`
  margin: 0 auto;
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
