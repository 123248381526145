import * as React from 'react';
import { SeoMetaInfo } from 'Atoms';
import { useProject } from 'Client/utils/hooks';
import { HubPagesTemplate as Template } from 'Templates';
import { EditModeButton } from 'Pages/edit/components/EditModeButton';
import { MilestoneItem } from './components';
import { MilestoneItemProps, MilestonesPageProps } from './types';
import {
  MilestonesContainer,
  DescriptionArea,
  PageHeader,
  Description,
  Timeline,
} from './MilestonesPage.styles';

const MilestonesPage: React.FC<MilestonesPageProps> = ({
  milestones,
  title,
  description,
  ...props
}: MilestonesPageProps) => {
  const project = useProject();

  return (
    <Template {...props}>
      <MilestonesContainer>
        <SeoMetaInfo
          projectStage={project.stage}
          projectName={project.name}
          page="milestones"
        />
        <DescriptionArea>
          <PageHeader variant="h1" data-testid="milestones-page-header">
            {title}
          </PageHeader>
          <Description>{description}</Description>
        </DescriptionArea>
        <Timeline>
          {milestones.map((milestoneProps: MilestoneItemProps) => (
            <MilestoneItem
              key={milestoneProps.order}
              data-scroll-id={milestoneProps?._id}
              {...milestoneProps}
            />
          ))}
        </Timeline>
      </MilestonesContainer>
      <EditModeButton />
    </Template>
  );
};

export { MilestonesPage };
