import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ImageAndGridContent, ImageAndGridLayoutTypes } from '../types';
import { CarouselVisualizer } from './ImageBlock/CarouselVisualizer';
import { VisualizerComponentProps } from './ImageBlock/types';
import { FullWidthVisualizer } from './ImageBlock/FullWidthVisualizer';
import { ImageAndTextVisualizer } from './ImageBlock/ImageAndTextVisualizer';
import DefaultImageVisualizer from './ImageBlock/DefaultImageVisualizer';
import GridVisualizer from './ImageBlock/GridVisualizer';

const ImageAndGridVisualizer: React.FC<ImageAndGridContent> = ({
  files,
  layout,
  alignment,
}) => {
  const { t } = useTranslation();
  const [windowWidth, setWindowWidth] = React.useState(0);
  const [windowHeight, setWindowHeight] = React.useState(0);

  React.useLayoutEffect(() => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  }, []);

  const props: VisualizerComponentProps = {
    files: files,
    windowWidth: windowWidth,
    windowHeight: windowHeight,
  };

  switch (layout) {
    case ImageAndGridLayoutTypes.FULL_WIDTH:
      return <FullWidthVisualizer {...props} />;
    case ImageAndGridLayoutTypes.CAROUSEL:
      return <CarouselVisualizer {...props} />;
    case ImageAndGridLayoutTypes.IMAGE_AND_TEXT:
      return <ImageAndTextVisualizer {...props} alignment={alignment} />;
    case ImageAndGridLayoutTypes.DEFAULT:
      return <DefaultImageVisualizer {...props} />;
    case ImageAndGridLayoutTypes.GRID:
      return <GridVisualizer {...props} />;
    default:
      return <p>{t('Image')}</p>;
  }
};

export default ImageAndGridVisualizer;
