import * as React from 'react';
import styled, { css } from 'styled-components';
import {
  CardFooter as BaseCardFooter,
  Card as BaseCard,
} from 'Atoms/Card/ProjectCard/ProjectCard.styles';
import { CardHeader as BaseCardHeader } from '../DynamicProposals/components/CustomProposalCard/CustomProposalCard.styles';

export const Description = styled.p`
  margin: 0;
  margin-left: 0.75rem;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.black[500]};
  align-self: center;
`;

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CategoryWrapper = styled.div`
  background-color: ${({ theme }) => theme.colorMappings.planAppTileBackground};
  width: 100%;
  padding: 1rem 1rem;
`;

export const Card = styled((props) => <BaseCard {...props} />)`
  height: unset;
`;

export const CardHeader = styled((props) => <BaseCardHeader {...props} />)`
  border: 0;
  p {
    font-size: 0.875rem;
  }
  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      flex-direction: column;
      align-content: center;
      flex-wrap: wrap;
    }
  `};
`;

export const CardFooter = styled((props) => <BaseCardFooter {...props} />)`
  span {
    color: ${({ theme }) => theme.colorMappings.primaryButtonBackground};
  }
  svg {
    path {
      fill: ${({ theme }) => theme.colorMappings.primaryButtonBackground};
    }
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.5rem;
  }
`;

export const FooterText = styled.p`
  margin: 0;
  font-size: 0.8rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colorMappings.primaryButtonBackground};
`;

export const LoadingPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;
