import React from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { useEditModeLayout } from 'Client/context/editModeLayoutContext';
import { useEditModeContext } from 'Client/utils/hooks';
import { TwoColumns } from '../../types';
import { TextContent, Wrapper } from './TwoColumnsVisualizer.styles';

type TwoColumnsVisualizerProps = {
  left: TwoColumns['left'];
  right: TwoColumns['right'];
};

export const TwoColumnsVisualizer = ({
  left,
  right,
}: TwoColumnsVisualizerProps) => {
  const theme = useTheme();
  const [isEditModeLayout] = useEditModeLayout();
  const [editModeState] = useEditModeContext();
  const editModeMobileLayout =
    isEditModeLayout && editModeState.editModeLayout.previewMode === 'mobile';
  const isMobile =
    useMediaQuery(theme.breakpoints.down('xs')) || editModeMobileLayout;

  return (
    <Wrapper isEditModeLayout={isEditModeLayout}>
      <TextContent isMobile={isMobile}>
        <div dangerouslySetInnerHTML={{ __html: left.content }}></div>
        <div dangerouslySetInnerHTML={{ __html: right.content }}></div>
      </TextContent>
    </Wrapper>
  );
};
