export const scrollToSection = (
  sectionId: string,
  scrollProps?: ScrollIntoViewOptions
) => {
  const defaultScrollProps: ScrollIntoViewOptions = {
    behavior: 'smooth',
  };
  const scrollOptions = scrollProps ?? defaultScrollProps;
  const element = document.getElementById(sectionId);
  element?.scrollIntoView(scrollOptions);
};
