export type HTMLElementEvent<T extends HTMLElement> = Event &
  EventTarget & {
    target: T;
  };

export type MouseEventCustom<T extends HTMLElementEvent<HTMLDivElement>> =
  React.MouseEvent & {
    target: T & {
      id: string;
      offsetWidth: number;
      firstChild: ChildNode & {
        offsetWidth: number;
      };
      parentElement: ChildNode & {
        offsetWidth: number;
      };
    };
  };

export const checkLengthAndSetModal = (
  e: MouseEventCustom<HTMLElementEvent<HTMLDivElement>>,
  tolerance = 2 //font-size can vary
) => {
  const isWrapper = e.target.id === 'wrapper';
  const width = isWrapper
    ? e.target.offsetWidth
    : e.target.parentElement.offsetWidth;
  const widthChild = isWrapper
    ? e.target.firstChild.offsetWidth
    : e.target.offsetWidth;
  if (widthChild + tolerance >= width) {
    return true;
  }
  return false;
};
