import * as React from 'react';
import styled, { css } from 'styled-components';

export const Wrapper = styled(({ cta, ...props }) => <div {...props} />)`
  ${({ theme }) => css`
    margin: 1rem 0.25rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;

    div {
      h2 {
        color: ${theme.colors.black[600]};
        margin: 0;
        font-size: 3rem;
        font-weight: 700;
        line-height: 3rem;
        font-family: Source Sans Pro, Open Sans, Sans-serif;
      }

      p {
        color: ${theme.colorMappings.greyTextDarker};
        font-size: 1.125rem;
        margin: 0;
      }
    }
    figure {
      margin: 1rem;
    }
    ${theme.breakpoints.down('xs')} {
      display: flex;
      gap: 0.5rem;
      div {
        h2 {
          line-height: 1.25rem;
          font-size: 1.5rem;
        }

        p {
          font-size: 0.8rem;
        }
      }
      figure {
        margin: 0;
      }

      figure img {
        margin: 0 0 1rem;
      }
    }
  `}
`;

export const Image = styled(({ big, ...props }) => <img {...props} />)`
  ${({ big, theme }) => css`
    height: ${big ? '11rem' : '7rem'};

    ${theme.breakpoints.down('xs')} {
      ${'height: 20vw;'}
    }
  `}
`;
