import * as React from 'react';
import { DocumentIcon, UsersIcon } from 'Atoms/Icons';

export const buttonsObj = [
  {
    name: 'Projects',
    url: '/project-centre',
    icon: <DocumentIcon />,
    type: 'menu',
    isActive: () => true,
  },
  {
    name: 'People',
    url: '/project-centre/team-management',
    icon: <UsersIcon />,
    type: 'menu',
    isActive: (customer: { hubspot_id: string; customer: string }) =>
      customer.customer && customer.customer !== 'All customers',
  },
];
