import * as React from 'react';
import { useProject } from 'Client/utils/hooks';
import { Masthead } from 'Client/components/organisms';
import { Wrapper, Content } from './PlanningAppPages.styles';

export const PlanningAppPagesTemplate: React.FC = ({ children }) => {
  const project = useProject();

  return (
    <Wrapper data-testid="PlanningAppPageTemplate">
      <Masthead project={project} />
      <Content>{children}</Content>
    </Wrapper>
  );
};

export default PlanningAppPagesTemplate;
