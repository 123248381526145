import * as React from 'react';

export const Envelope = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.0834961 2C0.0834961 1.30964 0.64314 0.75 1.3335 0.75H14.6668C15.3572 0.75 15.9168 1.30964 15.9168 2V12C15.9168 12.6904 15.3572 13.25 14.6668 13.25H1.3335C0.643141 13.25 0.0834961 12.6904 0.0834961 12V2ZM1.50609 1.58333L7.11628 7.19353C7.60443 7.68168 8.39589 7.68168 8.88405 7.19353L14.4942 1.58333H1.50609ZM15.0835 2.17259L9.4733 7.78278C8.65971 8.59637 7.34062 8.59637 6.52702 7.78278L0.916829 2.17259V12C0.916829 12.2301 1.10338 12.4167 1.3335 12.4167H14.6668C14.8969 12.4167 15.0835 12.2301 15.0835 12V2.17259Z"
      fill="black"
    />
  </svg>
);
