import * as React from 'react';
import { captureException } from '@sentry/node';
import orderBy from 'lodash.orderby';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { searchMapAddress } from 'Client/services/map/searchMapAddress';
import { AutocompleteMapAddressOption, OptionItem } from 'Client/types';
import { pluralizeWord } from 'Client/utils/stringManipulations';
import { BoldSearchIcon } from 'Atoms/Icons';
import { ToolTip } from 'Client/components/molecules';
import {
  Container,
  Label,
  LabelContainer,
  RelativeContainer,
  StyledAutocomplete,
} from './styles';
import { PostcodeFieldProps } from './types';

export const PostcodeField: React.FC<PostcodeFieldProps> = ({
  handleChange,
  label,
  searchFor = ['postcode'],
  isMenuOpen,
  ...props
}) => {
  const { t } = useTranslation();
  const [input, setInput] = React.useState(null);
  const [loadingAddress, setLoadingAddress] = React.useState(false);
  const [addressOptions, setAddressOptions] = React.useState<OptionItem[]>();
  const [isDebouncing, setIsDebouncing] = React.useState(false);
  const [debouncedText] = useDebounce(input, 500);

  const handleSearch = async (search: string) => {
    setLoadingAddress(true);
    try {
      const options = await searchMapAddress({
        input: search,
        autocomplete: true,
        fuzzyMatch: false,
        types: searchFor,
        limit: 10,
        isAdress: true,
      });
      /* As the autocomplete uses 'label' to display the option text,
       * on this case we want the 'text' prop to be displayed
       */
      const textAsLabelOptions = orderBy(
        options.map((item) => ({
          ...item,
          label: item.text,
          value: item.text.replace(/\s/g, ''),
          text: item.label,
        })),
        ['label'],
        ['asc']
      );
      const exactMatch = textAsLabelOptions.find(
        (opt) =>
          opt.label.replace(/\s/g, '').toLowerCase() ===
          input.replace(/\s/g, '').toLowerCase()
      );
      if (exactMatch) {
        /* Adds exact match to the first position */
        if (textAsLabelOptions.indexOf(exactMatch)) {
          textAsLabelOptions.splice(textAsLabelOptions.indexOf(exactMatch), 1);
          textAsLabelOptions.unshift(exactMatch);
        }
      }
      setAddressOptions(textAsLabelOptions);
    } catch (err) {
      captureException(err);
    } finally {
      setLoadingAddress(false);
    }
  };

  React.useEffect(() => {
    if (!debouncedText) {
      handleSearch(null);
      setIsDebouncing(false);
      return;
    }
    handleSearch(String(debouncedText));
    setIsDebouncing(false);
  }, [debouncedText]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    setIsDebouncing(true);
  }, [input]);

  const noOptsMessage = () => {
    if (isDebouncing && input) {
      return t('Loading...');
    }
    if (input) {
      return t('No valid {{item}} were found', {
        item: searchFor.map((word) => pluralizeWord(word, 2)).join(' or '),
      });
    }
    return t('Type to search');
  };

  return (
    <Container isMenuOpen={isMenuOpen}>
      <LabelContainer>
        {label && <Label>{label}</Label>}

        <ToolTip
          minHorizontalPosition={30}
          startPositionHorizontalMutation={-116}
          isMenuOpen={isMenuOpen}
        >
          <p>{t('Filter all projects within 1km of your postcode')}</p>
        </ToolTip>
      </LabelContainer>

      <RelativeContainer>
        <BoldSearchIcon />
        <StyledAutocomplete
          filterOption={(opt: AutocompleteMapAddressOption) => opt && true}
          options={addressOptions}
          hideArrow={true}
          isLoading={loadingAddress}
          handleChange={handleChange}
          noOptionsMessage={noOptsMessage}
          isClearable={true}
          handleInputChange={(val: string) => {
            setInput(String(val));
          }}
          {...props}
        />
      </RelativeContainer>
    </Container>
  );
};
