import { getCachedCloudinaryUrl } from 'Client/utils/cloudinary';
import { IMAGE_CLOUDINARY_TYPES } from 'Client/utils/cloudinary/types';

export const getImageUrlWidthBased = ({
  url,
  width,
  height,
}: {
  width?: number;
  height?: number;
  url: string;
}) =>
  getCachedCloudinaryUrl(url, IMAGE_CLOUDINARY_TYPES.CUSTOM, {
    width,
    height,
  });
