import styled from 'styled-components';

export const InlineImageWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: ${({ theme }) => theme.screenPadding};
  max-width: ${({ theme }) => theme.container.width};
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
`;

export const ImageContainer = styled.div`
  width: 100%;
`;
