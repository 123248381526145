import * as React from 'react';
import { Section } from './Section';
import { ProposalSectionRenderProps } from '../types';

const ProposalSectionRender: React.FC<ProposalSectionRenderProps> = (
  props: ProposalSectionRenderProps
) => {
  const {
    title,
    description,
    question,
    value,
    handleChange,
    image,
    steps,
    imageComparisonSlider,
    showProgressBar,
    showMinutesLeft,
    hideQuestion,
    onClick,
    voiceValue,
    files,
    pagePreview,
    imageAndGrid,
    accordionContent,
    twoColumns,
    navMap,
    backgroundColour,
    imageMapPro,
    sectionId,
  } = props;
  return (
    <div
      data-testid="ProposalSectionRender"
      data-scroll-id={sectionId}
      onClick={onClick}
    >
      <Section
        backgroundColour={backgroundColour}
        title={title}
        description={description}
        question={question}
        value={value}
        voiceValue={voiceValue}
        onChange={handleChange}
        image={image}
        sections={steps}
        imageComparisonSlider={imageComparisonSlider}
        accordionContent={accordionContent}
        imageAndGrid={imageAndGrid}
        twoColumns={twoColumns}
        navMap={navMap}
        showProgressBar={showProgressBar}
        showMinutesLeft={
          typeof showMinutesLeft === 'boolean' ? showMinutesLeft : true
        }
        hideQuestion={hideQuestion}
        files={files}
        pagePreview={pagePreview}
        imageMapPro={imageMapPro}
      />
    </div>
  );
};

export { ProposalSectionRender };
