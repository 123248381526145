import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: 100%;
`;

export const Content = styled.div`
  margin: 0 auto;
  width: 100%;
`;
