import styled, { css } from 'styled-components';
import MuiTimelineDot from '@material-ui/lab/TimelineDot';
import { StatusDotProps } from './types';

export const Dot = styled(MuiTimelineDot)<StatusDotProps>`
  ${({ width, height, theme, status, glaColor }) => {
    return css`
      width: ${width}rem;
      height: ${height}rem;
      border-radius: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${glaColor || theme.colorMappings[status]};

      @keyframes pulse {
        0% {
          transform: scale(0.8);
        }
        50% {
          transform: scale(1);
        }
        100% {
          transform: scale(0.8);
        }
      }

      &:before {
        width: ${width * 2}rem;
        height: ${height * 2}rem;
        position: absolute;
        margin: 0 auto;
        border-radius: 100%;
        background-color: ${theme.colorMappings[status]};

        animation: pulse 2.5s -0.4s infinite;
        content: '';
        opacity: 0.3;
      }
    `;
  }};
`;
