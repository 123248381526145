import * as React from 'react';
import styled, { css } from 'styled-components';
import { ChevronIcon } from 'Atoms/Icons';

export const Wrapper = styled.div<{ isOpen: boolean }>`
  ${({ theme, isOpen }) => css`
    width: ${isOpen ? '15.625rem' : '3.75rem'};
    height: calc(100vh - 3.75rem);
    background-color: ${theme.colors.white[500]};
    display: flex;
    flex-direction: column;
    cursor: pointer;
    box-shadow: 0.25rem 0 1.875rem 0 rgba(0, 0, 0, 0.102);
    z-index: 10;
    position: relative;
    color: ${theme.colors.black[50]};
  `}
`;

export const Header = styled.div`
  background: black;
  height: 4.375rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonsDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ToggleButton = styled.div<{ isOpen: boolean }>`
  height: 2.5625rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: ${({ theme }) =>
    theme.colorMappings.controlCenterContentBackground};

  ${({ isOpen }) =>
    isOpen &&
    css`
      position: absolute;
      background-color: ${({ theme }) => theme.colors.white[500]};
      bottom: 1rem;
      right: -1.875rem;
      width: 1.9375rem;
      justify-content: center;
    `}
`;

export const StyledChevron = styled(({ isOpen, ...props }) => (
  <ChevronIcon {...props} />
)).attrs({
  color: 'black',
  opacity: '1',
  width: 6,
  height: 11,
})<{ isOpen: boolean }>`
  margin-right: 1.5625rem;
  transform: rotate(180deg);
  height: 0.8125rem;
  width: 0.8125rem;
  ${({ isOpen }) =>
    isOpen &&
    css`
      margin-right: 0;
      transform: rotate(0deg);
    `}
`;

export const ClickableDivTop = styled.div`
  height: 3.25rem;
`;

export const ClickableDivBottom = styled.div`
  flex: 1;
`;
