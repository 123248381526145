import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  HeaderContainer,
  Title,
  ChevronDown,
  EnhancedNewsIcon,
  EnhancedCircleCheckIcon,
} from './DrawerText.styles';
import { HeaderProps } from './types';

export const DrawerText: React.FC<HeaderProps> = ({
  toggleOpenEmailPanel,
  isPanelOpen,
  emailProvided,
}: HeaderProps) => {
  const { t } = useTranslation();
  return (
    <HeaderContainer onClick={toggleOpenEmailPanel} isPanelOpen={isPanelOpen}>
      <ChevronDown isExpanded={isPanelOpen} />
      {!isPanelOpen && (
        <>
          {emailProvided ? (
            <>
              <EnhancedCircleCheckIcon isPanelOpen={isPanelOpen} />
              <Title
                data-testid="email-provided-title"
                isPanelOpen={isPanelOpen}
              >
                {t('We’ve got your email')}
                <span className="no-mobile">
                  : <strong>{emailProvided}</strong>
                </span>
              </Title>
            </>
          ) : (
            <>
              <EnhancedNewsIcon isPanelOpen={isPanelOpen} />
              <Title isPanelOpen={isPanelOpen}>
                <strong>{t('What is your email address?')}</strong>
              </Title>
            </>
          )}
        </>
      )}
    </HeaderContainer>
  );
};
