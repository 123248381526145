import * as React from 'react';
import { Wrapper, Text, Modal } from './TopBarButton.styles';
import { TopBarButtonProps } from './types';

export const TopBarButton: React.FC<TopBarButtonProps> = ({
  buttons,
}: TopBarButtonProps) => {
  const [modal, setModal] = React.useState<boolean>(false);

  const mapButtonText = (buttonType: string) => {
    if (buttonType === 'newProject') {
      return '+ New project';
    }
    if (buttonType === 'newMember') {
      return '+ New member';
    }
    if (buttonType === 'createCustom') {
      return 'Create custom';
    }
  };
  if (!buttons?.length) {
    return null;
  }
  return (
    <>
      {buttons.map(
        ({ type, onClick, disabled, modalText, permissionsCheck }) => {
          if (!permissionsCheck()) {
            return;
          }
          return (
            <Wrapper
              key={type}
              onClick={(e) => onClick(e)}
              disabled={disabled}
              onMouseEnter={() => setModal(true)}
              onMouseLeave={() => setModal(false)}
            >
              <Text>{mapButtonText(type)}</Text>
              {modal && disabled && <Modal>{modalText}</Modal>}
            </Wrapper>
          );
        }
      )}
    </>
  );
};
