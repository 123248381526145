import * as React from 'react';
import {
  AvatarCoupleIcon,
  TrendUpChartIcon,
  CommentIcon,
  SettingsLightIcon,
  PieChartWithSliceIcon,
  BrightIcon,
} from 'Atoms/Icons';
import { userHasPermission } from 'Client/utils/user';
import { Permissions } from 'Client/constants/permissions';
import { ButtonProps } from '../types';

export const buttonsObj: Array<ButtonProps> = [
  {
    name: 'Overview',
    url: '/v5/dashboard/overview',
    icon: <PieChartWithSliceIcon color="black" width={18} height={24} />,
  },
  {
    name: 'Visitors',
    url: '/v5/dashboard/visitors',
    icon: <TrendUpChartIcon color="black" width={18} height={24} />,
  },
  {
    name: 'Respondents',
    url: '/v5/dashboard/respondents',
    icon: <AvatarCoupleIcon color="black" width={17} height={14} />,
  },
  {
    name: 'Contributions',
    url: '/v5/dashboard/contributions',
    icon: <CommentIcon width={17} height={13}  color="black" />,
    checkPermissions: (_user, project) => project.features.planningApps,
  },
  {
    name: 'Trends AI',
    url: '/v5/dashboard/trends-ai',
    icon: <BrightIcon width={22} height={22} color="#333333" />,
    checkPermissions: (_user, project) => !project.features.topicAISection,
  },
  {
    name: 'Settings',
    url: '/v5/dashboard/settings',
    icon: <SettingsLightIcon width={17} height={17} />,
    type: 'menu',
    nestedButtons: [
      {
        name: 'Manage Team',
        url: '/project-centre/team-management?customer=',
        type: 'nested',
      },
      {
        name: 'Project settings',
        url: '/v5/dashboard/settings',
        type: 'nested',
        checkPermissions: (user) =>
          userHasPermission(user, Permissions.SEE_SETTINGS),
      },
      {
        name: 'Plugins and Scripts',
        url: '/v4/dashboard/settings/scripts',
        type: 'nested',
      },
      {
        name: 'Branding',
        url: '/v4/dashboard/branding',
        type: 'nested',
      },
      {
        name: 'Edit demographics',
        url: '/edit/demographics',
        type: 'nested',
      },
      {
        name: 'Edit team',
        url: '/edit/project-team',
        type: 'nested',
      },
      {
        name: 'Edit preferences',
        url: '/edit/preferences',
        type: 'nested',
      },
    ],
  },
];
