import * as React from 'react';
import { DraggableSection } from 'Organisms';
import { BreakPage } from './BreakPage';

interface Props {
  renderCondition: boolean;
  id: string;
  naturalIndex: number;
  onSectionClick?: (t: string, i: number) => void;
  isDraggingOver: boolean;
}

export const BreakPageElement = ({
  renderCondition,
  id,
  naturalIndex,
  onSectionClick,
  isDraggingOver,
}: Props) => {
  if (renderCondition) {
    return (
      <DraggableSection
        id={id}
        index={naturalIndex}
        isDraggingOver={isDraggingOver}
      >
        <BreakPage
          onClick={
            onSectionClick
              ? () => onSectionClick('page-break', naturalIndex)
              : () => null
          }
        />
      </DraggableSection>
    );
  }
  return <></>;
};
