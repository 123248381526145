import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useProject } from 'Client/utils/hooks';
import { CommentsWithLinesIcon } from 'Icons';
import { fetchContributionsCount } from 'Client/services/contributions';
import { ContributionType } from 'Shared/types/contribution';
import { Wrapper } from './ResponsesCounter.styles';

interface Props {
  slug: string;
}

export const ResponsesCounter: React.FC<Props> = ({ slug }: Props) => {
  const { t } = useTranslation();
  const project = useProject();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [responses, setResponses] = React.useState<number>(0);

  const showCounter = typeof responses === 'number' && responses > 0;

  React.useEffect(() => {
    fetchContributionsCount({
      projectId: project._id,
      surveySlug: slug,
      type: [ContributionType.COMMENT, ContributionType.AGREEMENT],
    }).then((count) => {
      setResponses(count);
    });
  }, [slug]);

  if (!showCounter) {
    return null;
  }

  return (
    <Wrapper data-testid="ResponsesCounter-data-view">
      <Link href={`/contributions/proposal/${slug}`} passHref={true}>
        <a>
          <CommentsWithLinesIcon width={20} />
          {isMobile
            ? responses
            : responses === 1
            ? t(`1 response`)
            : t(`{{responses}} responses`, {
                responses,
              })}
        </a>
      </Link>
    </Wrapper>
  );
};
