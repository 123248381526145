import * as React from 'react';
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from 'react-compare-slider';
import { ImageSliderCaptions, ImageSliderWrapper } from './styles';

export const ImageComparison = ({
  backgroundColour = null,
  imageComparisonSlider,
}) => {
  return (
    <ImageSliderWrapper backgroundColour={backgroundColour}>
      <ReactCompareSlider
        position={Number(imageComparisonSlider.startPosition.value) ?? 50}
        itemOne={
          <ReactCompareSliderImage
            src={imageComparisonSlider.beforeLeft.uri}
            srcSet={imageComparisonSlider.beforeLeft.uri}
            alt={imageComparisonSlider.beforeLeft.description}
            style={{ objectPosition: 'left' }}
          />
        }
        itemTwo={
          <ReactCompareSliderImage
            src={imageComparisonSlider.afterRight.uri}
            srcSet={imageComparisonSlider.afterRight.uri}
            alt={imageComparisonSlider.afterRight.description}
            style={{ objectPosition: 'right' }}
          />
        }
      />
      {imageComparisonSlider.showCaption && (
        <ImageSliderCaptions isShown={imageComparisonSlider.showCaption}>
          <span>{imageComparisonSlider.beforeLeft.caption}</span>
          <span>{imageComparisonSlider.afterRight.caption}</span>
        </ImageSliderCaptions>
      )}
    </ImageSliderWrapper>
  );
};
