import { ParsedUrlQuery } from 'querystring';

export const getEditModePath = (
  asPath: string,
  query: { slug: Array<string> } | ParsedUrlQuery,
  locale: string
): string => {
  let newPath = `/${locale}/edit`;
  if (asPath.includes('proposals/') && query.slug) {
    const { slug } = query;
    newPath = `/${locale}/edit/proposals/${slug[0]}`;
  } else if (asPath.includes('map/') && query.slug) {
    const { slug } = query;
    newPath = `/${locale}/edit/map/${slug}`;
  } else {
    newPath = `/${locale}/edit${asPath}`;
  }

  return newPath;
};
