import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { cpBrandName, cpBrandNamePlural } from 'Client/constants/brand';
import {
  COMMONPLACE_ABOUT,
  COMMONPLACE_FACEBOOK,
  COMMONPLACE_TWITTER,
  COMMONPLACE_GENERAL_ENQUIRIES,
  COMMONPLACE_IN_ACTION,
} from 'Client/constants/urls';
import {
  EmailRoundIcon,
  FacebookRoundIcon,
  TwitterRoundIcon,
} from 'Atoms/Icons/Socials';
import {
  Wrapper,
  LogoWrapper,
  LinksWrapper,
  SocialIconsWrapper,
  CommonplaceLogo,
  LinksDiv,
  LogoLink,
} from './LoginFooter.styles';

export const LoginFooter: React.FC = () => {
  const { t } = useTranslation();
  const isMobile = typeof window !== 'undefined' && window.innerWidth < 420;

  return (
    <Wrapper>
      <LogoWrapper>
        {isMobile ? (
          <CommonplaceLogo />
        ) : (
          <LogoLink
            href="https://www.commonplace.is/"
            target="_blank"
            rel="noreferrer"
          >
            <CommonplaceLogo />
          </LogoLink>
        )}
        {isMobile && (
          <SocialIconsWrapper>
            <a target="blank" href={COMMONPLACE_FACEBOOK}>
              <FacebookRoundIcon />
            </a>
            <a target="blank" href={COMMONPLACE_TWITTER}>
              <TwitterRoundIcon />
            </a>
            <a target="blank" href="mailto:hello@commonplace.is">
              <EmailRoundIcon />
            </a>
          </SocialIconsWrapper>
        )}
      </LogoWrapper>
      <LinksDiv>
        <LinksWrapper>
          <a target="blank" href={COMMONPLACE_ABOUT}>
            {t('About {{cpBrandName}}', { cpBrandName })}
          </a>
          <a target="blank" href={COMMONPLACE_GENERAL_ENQUIRIES}>
            {t('Contact us')}
          </a>
          <a target="blank" href={COMMONPLACE_IN_ACTION}>
            {t('Find more {{cpBrandNamePlural}}', { cpBrandNamePlural })}
          </a>
        </LinksWrapper>
        {!isMobile && (
          <SocialIconsWrapper>
            <a target="blank" href={COMMONPLACE_FACEBOOK}>
              <FacebookRoundIcon />
            </a>
            <a target="blank" href={COMMONPLACE_TWITTER}>
              <TwitterRoundIcon />
            </a>
            <a target="blank" href="mailto:hello@commonplace.is">
              <EmailRoundIcon />
            </a>
          </SocialIconsWrapper>
        )}
      </LinksDiv>
    </Wrapper>
  );
};
