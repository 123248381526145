import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { useApolloClient } from '@apollo/client';
import { captureEvent } from '@sentry/node';
import type { HubPageProps } from 'Pages/hub';
import { HubHeader } from 'Client/components/organisms/HubHeader';
import {
  CardWrapper,
  CardTitle,
  CardFocusLink,
  CardDescription,
  CardContent,
} from 'Atoms/Card/ProjectCard/ProjectCard.styles';
import { StatusDot } from 'Atoms/StatusDot';
import { getPlanningAppTitle } from 'Client/pages/planningapps/utils';
import { colorByStatus } from 'Client/constants/planningAppStatus';
import { useProject } from 'Client/utils/hooks';
import { CommentIcon } from 'Atoms/Icons';
import { Category, LoadRing } from 'Molecules';
import { RootState } from 'Client/redux-store';
import { GET_FILTERED_PLANNING_APPS } from 'Client/pages/planningapps/PlanningApps.gql';
import { fetchCommentsCountOfPlanApps } from 'Client/services/planningapp/fetchCommentsCountOfPlanApps';
import {
  PlanningAppPageAndContent,
  PlanningAppStage,
} from 'Shared/types/planningapp';
import HorizontalProposalsReel from '../DynamicProposals/components/HorizontalProposalsReel/HorizontalProposalsReel';
import { CardMedia } from '../DynamicProposals/components/CustomProposalCard/CustomProposalCard.styles';
import {
  ProposalCardComments,
  ProposalsReel,
} from '../DynamicProposals/components';
import {
  Card,
  CardHeader,
  CardFooter,
  Description,
  StatusWrapper,
  CategoryWrapper,
  FooterText,
  FooterWrapper,
  LoadingPanel,
} from './PlanningApps.styles';
interface PlanningAppsProps extends Pick<HubPageProps, 'planningApps'> {
  title?: string;
  hideHubHeader?: boolean;
  setFilteredPlanningApps?: (planningApps: PlanningAppPageAndContent[]) => void;
}
const PlanningApps: React.FC<PlanningAppsProps> = ({
  planningApps,
  title,
  hideHubHeader,
  setFilteredPlanningApps,
}: PlanningAppsProps) => {
  const { filters, queryByArea: reduxQueryByArea } = useSelector(
    (state: RootState) => state.filters
  );
  const { t } = useTranslation();
  const project = useProject();
  const isGla = project?.features?.glaPlanningApps;
  const client = useApolloClient();
  const [isLoading, setIsLoading] = React.useState(false);
  const handleLoadMore = async () => {
    const listViewFilters = [
      ...filters,
      ...(reduxQueryByArea?.references
        ? [
            {
              reference: {
                in: `${reduxQueryByArea?.references
                  .map((ref) => `'${ref}'`)
                  .join(',')}`,
              },
            },
          ]
        : []),
    ];

    try {
      setIsLoading(true);
      const lazyLoading = {
        offset: planningApps?.length || 0,
        limit: 5,
      };
      const { data } = await client.query({
        query: GET_FILTERED_PLANNING_APPS,
        variables: {
          filters: listViewFilters,
          limit: lazyLoading.limit,
          offset: lazyLoading.offset,
        },
      });
      const {
        getFilteredPlanningApps: { planningAppContent },
      } = data;
      const planningAppsData = planningAppContent.map((item) => {
        return {
          content: item,
          slug: item.reference?.split('/').join('-'),
        };
      });

      if (
        planningAppsData?.some(
          (item) => item?.content?.stage === PlanningAppStage.stage3
        )
      ) {
        const numberPerSlug = await fetchCommentsCountOfPlanApps({
          planApps: planningAppsData?.map((item) => ({
            slug: item?.slug,
          })),
          projectId: project?._id,
        });

        const withComments = planningAppsData.map((item) => {
          const contributionsNumber = numberPerSlug?.find(
            (number) => number.slug === item?.slug
          )?.contributionsNumber;

          return {
            ...item,
            ...((contributionsNumber && { contributionsNumber }) || {}),
          };
        });

        return setFilteredPlanningApps([...planningApps, ...withComments]);
      }

      return setFilteredPlanningApps([...planningApps, ...planningAppsData]);
    } catch (error) {
      captureEvent(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!planningApps?.length) return null;

  return (
    <>
      {!hideHubHeader && <HubHeader title={title || t('Have your say')} />}
      {isGla ? (
        <>
          <HorizontalProposalsReel
            tiles={planningApps}
            data-testid="reelreel"
          />
          {typeof window !== 'undefined' && (
            <Waypoint
              data-testid="papp-waypoint"
              scrollableAncestor={window}
              onEnter={handleLoadMore}
              onLeave={() => {}}
              topOffset="50%"
            />
          )}
          {isLoading && (
            <LoadingPanel>
              <LoadRing />
              <p>{t('Loading')}</p>
            </LoadingPanel>
          )}
        </>
      ) : (
        <ProposalsReel
          showCardsCounter={false}
          proposalsCount={planningApps.length}
        >
          {planningApps.map((planApp) => (
            <Card key={planApp.slug}>
              <CardWrapper>
                <CardFocusLink
                  tabIndex={0}
                  href={`/planningapps/${planApp.slug}`}
                >
                  <CardMedia
                    component="img"
                    alt={planApp?.mainImg?.display_name || 'placeholder'}
                    src={
                      planApp?.mainImg?.url ||
                      'https://res.cloudinary.com/commonplace-digital-limited/image/upload/v1686227526/generic_application_image_1_jrtbly.jpg'
                    }
                  />
                  <CardHeader>
                    <StatusWrapper>
                      <StatusDot
                        status={colorByStatus[planApp.content.status]}
                      />
                      <Description>{planApp.content.status}</Description>
                    </StatusWrapper>
                    <Description>{planApp.content.reference}</Description>
                  </CardHeader>
                  <CategoryWrapper>
                    <Category
                      category={planApp.content.category}
                      width={30}
                      height={30}
                    />
                  </CategoryWrapper>
                  <CardContent>
                    <CardTitle>
                      {planApp.content?.name ||
                        getPlanningAppTitle(planApp.content.address)}
                    </CardTitle>
                    <CardDescription>
                      {planApp.content.shortDesc}
                    </CardDescription>
                  </CardContent>
                </CardFocusLink>
                <CardFocusLink
                  tabIndex={0}
                  href={`/planningapps/${planApp.slug}?tab=3`}
                >
                  <CardFooter>
                    {planApp.contributionsNumber > 0 ? (
                      <ProposalCardComments
                        stage={planApp.stage}
                        count={planApp.contributionsNumber}
                      />
                    ) : (
                      <FooterWrapper>
                        <CommentIcon />
                        <FooterText>
                          {planApp.content.status === 'Consultation open'
                            ? 'Be the first to comment'
                            : '0'}
                        </FooterText>
                      </FooterWrapper>
                    )}
                  </CardFooter>
                </CardFocusLink>
              </CardWrapper>
            </Card>
          ))}
        </ProposalsReel>
      )}
    </>
  );
};

export { PlanningApps };
