import styled, { css } from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { editModeMobile } from 'Client/components/theme/utils';

export const Wrapper = styled.div<{ backgroundColour?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ backgroundColour }) =>
    backgroundColour && `background-color: ${backgroundColour};`}
`;

export const FullWidthContainer = styled.div<{
  whiteBackground?: boolean;
  backgroundColour?: string;
  scaledDownLayout?: boolean;
}>`
  width: 100%;

  ${({ scaledDownLayout }) => css`
    ${scaledDownLayout &&
    css`
      width: 120%;
      padding: 0 10%;
    `}
  `}

  background-color: ${({ theme, whiteBackground, backgroundColour }) =>
    backgroundColour
      ? backgroundColour
      : whiteBackground
      ? theme.colorMappings.white
      : theme.colors.grey[50]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const NoOverflowFullWidthContainer = styled(FullWidthContainer)<{
  backgroundColour?: string;
  scaledDownLayou?: boolean;
}>`
  overflow: hidden;
  background-color: ${({ theme, backgroundColour }) =>
    backgroundColour ? backgroundColour : theme.colors.white[500]};

  ${({ scaledDownLayout }) => css`
    ${scaledDownLayout &&
    css`
      width: 120%;
      padding: 0 10%;
    `}
  `}
`;

export const Title = styled(Typography)`
  font-size: 1.25rem;
`;
export const ContentPreviewWrapper = styled.div`
  padding: 0 1.5rem;
  width: 100%;
  max-width: 70rem;
`;

export const QuestionWrapper = styled.div<{
  fullWidth?: boolean;
}>`
  text-align: center;
  margin: 1rem 0;
  width: 100%;
  max-width: 45rem;
  padding: 0 1.5rem;

  ${({ theme, fullWidth }) => `
    ${
      fullWidth
        ? css`
            width: 100%;
            max-width: unset;
            padding: 0;

            .keep-padding {
              padding: 0 1.5rem;
            }
          `
        : ''
    }
    ${editModeMobile(
      theme.breakpoints.down('sm'),
      css`
        width: 100%;
      `
    )}

    ${editModeMobile(
      theme.breakpoints.down('xs'),
      css`
        margin-bottom: 3rem;
      `
    )}
  `}
`;

export const ImageWrapper = styled.div<{
  image: string;
}>`
  background: ${({ image }) => `url(${image})`};
  background-size: cover;
  background-position-x: 50%;
  background-position-y: 50%;
  width: 100%;
  height: 18.75rem;
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
  font-weight: bold;
  font-size: 2.25rem;
  color: black;

  ${({ theme }) => `
    ${editModeMobile(
      theme.breakpoints.down('sm'),
      css`
        font-size: 1.5625rem;
      `
    )}

    ${editModeMobile(
      theme.breakpoints.down('xs'),
      css`
        font-size: 1.125rem;
      `
    )}
  `}
`;

export const ImageTextWrapper = styled.div`
  width: 75rem;
  padding: 0 2rem;
  margin: 0 auto;
  line-height: 1.25;
`;

export const ImageSectionTitle = styled.span`
  background-color: white;
  opacity: 0.8;
  padding: 0 0.8125rem;
  margin: 0;
`;

export const ContentWrapper = styled.div<{
  isEditModeLayout?: boolean;
}>`
  padding-top: 3.5rem;
  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
  ${({ theme, isEditModeLayout }) => `
    ${
      isEditModeLayout
        ? editModeMobile(
            theme.breakpoints.down('sm'),
            css`
              padding-top: 1rem;
              padding-bottom: 1rem;
            `
          )
        : editModeMobile(
            theme.breakpoints.down('sm'),
            css`
              padding-top: 2.5rem;
            `
          )
    }
  `}
`;

export const TitleWrapper = styled(Box)<{
  isEditModeLayout?: boolean;
  backgroundColour?: string;
}>`
  padding-top: 3.5rem;
  ${({ backgroundColour }) =>
    backgroundColour && `background-color: ${backgroundColour}`}
  ${({ theme, isEditModeLayout }) => `
    ${
      isEditModeLayout
        ? editModeMobile(
            theme.breakpoints.down('sm'),
            css`
              padding-top: 1rem;
              padding-bottom: 1rem;
            `
          )
        : editModeMobile(
            theme.breakpoints.down('sm'),
            css`
              padding-top: 2.5rem;
            `
          )
    }
  `}
`;
