import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Loading } from '.';
import { Image, Wrapper } from './Counter.styles';
import { BannerCounterProps as Props } from '../types';

export const CounterReactions: React.FC<Props> = ({
  cta,
  count,
  loading,
}: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <Wrapper cta={cta}>
      <figure>
        <Image
          big
          src="/static/illustrations/girl-comment.png"
          alt={t('A person happy and a comment icon')}
        />
      </figure>

      {!cta ? (
        loading ? (
          <Loading />
        ) : (
          <div>
            {Boolean(count) && <h2>{count.toLocaleString(i18n.language)}</h2>}
            <p>
              {count < 1
                ? t('No reactions to comments')
                : count > 1
                ? t('Reactions to comments')
                : t('Reaction to comments')}
            </p>
          </div>
        )
      ) : null}
    </Wrapper>
  );
};
