import * as React from 'react';
import { Files } from 'Atoms/Files';
import { getThumbnail } from 'Client/services/cloudinary';
import { MediaUpload } from '../types';
import { QuestionWrapper } from './Section.styles';

export const MediaUploadVisualizer = ({ title, files }: MediaUpload) => {
  const withData = files.map((file) => {
    const index = file?.url?.indexOf('projects');
    const publicId = file?.url?.slice(index, -4);
    const thumbnail = file?.previewImage || getThumbnail(publicId);

    return {
      info: [{ header: 'Format', value: file.url.split('.').pop(), width: 8 }],
      thumbnail,
      ...file,
    };
  });

  return (
    <QuestionWrapper data-testid="MediaUploadVisualizer">
      <h2>{title}</h2>
      <Files files={withData} />
    </QuestionWrapper>
  );
};
