import * as React from 'react';
import styled, { css } from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  height: 100vh;
`;

export const Wrapper = styled.div`
  height: 100%;
  flex: 1;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.grey[50]};
`;

export const Content = styled(({ ...props }) => <div {...props} />)`
  height: calc(100vh - 4.735rem);
  overflow: auto;
  ${({ theme }) => css`
    background-color: ${theme.colors.white[500]};
    font-family:
      Source Sans Pro,
      sans-serif;
  `}

  .settings-footer, .add-news-footer {
    z-index: 10;
    margin-top: 4.375rem;
    padding: 0.5rem 2rem;
    padding-bottom: 0.875rem;
    filter: drop-shadow(
      0 -0.125rem 0.938rem ${({ theme }) => theme.colorMappings.footerShadow}
    );
    background-color: ${({ theme }) => theme.colors.white[500]};
    position: fixed;
    bottom: 0;
    left: 0;
    width: ${({ navbarExpanded }) =>
      `calc(100% - ${navbarExpanded ? '15.625rem' : '3.75rem'})`};
    margin-left: ${({ navbarExpanded }) =>
      navbarExpanded ? '15.625rem' : '3.75rem'};
  }

  .add-news-footer {
    padding: 0.875rem 1.011rem 0.911rem 2.5rem;
    display: flex;
    justify-content: space-between;
  }
`;
