import * as React from 'react';
import Head from 'next/head';
import { useTranslation, Trans } from 'react-i18next';
import MuiBox from '@material-ui/core/Box';
import { useRouter } from 'next/router';
import { SeoMetaInfo, Link, Container, Typography } from 'Atoms';
import { Button } from 'Molecules';
import { ProposalPagesTemplate as Template } from 'Templates';
import { useProject, useProposalContext } from 'Client/utils/hooks';
import { PROPOSAL_ACTION_TYPES } from 'Client/context/proposalReducer';
import { changeRouteKeepParams } from 'Client/utils/url';
import { EmptyAnswersPageProps } from './types';
import { FullWidthContainer, OtherProposalsReel } from './components';

export const EmptyAnswersPage: React.FC<EmptyAnswersPageProps> = ({
  proposalTitle,
  proposalSlug,
  otherProposals,
  consentedToNews,
  ...props
}: EmptyAnswersPageProps) => {
  const [, dispatch] = useProposalContext();
  const project = useProject();
  const router = useRouter();
  const { t } = useTranslation();

  React.useEffect(() => {
    dispatch({
      type: PROPOSAL_ACTION_TYPES.SET_FOOTER,
      showFooter: false,
    });
    dispatch({
      type: PROPOSAL_ACTION_TYPES.SET_HEADER,
      showHeader: true,
    });
  }, []);

  const handleEditComment = () =>
    router.push(`/proposals/${proposalSlug}/step1`);

  return (
    <Template
      proposalTitle={proposalTitle}
      proposalSlug={proposalSlug}
      proposalHasQuestions
      {...props}
    >
      <FullWidthContainer>
        <Head>
          <meta name="robots" content="noindex" />
        </Head>
        <SeoMetaInfo
          projectStage={project.stage}
          projectName={project.name}
          page="proposal"
        />
        <Container>
          <MuiBox marginTop={3}>
            <Typography
              align={'inherit'}
              variant="h2"
              data-testid="EmptyAnswersPageHeader"
            >
              {t(`You didn't answer any questions...`)}
            </Typography>
            <MuiBox marginTop={1}>
              <Typography variant="body2">
                <Trans>
                  {'Make your opinion count and '}
                  <Button link onClick={handleEditComment} removeDefaultStyling>
                    answer the proposal
                  </Button>
                  .
                </Trans>
              </Typography>
              {!consentedToNews && (
                <Typography variant="body2">
                  <Trans>
                    {
                      'No time to answer now? Sign up for project updates on the '
                    }
                    <Link
                      to={changeRouteKeepParams(router.asPath, '/news')}
                      noPadding
                      noUnderline
                      style={{ fontSize: '1rem' }}
                    >
                      {'news page'}
                    </Link>
                    .
                  </Trans>
                </Typography>
              )}
            </MuiBox>
          </MuiBox>
        </Container>
        {otherProposals && otherProposals.length > 0 ? (
          <OtherProposalsReel proposals={otherProposals} project={project} />
        ) : (
          <Container>
            <Link to="/" noPadding>
              {t('Back to home page')}
            </Link>
          </Container>
        )}
      </FullWidthContainer>
    </Template>
  );
};
