import * as React from 'react';
import { Container } from 'Atoms';

interface Props {
  type: string;
  Component: React.ReactElement;
}

export const Section: React.FC<Props> = (props: Props) => {
  const { Component, type } = props;
  const fullScreenItems = ['hero'];
  const isFullScreen = fullScreenItems.includes(type);

  if (!isFullScreen) {
    return (
      <>
        <Container>{Component}</Container>
      </>
    );
  }
  return <>{Component}</>;
};
