import * as React from 'react';
import { Dot } from './StatusDot.styles';
import { StatusDotProps } from './types';

export const StatusDot: React.FC<StatusDotProps> = ({
  status,
  glaColor,
  width = 0.75,
  height = 0.75,
}) => {
  return (
    <Dot status={status} width={width} height={height} glaColor={glaColor} />
  );
};
