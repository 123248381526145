import styled from 'styled-components';

export const BreakPageLine = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
  &::before,
  &::after {
    display: inline-block;
    content: '';
    border-top: 0.125rem solid ${({ theme }) => theme.colorMappings.black};
    width: 32%;
  }
  &::before {
    margin: 0 1rem 0 0;
  }
  &::after {
    margin: 0 0 0 1rem;
  }
`;
