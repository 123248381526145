import React from 'react';
import { StoppingUpOrderIcon } from '../StoppingUpOrder';
// import { Buildings } from '../Buildings';
import AmendedApplication from './AmendedApplication';
import CarParking from './CarParking';
import GreenBelt from './GreenBelt';
import ImpactsStrategicSite from './ImpactsStrategicSite';
import LossOfEmploymentUse from './LossOfEmploymentUse';
import LossOfHomes from './LossOfHomes';
import LossOfPlayingField from './LossOfPlayingField';
import MiningOperations from './MiningOperations';
import NonResidentialDevelopment from './NonResidentialDevelopment';
import ResidentialDevelopment from './ResidentialDevelopment';
import SeeProposal from './SeeProposal';
import TallBuildings from './TallBuildings';
import TransportInfrastructure from './TransportInfrastructure';
import Undefined from './Undefined';
import WasteRecycling from './WasteRecycling';
interface GetPlanningAppsCategoryIconProps {
  icon: string;
  width?: number;
  height?: number;
}
export const getPlanningAppsCategoryIcon = ({
  icon,
  width,
  height,
}: GetPlanningAppsCategoryIconProps) => {
  switch (icon) {
    case 'Amended application':
      return <AmendedApplication width={width} height={height} />;
    case 'Car parking':
      return <CarParking width={width} height={height} />;
    case 'Green belt':
      return <GreenBelt width={width} height={height} />;
    case 'Impacts strategic site':
      return <ImpactsStrategicSite width={width} height={height} />;
    case 'Loss of employment use':
      return <LossOfEmploymentUse width={width} height={height} />;
    case 'Loss of homes':
      return <LossOfHomes width={width} height={height} />;
    case 'Loss of playing field':
      return <LossOfPlayingField width={width} height={height} />;
    case 'Mining operations':
      return <MiningOperations width={width} height={height} />;
    case 'Non-residential':
      return <NonResidentialDevelopment width={width} height={height} />;
    case 'Residential':
      return <ResidentialDevelopment width={width} height={height} />;
    case 'See proposal':
      return <SeeProposal width={width} height={height} />;
    case 'Tall buildings':
      return <TallBuildings width={width} height={height} />;
    case 'Transport infrastructure':
      return <TransportInfrastructure width={width} height={height} />;
    case 'Waste / recycling':
      return <WasteRecycling width={width} height={height} />;
    case 'Stopping up order':
      return <StoppingUpOrderIcon width={width} height={height} />;
    default:
      return <Undefined width={width} height={height} />;
  }
};
