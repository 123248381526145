import * as React from 'react';
import {
  CalendarBoldIcon,
  CalendarCrossBoldIcon,
  LikeBoldIcon,
  MessageCircleLinesBoldIcon,
} from 'Atoms/Icons';
import { EyeOpenBoldIcon } from 'Atoms/Icons/EyeBold/EyeOpenBold';

export const headers = [
  { text: 'Launch date', icon: <CalendarBoldIcon height={20} width={20} /> },
  {
    text: 'Close date',
    icon: <CalendarCrossBoldIcon height={20} width={20} />,
  },
  { text: 'Visitors', icon: <EyeOpenBoldIcon height={20} width={20} /> },
  {
    text: 'Comments',
    icon: <MessageCircleLinesBoldIcon height={20} width={20} />,
  },
  { text: 'Agreements', icon: <LikeBoldIcon height={20} width={20} /> },
];
