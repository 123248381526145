import * as React from 'react';
import { captureException } from '@sentry/node';
import { useRouter } from 'next/router';
import fetch from 'isomorphic-unfetch';
import Head from 'next/head';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { UPLOAD_FILE_TO_S3 } from 'Client/utils/gql/mutations.gql';
import { SeoMetaInfo } from 'Atoms';
import { ProposalPagesTemplate as Template } from 'Templates';
import {
  useProposalContext,
  useProject,
  useUser,
  useAnalytics,
  MixpanelEventTypes,
  usePermissions,
} from 'Client/utils/hooks';
import { PROPOSAL_ACTION_TYPES } from 'Client/context/proposalReducer';
import { saveCommentFirstTime } from 'Client/services/contributions';
import { handleSubmitComment } from 'Client/services/contributionFlow';
import { checkOnUserLanguage } from 'Client/services/user';
import { EditModeButton } from 'Pages/edit/components/EditModeButton';
import { ContributionType } from 'Shared/types/contribution';
import {
  getLocalItem,
  setLocalItem,
  CONFIRMATION_EMAIL_SENT_ITEM,
  CONTRIBUTION_SESSION_ITEM,
} from 'Client/utils/localstorage';
import { Permissions } from 'Client/constants/permissions';
import { PageTypes } from 'Shared/types/page';
import { canUserSeeDrafts } from 'Client/services/proposals';
import {
  ProposalPageProps,
  QuestionValue,
  BlockContentStages,
  ProposalStage,
} from './types';
import {
  OtherProposalsReel,
  SectionWrapper,
  ProposalSectionRender,
  FinishedEngagementNotice,
} from './components';
import {
  existingAnswersNeedReset,
  makeInitialAnswers,
  areNoQuestionsAnswered,
  makeMappedAnswers,
} from './utils';
import { NoPermissionPage } from '../noPermission';

/**
 * Wraps a component to render `NoPermissionPage` if the proposal is in draft stage
 * and the user doesn't have permission to view drafts.
 *
 * @todo Move this to its own file. Due to an import cycle in the login page,
 * `withDraftProtection` gets called before initialisation if it's located on its own
 * file. Once all imports of files in `/pages` have been fixed it should be safe to
 * move this.
 *
 * @param C - The component to wrap.
 * @returns The wrapped component.
 */
export const withDraftProtection = <P extends { stage?: ProposalStage }>(
  C: React.FC<P>
): React.FC<P> =>
  function ComponentWithPermission(props: P) {
    const { user } = useUser();

    const isDraft = props.stage === ProposalStage.DRAFT;
    const allow = !isDraft || canUserSeeDrafts(user);

    return allow ? <C {...props} /> : <NoPermissionPage />;
  };

export const ProposalPage: React.FC<ProposalPageProps> = withDraftProtection(
  ({
    proposalId,
    proposalTitle,
    proposalSlug,
    stage,
    questions,
    steps,
    otherProposals,
    showMinutesLeft,
    contributionsNumber,
    prefillAnswersFromContribution,
    numOfProposalSteps,
    ...props
  }) => {
    const project = useProject();
    const { trackEvent } = useAnalytics();
    const [
      {
        answers,
        voiceAnswers,
        contributionId,
        currentStep,
        totalSteps,
        showFooter,
        signupEmail,
        customQuestionOptions,
        transcribeOnly,
      },
      dispatch,
    ] = useProposalContext();
    const { user } = useUser();
    const router = useRouter();
    const { i18n } = useTranslation();
    const { can } = usePermissions();
    const [uploadToS3] = useMutation(UPLOAD_FILE_TO_S3);

    const hideQuestion =
      !can(Permissions.SEE_QUESTIONS_AFTER_CLOSURE) &&
      (BlockContentStages.includes(stage) ||
        BlockContentStages.includes(project.stage));

    React.useEffect(() => {
      if (
        questions &&
        (Object.keys(answers).length === 0 ||
          existingAnswersNeedReset(questions, answers))
      ) {
        if (router.query?.cid) {
          // initialize with existing answers if query id present
          prefillAnswersFromContribution(router.query.cid as string);
        } else {
          // initialize/reset answers either when answers are empty
          // or when switching between proposals
          const initAnswers = makeInitialAnswers(questions);
          dispatch({
            type: PROPOSAL_ACTION_TYPES.SET_ANSWERS,
            answers: initAnswers,
          });
          dispatch({
            type: PROPOSAL_ACTION_TYPES.SET_CONTRIBUTION_ID,
            contributionId: undefined,
          });
          dispatch({
            type: PROPOSAL_ACTION_TYPES.SET_CONTRIBUTION_TYPE,
            contributionType: undefined,
          });
        }
      }
    }, [questions, answers, router.query]);

    React.useEffect(() => {
      dispatch({
        type: PROPOSAL_ACTION_TYPES.SET_TOTAL_STEPS,
        totalSteps: steps.length,
      });
    }, [steps]);

    React.useEffect(() => {
      const isLastStep = currentStep === totalSteps;
      if (isLastStep && (!questions || questions.length === 0)) {
        dispatch({
          type: PROPOSAL_ACTION_TYPES.SET_FOOTER,
          showFooter: false,
        });
      } else if (!showFooter) {
        dispatch({
          type: PROPOSAL_ACTION_TYPES.SET_FOOTER,
          showFooter: true,
        });
      }
    }, [currentStep, totalSteps, questions, showFooter]);

    const handleChange = (questionId: string, answer: QuestionValue) => {
      const answersExist = Object.keys(answers).find(
        (answer) => answers[answer] !== ''
      );

      if (!answersExist) {
        // trigger the first time a question is answered
        trackEvent(MixpanelEventTypes.ADDING_COMMENT, {
          path: router.asPath,
        });
      }

      const newAnswers = { ...answers };
      if (
        (Array.isArray(answer) && answer.length === 0) ||
        answer == null ||
        answer === ''
      ) {
        // not ideal, but the whole CF treats empty strings as defaults
        // in future we should move away from the expectation that all answers exist and start as strings
        newAnswers[questionId] = '';
      } else {
        newAnswers[questionId] = answer;
      }
      dispatch({
        type: PROPOSAL_ACTION_TYPES.SET_ANSWERS,
        answers: newAnswers,
      });
    };

    const handleSaveDraftComment = async () => {
      console.time('ContributionFlow - [1.1] - handleSaveDraftComment');
      console.time(
        'ContributionFlow - [1.1] - handleSaveDraftComment - No answers'
      );
      console.time(
        'ContributionFlow - [1.1] - handleSaveDraftComment - Existent Contribution'
      );
      console.time(
        'ContributionFlow - [1.1] - handleSaveDraftComment - New Contribution'
      );
      console.time(
        'ContributionFlow - [1.1] - handleSaveDraftComment - New Gaming Contribution'
      );
      try {
        const noQuestionsAnswered =
          areNoQuestionsAnswered(answers) &&
          areNoQuestionsAnswered(voiceAnswers as Record<string, string>);
        if (!noQuestionsAnswered) {
          // do not save comment if no questions answered
          if (contributionId) {
            // updating existing answer
            try {
              const contributionData = { answers, language: i18n.language };
              await fetch(`/api/contributions/${contributionId}`, {
                method: 'PATCH',
                body: JSON.stringify(contributionData),
              });
              if (project?.features?.trackContributionFlow) {
                trackEvent(MixpanelEventTypes.TRACK_CONTRIBUTION_FLOW, {
                  fileName: 'src/client/pages/proposals/ProposalPage.tsx',
                  functionName: 'handleSaveDraftComment',
                  database: 'acorn',
                  fieldToBeUpdated: Object.keys(contributionData),
                  gaudiUpdate: null,
                  acornUpdate: contributionData,
                  userId: user._id,
                  demographicsId: 'none',
                  contributionId: contributionId,
                });
              }
              console.timeEnd(
                'ContributionFlow - [1.1] - handleSaveDraftComment - Existent Contribution'
              );

              return {
                noQuestionsAnswered,
                contrId: contributionId,
                isGaming: false,
              };
            } catch (error) {
              captureException(
                `error in handleSaveDraftComment @ ProposalPage.tsx : ${error}`
              );
            } finally {
              console.timeEnd(
                'ContributionFlow - [1.1] - handleSaveDraftComment - Existent Contribution'
              );
            }
          } else {
            // saving for the first time
            const contr = await saveCommentFirstTime({
              project,
              user,
              proposalId,
              proposalSlug,
              answers,
              language: i18n.language,
              transcribeOnly,
            });
            if (contr?.gaming) {
              console.timeEnd(
                'ContributionFlow - [1.1] - handleSaveDraftComment - New Gaming Contribution'
              );

              return {
                noQuestionsAnswered,
                contrId: null,
                isGaming: Boolean(contr.gaming),
              };
            }
            dispatch({
              type: PROPOSAL_ACTION_TYPES.SET_CONTRIBUTION_ID,
              contributionId: contr?._id,
            });
            dispatch({
              type: PROPOSAL_ACTION_TYPES.SET_CONTRIBUTION_TYPE,
              contributionType: ContributionType.COMMENT,
            });
            await checkOnUserLanguage({ user, i18nLang: i18n.language });
            console.timeEnd(
              'ContributionFlow - [1.1] - handleSaveDraftComment - New Contribution'
            );

            return {
              noQuestionsAnswered,
              contrId: contr?._id,
              isGaming: false,
            };
          }
        }
        console.timeEnd(
          'ContributionFlow - [1.1] - handleSaveDraftComment - No answers'
        );
        return { noQuestionsAnswered, contrId: null, isGaming: false };
      } catch (error) {
        captureException(error);
      } finally {
        console.timeEnd('ContributionFlow - [1.1] - handleSaveDraftComment');
      }
    };

    const triggerSubmitComment = async (footerEmail?: string) => {
      console.time('ContributionFlow - [1.2] - triggerSubmitComment');
      const mappedAnswers = makeMappedAnswers({
        answers,
        questions,
        customQuestionOptions,
      });
      const feelingAnswer = mappedAnswers.find(
        (answer) => answer.name === 'feeling'
      );
      const result = await handleSubmitComment({
        storedUserId: getLocalItem(CONTRIBUTION_SESSION_ITEM),
        footerEmail,
        signupEmail,
        contributionId,
        project,
        user,
        proposalId,
        proposalSlug,
        answers,
        voiceAnswers,
        questions,
        router,
        dispatch,
        numOfProposalSteps,
        feelingAnswer,
        confEmailSent: getLocalItem(CONFIRMATION_EMAIL_SENT_ITEM),
        setLocalItem,
        transcribeOnly,
      });
      await Promise.all(
        Object.keys(voiceAnswers || {}).map(async (key) => {
          const id = result?.id;
          const blob = await fetch(voiceAnswers[key]).then((r) => r.blob());
          const form = new FormData();
          form.append('file', blob, `${id}_${key}.wav`);

          uploadToS3({ variables: { file: form.get('file') } });
        })
      );
      console.timeEnd('ContributionFlow - [1.2] - triggerSubmitComment');
      return result;
    };

    const isLastStepOfProposalWithoutQuestions =
      currentStep === totalSteps && (!questions || questions.length === 0);

    const sortedSteps = steps?.slice().sort((a, b) => a.order - b.order) || [];

    return (
      <Template
        currentStep={sortedSteps[currentStep - 1]}
        proposalTitle={proposalTitle}
        proposalSlug={proposalSlug}
        onSaveDraftComment={handleSaveDraftComment}
        contributionsNumber={contributionsNumber}
        onSubmitComment={triggerSubmitComment}
        showGamingBanner={true}
        hideQuestion={hideQuestion}
        {...props}
      >
        <SectionWrapper>
          <Head>
            <meta name="robots" content="noindex" />
          </Head>
          <SeoMetaInfo
            projectStage={project.stage}
            projectName={project.name}
            proposalTitle={proposalTitle}
            page={PageTypes.PROPOSAL}
          />
          {sortedSteps.map(
            (
              {
                title,
                description,
                question,
                sections: nestedSections,
                image,
                imageComparisonSlider,
                imageAndGrid,
                accordionContent,
                twoColumns,
              },
              index
            ) =>
              nestedSections ? (
                <div
                  id={`step${index + 1}`}
                  key={`step${index + 1}`}
                  className={proposalSlug}
                >
                  {nestedSections
                    .sort((a, b) => (a.order > b.order ? 1 : -1))
                    .map((nestedSection, nestedIndex) => {
                      return (
                        <ProposalSectionRender
                          backgroundColour={nestedSection.backgroundColour}
                          key={nestedIndex}
                          title={nestedSection.title}
                          description={nestedSection.description}
                          question={nestedSection.question}
                          value={answers[nestedSection.question?.id]}
                          voiceValue={
                            voiceAnswers[nestedSection.question?.id] as string
                          }
                          steps={steps}
                          handleChange={handleChange}
                          image={nestedSection.image}
                          imageComparisonSlider={
                            nestedSection.imageComparisonSlider
                          }
                          imageAndGrid={nestedSection.imageAndGrid}
                          accordionContent={nestedSection.accordionContent}
                          twoColumns={nestedSection.twoColumns}
                          showProgressBar={nestedIndex === 0}
                          showMinutesLeft={
                            typeof showMinutesLeft === 'boolean'
                              ? showMinutesLeft
                              : true
                          }
                          hideQuestion={hideQuestion}
                          files={nestedSection.files}
                          pagePreview={nestedSection.pagePreview}
                          navMap={nestedSection.navMap}
                          imageMapPro={nestedSection.imageMapPro}
                          sectionId={question?.id ?? nestedSection?._id}
                        />
                      );
                    })}
                </div>
              ) : (
                <div
                  id={`step${index + 1}`}
                  key={`step${index + 1}`}
                  className={proposalSlug}
                >
                  <ProposalSectionRender
                    title={title}
                    description={description}
                    question={question}
                    value={answers[question?.id]}
                    steps={steps}
                    handleChange={handleChange}
                    image={image}
                    imageComparisonSlider={imageComparisonSlider}
                    imageAndGrid={imageAndGrid}
                    accordionContent={accordionContent}
                    twoColumns={twoColumns}
                    showProgressBar
                    showMinutesLeft={
                      typeof showMinutesLeft === 'boolean'
                        ? showMinutesLeft
                        : true
                    }
                    hideQuestion={hideQuestion}
                  />
                </div>
              )
          )}
          {hideQuestion && (
            <FinishedEngagementNotice
              proposal={{
                title: proposalTitle,
                slug: proposalSlug,
                image: undefined,
              }}
            />
          )}
        </SectionWrapper>
        <EditModeButton />
        {isLastStepOfProposalWithoutQuestions && otherProposals && (
          <OtherProposalsReel
            proposals={otherProposals}
            project={project}
            showUnderline={!hideQuestion}
            isMultiStep={steps?.length > 1}
          />
        )}
      </Template>
    );
  }
);
