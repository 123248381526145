import * as React from 'react';
import Link from 'next/link';
import styled, { css } from 'styled-components';
import PlayIcon from '@material-ui/icons/PlayArrow';
import CheckIcon from '@material-ui/icons/Check';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MuiTimelineDot from '@material-ui/lab/TimelineDot';
import { editModeMobile } from 'Client/components/theme/utils';
import { MilestoneStageType } from 'Client/pages/timeline/types';
import { TimelineDotProps, TimelineSeparatorProps } from './types';

interface MilestoneItemProps {
  stage: MilestoneStageType;
}

const icons = {
  active: <PlayIcon />,
  completed: <CheckIcon />,
  planned: <MoreHorizIcon />,
};

const MilestoneItem: React.FC<MilestoneItemProps> = ({
  stage,
}: MilestoneItemProps) => {
  return (
    <>
      {stage === 'planned' && <TimelineSeparator stage={stage} />}
      <Link href="/timeline">
        <TimelineDot className={`${stage}-timelineDot`} stage={stage}>
          {icons[stage]}
        </TimelineDot>
      </Link>
      {stage === 'completed' && <TimelineSeparator stage={stage} />}
    </>
  );
};

const TimelineDot = styled(({ stage, ...props }: TimelineDotProps) => (
  <MuiTimelineDot {...props} />
))`
  .MuiSvgIcon-root {
    z-index: 1;
    color: white;
    width: 2.5rem;
    height: 2.5rem;
  }

  width: 3.8rem;
  height: 3.8rem;
  min-width: 3.8rem;
  min-height: 3.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  box-shadow: ${({ theme }) => `0 0 1.25rem 0 ${theme.colorMappings.shadow}`};
  position: relative;

  @keyframes pulse-dot {
    0% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
    }
  }

  ${({ theme, stage }) => css`
    ${stage == 'completed' && `background-color:${theme.colors.green[700]}`};
    ${stage == 'active' && `background-color:${theme.colors.purple[600]}`};
    ${stage == 'planned' &&
    `
      background-color:${theme.colors.white[500]};

      .MuiSvgIcon-root {
        color: ${theme.colors.grey[300]};
      }
    `}
  `}

  ${({ theme, stage }) =>
    stage == 'active' &&
    css`
      &:before {
        content: '';
        position: absolute;
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        background-color: ${theme.colors.purple[600]};
        z-index: 1;
        opacity: 0.2;
        animation: pulse-dot 2.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s
          infinite;
      }
    `};

  ${({ theme, stage }) => css`
    ${stage == 'active' &&
    editModeMobile(
      theme.breakpoints.down('xs'),
      css`
        &:before {
          content: '';
          position: absolute;
          width: 3.125rem;
          height: 3.125rem;
          min-width: 3.125rem;
          min-height: 3.125rem;

          border-radius: 50%;
          background-color: ${theme.colors.purple[600]};
          z-index: 1;
          opacity: 0.2;
          animation: pulse-dot 2.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s
            infinite;
        }
      `
    )}

    ${editModeMobile(
      theme.breakpoints.down('xs'),
      css`
        width: 2.1875rem;
        height: 2.1875rem;
        min-width: 2.1875rem;
        min-height: 2.1875rem;

        .MuiSvgIcon-root {
          width: 1.5625rem;
          height: 1.5625rem;
        }
      `
    )}
  `}
`;

const TimelineSeparator = styled('hr')<TimelineSeparatorProps>`
  width: 13rem;
  min-width: 13rem;
  border: none;
  border-top: 0.25rem dotted ${({ theme }) => theme.colors.grey[300]};
  height: 0.25rem;
  margin: 0;

  color: ${({ theme }) => theme.colors.white[500]};
  background-color: ${({ theme }) => theme.colors.white[500]};

  ${({ theme, stage }) => css`
    ${stage === 'completed' &&
    css`
      border-top: 0.25rem solid ${theme.colors.grey[300]};
    `}

    ${editModeMobile(
      theme.breakpoints.down('sm'),
      css`
         {
          width: 5rem;
          min-width: 5rem;
        }
      `
    )}
  `};
`;

export { MilestoneItem };
