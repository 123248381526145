import { useRouter } from 'next/router';
import { ValidationStatusData } from 'Client/utils/validators';
import { useAnalytics } from '.';
import { useUser } from '../useUser';
import { MixPanelData, MixpanelEventTypes } from './types';

const useTrackEmailValidation = () => {
  const { trackEvent } = useAnalytics();
  const { user } = useUser();
  const router = useRouter();

  return (
    email: string,
    validation: ValidationStatusData | null,
    additionalData?: MixPanelData
  ) => {
    const mixpanelData: MixPanelData = {
      role: user?.role?.name,
      path: router.asPath,
      email,
      ...additionalData,
    };

    if (validation) {
      trackEvent(
        validation.type === 'error'
          ? MixpanelEventTypes.EMAIL_VALIDATION_HARD
          : MixpanelEventTypes.EMAIL_VALIDATION_SOFT,
        mixpanelData
      );
    } else {
      trackEvent(MixpanelEventTypes.EMAIL_VALIDATION_OK, mixpanelData);
    }
  };
};

export default useTrackEmailValidation;
