import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Link from 'next/link';
import { HubHeader } from 'Client/components/organisms/HubHeader';
import { ProjectTeamPageProps } from './types';
import { StakeholderWrapper, Card, Image, Title } from './ProjectTeam.styles';

const ProjectTeam: React.FC<ProjectTeamPageProps> = ({
  stakeholders,
}: ProjectTeamPageProps) => {
  const { t } = useTranslation();

  return (
    <>
      <HubHeader
        title={t('Meet the team')}
        anchorText={t('Learn more')}
        path="project-team"
      />
      <StakeholderWrapper container spacing={5}>
        {stakeholders
          .filter(
            (item) =>
              typeof item.showOnLanding == 'undefined' ||
              item.showOnLanding == true
          )
          .map((stakeholder, index) => {
            return (
              <Grid
                item
                xs={6}
                sm={4}
                md={3}
                lg={2}
                key={`${stakeholder.name}-${index}`}
              >
                <Link href="/project-team" passHref={true}>
                  <a>
                    <Card>
                      <Image
                        src={stakeholder.logo}
                        alt={stakeholder.logoAltText}
                      />
                    </Card>
                  </a>
                </Link>

                <Title variant="h3">{stakeholder.name}</Title>
              </Grid>
            );
          })}
      </StakeholderWrapper>
    </>
  );
};

export { ProjectTeam };
