import * as React from 'react';
import styled, { css } from 'styled-components';
import { Button as BaseButton } from 'Molecules';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      color: ${theme.colorMappings.greyTextDarker};
      font-size: 1.125rem;
      text-align: center;
    }
  `}
`;

export const Button = styled(({ ...props }) => <BaseButton {...props} />)`
  ${({ theme }) => css`
    min-height: 2rem;
    margin: 1.125rem;
    padding: 0.25rem 0.5rem;
    border: 0;
    border-radius: 0.1875rem;

    svg {
      ${theme.direction === 'rtl' ? 'margin-left' : 'margin-right'}: 0.5rem;
    }

    :hover {
      border: 0;
    }
  `}
`;
