import * as React from 'react';
import { ProjectNavigation } from 'Organisms';
import { useWindowSize } from 'Client/utils/hooks/windowResize';
import { Masthead as DesktopHeader } from 'Organisms';
import { useProject } from 'Client/utils/hooks';
import { Masthead as MobileHeader } from '../ProposalPages/components';
import { Content } from './HiddenCommentsPages.styles';
import { Wrapper } from '../EditorPages.styles';
import { HiddenCommentsPagesTemplateProps } from './types';

export const HiddenCommentsPagesTemplate: React.FC<
  HiddenCommentsPagesTemplateProps
> = ({ children, title }: HiddenCommentsPagesTemplateProps) => {
  const size = useWindowSize();
  const project = useProject();
  const [isDesktop, setIsDesktop] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (size[0] >= 960) {
      setIsDesktop(true);
      return;
    }
    setIsDesktop(false);
  }, [size]);
  return (
    <Wrapper>
      {isDesktop ? (
        <DesktopHeader project={project} />
      ) : (
        <MobileHeader title={title} />
      )}
      {isDesktop && <ProjectNavigation />}
      <Content isDesktop={isDesktop}>{children}</Content>
      {!isDesktop && <ProjectNavigation />}
    </Wrapper>
  );
};

export default HiddenCommentsPagesTemplate;
