import styled, { css } from 'styled-components';

export const ImageSliderWrapper = styled.div<{ backgroundColour?: string }>`
  max-width: 75rem;
  padding: 1rem 1.5rem;
  ${({ backgroundColour }) =>
    backgroundColour && `background-color: ${backgroundColour};`}
`;

export const ImageSliderCaptions = styled.div<{ isShown?: boolean }>`
  ${({ isShown, theme }) => css`
    visibility: ${isShown ? 'visible' : 'hidden'};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    bottom: 1.5rem;

    span {
      ${theme.texts.smallLabel}
      padding: 0.2rem 0.4rem;
      background-color: ${theme.colorMappings.black};
      color: ${theme.colorMappings.white};
    }
  `}
`;
