import { gql } from '@apollo/client';

export const GET_FILTERED_PLANNING_APPS = gql`
  query GetFilteredPlanningApps(
    $filters: [JSONObject]
    $limit: Int
    $offset: Int
  ) {
    getFilteredPlanningApps(filters: $filters, limit: $limit, offset: $offset) {
      planningAppContent {
        reference
        glaReference
        status
        postcode
        category
        shortDesc
        address
        stage
        validatedDate
        name
        images
      }
      count
    }
  }
`;
