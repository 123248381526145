import * as React from 'react';
import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
`;

export const Wrapper = styled.div`
  height: 100%;
  flex: 1;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.grey[50]};
`;

export const Content = styled(({ isBannerShown, ...props }) => (
  <div {...props} />
))`
  height: calc(100vh - 3.75rem);
  overflow: auto;
  display: flex;
  background-color: ${({ theme }) =>
    theme.colorMappings.controlCenterContentBackground};
  font-family: Source Sans Pro, sans-serif;
`;
