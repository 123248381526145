import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { ArrowIcon } from 'Atoms/Icons';
import { useEditModeLayout } from 'Client/context/editModeLayoutContext';
import { useEditModeContext } from 'Client/utils/hooks';
import {
  FilesContainer,
  CarouselItem,
  ImageButton,
  DisplayContainer,
  Wrapper,
  DisplayCircles,
  Circle,
} from './CarouselVisualizer.styles';
import { VisualizerComponentProps } from './types';
import { getImageUrlWidthBased } from './utils';

export const CarouselVisualizer: React.FC<VisualizerComponentProps> = ({
  files,
  windowWidth,
  windowHeight,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [isEditModeLayout] = useEditModeLayout();
  const [editModeState] = useEditModeContext();
  const editModeMobileLayout =
    isEditModeLayout && editModeState.editModeLayout.previewMode === 'mobile';

  const [currentMargin, setCurrentMargin] = React.useState(0);
  const [imageIndex, setImageIndex] = React.useState(0);

  const WrapperRef = React.useRef(null);
  const wrapperWidth = WrapperRef?.current?.clientWidth;

  const imageCfHeight = windowHeight - windowHeight * (isMobile ? 0.7 : 0.3);
  const imageCfWidth = windowWidth - windowWidth * (isMobile ? 0.1 : 0.5);

  const imageEditModeWidth = wrapperWidth - 40;
  const imageEditModeHeight = windowHeight - windowHeight * 0.7;

  const imageWidth = editModeMobileLayout ? imageEditModeWidth : imageCfWidth;
  const imageHeight = editModeMobileLayout
    ? imageEditModeHeight
    : imageCfHeight;

  const leftButton = React.useRef<HTMLSpanElement>(null);
  const rightButton = React.useRef<HTMLSpanElement>(null);

  const imagesGap = 160;
  React.useEffect(() => {
    setCurrentMargin(imageWidth * imageIndex + imagesGap * imageIndex);
  }, [imageIndex]);

  const removeButtons = () => {
    leftButton.current.style.opacity = '0';
    rightButton.current.style.opacity = '0';
    setTimeout(() => {
      leftButton.current.style.opacity = '100';
      rightButton.current.style.opacity = '100';
    }, 300);
  };

  if (!files?.length) return <p>{t('Carousel')}</p>;

  return (
    <Wrapper ref={WrapperRef}>
      <DisplayContainer
        width={imageWidth}
        height={imageHeight}
        editMode={isEditModeLayout}
      >
        <ImageButton
          ref={leftButton}
          disabled={imageIndex === 0}
          height={imageHeight}
          side="left"
          onClick={() => {
            if (imageIndex === 0)
              return (leftButton.current.style.display = 'none');

            setImageIndex(imageIndex - 1);
            removeButtons();
          }}
        >
          <ArrowIcon color="white" />
        </ImageButton>
        <FilesContainer
          marginRight={currentMargin}
          imagesGap={imagesGap / 16} // rem
        >
          {files.map((file) => {
            return (
              <CarouselItem key={file.url}>
                <img
                  src={getImageUrlWidthBased({
                    width: Math.ceil(imageWidth),
                    height: Math.ceil(imageHeight),
                    url: file.url,
                  })}
                  alt={file.alt}
                  width={imageWidth}
                  height={imageHeight}
                />
              </CarouselItem>
            );
          })}
        </FilesContainer>
        <ImageButton
          ref={rightButton}
          height={imageHeight}
          side="right"
          disabled={imageIndex === files.length - 1}
          onClick={() => {
            if (imageIndex === files.length - 1)
              return (rightButton.current.style.display = 'none');

            setImageIndex(imageIndex + 1);
            removeButtons();
          }}
        >
          <ArrowIcon color="white" />
        </ImageButton>
      </DisplayContainer>
      <DisplayCircles>
        {files.map((file, index) => (
          <Circle
            key={file.url}
            filled={index === imageIndex}
            onClick={() => {
              setImageIndex(index);
            }}
          />
        ))}
      </DisplayCircles>
    </Wrapper>
  );
};
