import * as React from 'react';
import styled, { css } from 'styled-components';
import { HubPagesTemplate as Template } from 'Templates';
import { Container, SeoMetaInfo, Typography } from 'Atoms';
import { HtmlDescription } from 'Molecules';
import { Proposals } from 'Pages/hub/components';
import { useProject } from '../../utils/hooks/useProject';
import { ProposalsPageProps } from '../hub/types';

export const ProposalsPage: React.FC<ProposalsPageProps> = ({
  proposals,
  title,
  description,
  sectionTitle,
  ...props
}) => {
  const project = useProject();

  return (
    <Template {...props}>
      <SeoMetaInfo
        projectStage={project.stage}
        projectName={project.name}
        page="proposals"
      />
      <Container>
        <PageHeader variant="h1" data-testid="proposals-page-title">
          {title}
        </PageHeader>
        <Description>{description}</Description>
      </Container>
      <Container>
        <Proposals title={sectionTitle} proposals={proposals} />
      </Container>
    </Template>
  );
};

const PageHeader = styled(Typography)`
  font-size: 1.6rem;
  padding-top: 3.7rem;
`;

const Description = styled(HtmlDescription)`
  ${({ theme }) => css`
    color: ${theme.colorMappings.accessibleSecondaryText};
  `}
  padding-top: 0.8rem;
`;
