import * as React from 'react';
import { useQuery } from '@apollo/client';
import { captureException } from '@sentry/react';
import { useSelector } from 'react-redux';
import { RootState } from 'Client/redux-store';
import { useUser } from 'Client/utils/hooks';
import { LoadRing } from 'Client/components/molecules';
import { ProjectRowProps, ProjectsTableProps } from './types';
import { Wrapper } from './ProjectsTable.styles';
import { ProjectRow } from './components/ProjectRow/ProjectRow';
import { GET_PROJECTS_LIST_QUERY } from './ProjectsTable.gql';
import { ProgrammeWrapper } from './components/ProgrammeRow/ProgrammeWrapper';

export const ProjectsTable: React.FC<ProjectsTableProps> = ({
  setTotalProjectsCount,
  setLoading,
  setIsWaypointInside,
  isWaypointInside,
  setNoMoreProjects,
  filters,
}) => {
  const { user } = useUser();
  const { selectedCustomer } = useSelector(
    (state: RootState) => state.projectCentre
  );

  const [projects, setProjects] = React.useState<Array<ProjectRowProps>>([]);

  const { data, loading, refetch, fetchMore } = useQuery(
    GET_PROJECTS_LIST_QUERY,
    {
      variables: {
        getProjectsFromAdmin: {
          userId: user._id,
          customer: selectedCustomer,
          limit: 100,
          lastIndex: 0,
          filters,
        },
      },
    }
  );

  const handleSetCount = (count: number, projects: Array<ProjectRowProps>) => {
    if (!count && count !== 0) {
      setTotalProjectsCount(projects.length);
      return;
    }
    setTotalProjectsCount(count);
    return;
  };

  const setInitialData = () => {
    const { projects, count } = data.getProjectsFromAdminV2;
    if (projects) {
      setProjects(projects);
      handleSetCount(count, projects);
    }
  };

  React.useEffect(() => {
    if (data) {
      setInitialData();
    }
  }, [data]);

  React.useEffect(() => {
    setLoading(loading);
  }, [loading]);

  React.useEffect(() => {
    setTotalProjectsCount(0);
    setNoMoreProjects(false);
    refetch();
  }, [selectedCustomer]);

  const loadMore = async () => {
    try {
      setLoading(true);
      setIsWaypointInside(false);
      await fetchMore({
        variables: {
          getProjectsFromAdmin: {
            userId: user._id,
            customer: selectedCustomer,
            limit: 100,
            lastIndex: projects.length,
          },
        },
      }).then((r: { data: any }) => {
        if (r.data?.getProjectsFromAdminV2.projects) {
          const moreProjects = r.data?.getProjectsFromAdminV2.projects;
          setProjects((prev) => [...prev, ...moreProjects]);

          if (moreProjects.length === 0) {
            setNoMoreProjects(true);
          }
          handleSetCount(r?.data?.getProjectsFromAdminV2?.count, [
            ...projects,
            ...moreProjects,
          ]);
        }
        setLoading(false);
      });
    } catch (error) {
      captureException(`Error in loadMore @ ProjectsTable : ${error}`);
    }
  };

  React.useEffect(() => {
    if (isWaypointInside) {
      loadMore();
    }
  }, [isWaypointInside]);

  return (
    <Wrapper data-testid="ProjectsTable-Wrapper">
      {!loading &&
        projects?.map((p, index) => {
          if (p.isChildProject) {
            return;
          }
          if (p.isProgramme) {
            return (
              <ProgrammeWrapper
                key={index}
                programme={p}
                childProjects={p.populatedChildProjects}
              />
            );
          }
          return <ProjectRow key={index} {...p} />;
        })}
      {loading && <LoadRing loadingMessage={'Loading'} />}
    </Wrapper>
  );
};
