import styled from 'styled-components';

export const Wrapper = styled.div<{ current: boolean; isOpen: boolean }>`
  width: ${({ current, isOpen }) => (current && isOpen ? '' : '100%')};
  margin: ${({ current, isOpen }) => (current && isOpen ? '0 0.6875rem' : '')};
  height: 3rem;
  display: flex;
  align-items: center;
  background-color: ${({ current, theme }) =>
    current ? theme.colorMappings.controlCenterDisabledButtonBackground : ''};
`;

export const Title = styled.div<{ current: boolean }>`
  margin-left: 0.875rem;
  font-size: 1.125rem;
  font-weight: ${({ current }) => (current ? 700 : 400)};
  line-height: 1.1875rem;
  color: ${({ theme }) => theme.colorMappings.controlCenterNavBarText};
`;

export const CurrentBar = styled.div<{ isOpen: boolean }>`
  background-color: transparent;
  margin-right: ${({ isOpen }) => (isOpen ? '0.625rem' : '1.125rem')};
  width: 0.25rem;
  height: 100%;
`;

export const Modal = styled.div`
  background-color: ${({ theme }) => theme.colors.white[500]};
  width: 14rem;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.grey[700]};
  z-index: 999;
  position: absolute;
  left: 2.5rem;
  box-shadow: 0 0 0.4375rem 0.0625rem rgba(0 0 0 / 15%);
`;
