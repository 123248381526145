import styled, { css } from 'styled-components';
import MuiTimeline from '@material-ui/lab/Timeline';
import MuiTypography from '@material-ui/core/Typography';
import { HtmlDescription } from 'Molecules';
import { Container } from 'Client/components/atoms';

export const PageHeader = styled(MuiTypography)`
  font-size: 1.63rem;
`;

export const MilestonesContainer = styled(Container)`
  max-width: 50%;
  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      max-width: 100%;
      padding: 0;
      margin: 0;
    }

    ${theme.breakpoints.only('lg')} {
      max-width: 65%;
    }
  `}
`;

export const Description = styled(HtmlDescription)`
  ${({ theme }) => css`
    color: ${theme.colorMappings.accessibleSecondaryText};
    padding: 0.875rem 0.125rem;

    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `}
`;

export const DescriptionArea = styled.div`
  margin-top: 5rem;

  .MuiTypography-h1 {
    margin-left: 0;
  }
  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      width: 100%;
      padding: 0 1.75rem 0.875rem;
    }
  `}
`;

export const Timeline = styled(MuiTimeline)`
  padding: 0 0.625rem;

  .MuiTimelineItem-missingOppositeContent::before {
    display: none;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      width: 100%;
      padding: 0;
      margin: 0;
    }
  `}
`;
