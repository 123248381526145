import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  min-width: 78rem;
  background: ${({ theme }) => theme.colors.white[500]};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 2.875rem 0 3.9375rem;
  box-shadow: 0 0.25rem 0.25rem 0 rgb(0 0 0 / 20%);
  position: relative;
  z-index: 9;
`;
export const Text = styled.p<{ bold: boolean }>`
  padding: 0;
  margin: 0;
  margin-bottom: 0.1rem;
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  font-size: 0.875rem;
  line-height: 1.125rem;
`;

export const Filter = styled.div`
  display: flex;
  align-items: center;

  .react-select__control {
    width: 8.2rem;
    min-height: 2rem;
    border: none;
    background-color: transparent;
  }
  .react-select__value-container {
    min-height: 0 !important;
    padding: 0 0 0 0.2rem !important;
    border: none;
  }
  .react-select__single-value,
  .react-select__option {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.1rem;
  }
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__dropdown-indicator {
    color: ${({ theme }) => theme.colors.black[500]};
    transform: scale(0.8);
  }
`;
