import styled from 'styled-components';
import { editModeMobile } from 'Client/components/theme/utils';
import { HtmlDescription } from 'Client/components/molecules';
import { ImageAlignmentTypes, ImageOrientationTypes } from '../../types';

export const Wrapper = styled.div<{
  alignment: ImageAlignmentTypes;
  isMobile: boolean;
  columnHeight: number;
}>`
  width: 100%;
  position: relative;
  display: flex;
  gap: 2rem;
  margin: 0 auto;
  padding: ${({ theme }) => theme.screenPadding};
  max-width: ${({ theme }) => theme.container.width};
  min-height: ${({ columnHeight }) => columnHeight}rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  & > div {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  ${({ theme }) => `
    ${editModeMobile(
      theme.breakpoints.down('xs'),
      `
        width: 100%;
      `
    )}
  `}
  ${({ alignment, isMobile }) => {
    if (isMobile) {
      switch (alignment) {
        case ImageAlignmentTypes.TOP:
          return `
          flex-direction: column-reverse;
          justify-content: flex-end;
        `;
        case ImageAlignmentTypes.BOTTOM:
        default:
          return `
            flex-direction: column;
            justify-content: flex-start;
          `;
      }
    }
    switch (alignment) {
      case ImageAlignmentTypes.TOP:
        return `
          flex-direction: column-reverse;
          justify-content: flex-end;
        `;
      case ImageAlignmentTypes.BOTTOM:
        return `
          flex-direction: column;
          justify-content: flex-start;
        `;
      case ImageAlignmentTypes.RIGHT:
        return `
          flex-direction: row;
          justify-content: flex-end;
          & > div {
            width: calc(50% - 1rem);
          }
        `;
      case ImageAlignmentTypes.LEFT:
        return `
          flex-direction: row-reverse;
          justify-content: flex-start;
          & > div {
            width: 50%;
          }
        `;
      default:
        return `
          flex-direction: column;
          justify-content: flex-start;
        `;
    }
  }}
`;

export const ImageContainer = styled.div<{
  orientation: ImageOrientationTypes;
  columnHeight: number;
}>`
  max-height: ${({ columnHeight }) => columnHeight}rem;
  height: ${({ columnHeight }) => columnHeight}rem;

  ${({ orientation, columnHeight }) => {
    switch (orientation) {
      case ImageOrientationTypes.LANDSCAPE:
        return `
          flex-direction: column;
          & > img {
            width: 100%;
            max-height: ${columnHeight}rem;
          }
          `;
      case ImageOrientationTypes.PORTRAIT:
        return `
          flex-direction: row;
          & > img {
            height: 100%;
            max-height: ${columnHeight}rem;
            max-width: 100%;
          }
          `;
    }
  }}
`;

export const DescriptionContainer = styled(HtmlDescription)`
  position: relative;
  & > div {
    width: 100%;
  }
`;
