import * as React from 'react';
import styled, { css } from 'styled-components';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiPaper from '@material-ui/core/Paper';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiTimelineConnector from '@material-ui/lab/TimelineConnector';
import MuiTimelineContent from '@material-ui/lab/TimelineContent';
import MuiTimelineDot from '@material-ui/lab/TimelineDot';
import { editModeMobile } from 'Client/components/theme/utils';
import { AppTheme } from 'Client/types';
import { MilestoneStageType } from '../types';

export const TimelineContent = styled(MuiTimelineContent)`
  padding: 0;
`;

const dotStylesByStage = (stage: MilestoneStageType, theme: AppTheme) => {
  if (stage === MilestoneStageType.COMPLETED) {
    return css`
      background-color: ${theme.colors.green[700]};
    `;
  }
  if (stage === MilestoneStageType.ACTIVE) {
    return css`
      background-color: ${theme.colors.purple[600]};
      &:before {
        content: '';
        position: absolute;
        width: 3.75rem;
        height: 3.75rem;
        border-radius: 50%;
        background-color: ${theme.colors.purple[600]};
        z-index: -2;
        opacity: 0.1;
        animation: pulse-dot 2.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s
          infinite;
      }
    `;
  }
  if (stage === MilestoneStageType.DRAFT) {
    return css`
      margin: 0.8125rem;
      background-color: ${theme.colors.white[500]};
      .MuiSvgIcon-root {
        fill: ${theme.colors.grey[200]};
      }
    `;
  }
  return css`
    background-color: ${theme.colors.white[500]};
    .MuiSvgIcon-root {
      fill: ${theme.colors.grey[200]};
    }
  `;
};

export const TimelineDot = styled(MuiTimelineDot)<{
  stage: MilestoneStageType;
}>`
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ stage, theme }) => css`
    .MuiSvgIcon-root {
      fill: ${theme.colors.white[500]};
    }
    ${dotStylesByStage(stage, theme)}
  `}

  @keyframes pulse-dot {
    0% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
    }
  }
`;

export const Paper = styled(({ stage, ...props }) => <MuiPaper {...props} />)<{
  stage: string;
}>`
  padding: 1.75rem 2rem;
  margin: 1.25rem -0.5625rem;

  &:last-child .MuiTimelineConnector-root {
    display: none;
  }

  ${({ theme, stage }) => css`
    ${stage === MilestoneStageType.COMPLETED
      ? css`
          background-color: ${theme.colors.green[50]};
          box-shadow: none;
        `
      : stage === MilestoneStageType.ACTIVE
      ? css`
          box-shadow: 0 0 1.25rem 0 ${theme.colorMappings.shadow};
        `
      : css`
          background-color: ${theme.colors.grey[50]};
          box-shadow: none;
        `}

    ${editModeMobile(
      theme.breakpoints.down('sm'),
      css`
        padding: 1rem 1.25rem;
        margin: 0.3125rem 0;
      `
    )}
  `}
`;

export const Accordion = styled(MuiAccordion)`
  background: none;
  border: none;
  box-shadow: none;

  .MuiButtonBase-root {
    .MuiIconButton-label {
      .MuiSvgIcon-root {
        color: ${({ theme }) => theme.colorMappings.icon};
      }
    }
  }

  .MuiCollapse-container {
    cursor: default;
  }

  .MuiTypography-body1 {
    color: ${({ theme }) => theme.colorMappings.accessibleSecondaryText};
  }
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  cursor: default;
  padding: 0;
  ${({ theme }) =>
    theme.direction === 'rtl' ? 'padding-right: 2rem' : 'padding-left: 2rem'};
  min-height: unset;
  height: fit-content;

  .MuiAccordionSummary-content {
    flex-direction: column;
    cursor: default;
    padding: 0;
  }

  ${({ theme }) =>
    editModeMobile(
      theme.breakpoints.down('sm'),
      css`
        cursor: pointer;
        .MuiAccordionSummary-content {
          cursor: pointer;
        }
      `
    )}
`;

export const TimelineConnector = styled(({ expanded, completed, ...props }) => (
  <MuiTimelineConnector {...props} />
))<{
  expanded: boolean;
  completed: boolean;
}>`
  background: none;
  margin-right: 0.0625rem;
  margin-top: 1.75rem;
  position: absolute;
  z-index: 0;
  transition: height 0.2s;
  height: 16rem;
  ${({ theme, completed, expanded }) => css`
    border: 0.0625rem ${completed ? 'solid ' : 'dashed '}${theme.colors.grey[200]};

    ${editModeMobile(
      theme.breakpoints.down('sm'),
      expanded
        ? css`
            height: 16rem;
          `
        : css`
            height: 9rem;
          `
    )}
  `}
`;
