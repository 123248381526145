import * as React from 'react';
import { TopBarButton } from './components';
import {
  Subtitle,
  Title,
  Text,
  TitleContainer,
  Wrapper,
} from './TopBar.styles';
import { TopBarProps } from './types';

export const TopBar: React.FC<TopBarProps> = ({
  title,
  subtitle,
  text,
  buttonsProps,
}: TopBarProps) => {
  return (
    <Wrapper data-testid="TopBar-Wrapper">
      <TitleContainer>
        <Title>{title}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {text && <Text>{text}</Text>}
      </TitleContainer>
      <TopBarButton buttons={buttonsProps} />
    </Wrapper>
  );
};
