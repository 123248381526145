import * as React from 'react';
import { ProjectRow } from '../ProjectRow';
import {
  Wrapper,
  ChildProjects,
  Dash,
  ChildProjectWrapper,
  VerticalDash,
} from './ProgrammeWrapper.styles';
import { ProgrammeWrapperProps } from './types';

export const ProgrammeWrapper: React.FC<ProgrammeWrapperProps> = ({
  programme,
  childProjects,
}: ProgrammeWrapperProps) => {
  const childProjectsWrapperRef = React.useRef(null);
  const [verticalDashHeight, setVerticalDashHeight] = React.useState<number>(0);

  React.useEffect(() => {
    if (childProjectsWrapperRef?.current?.offsetHeight) {
      setVerticalDashHeight(childProjectsWrapperRef?.current?.offsetHeight);
    }
  }, [childProjectsWrapperRef]);

  return (
    <Wrapper color={'#000000'} data-testid="ProgrammeRow-Wrapper">
      <div>
        <ProjectRow componentType={'programme'} {...programme} />

        <ChildProjects ref={childProjectsWrapperRef}>
          <VerticalDash height={verticalDashHeight} />
          <div>
            {childProjects?.map((p, index) => {
              return (
                <ChildProjectWrapper key={index}>
                  <Dash />
                  <ProjectRow componentType={'child'} {...p} />
                </ChildProjectWrapper>
              );
            })}
          </div>
        </ChildProjects>
      </div>
    </Wrapper>
  );
};
