import * as React from 'react';
import { Masthead } from 'Organisms';
import { useProject } from 'Client/utils/hooks';
import { NavBar } from 'Client/pages/dashboard/components/NavBar';
import { Content, PageContainer, Wrapper } from './DashboardPage.styles';
import { DashboardPagesProps } from './types';

export const DashboardPagesTemplate: React.FC<DashboardPagesProps> = ({
  currentTab,
  children,
}: DashboardPagesProps) => {
  const project = useProject();
  const [navbarOpened, setNavbarOpened] = React.useState<boolean>(false);

  return (
    <PageContainer data-testid="DashboardPages-PageContainer">
      <NavBar
        navbarOpened={navbarOpened}
        setNavbarOpened={setNavbarOpened}
        currentTab={currentTab}
      />
      <Wrapper>
        <Masthead project={project} isDashboard />
        <Content
          id="dashboard-page-content-container"
          navbarExpanded={navbarOpened}
          isBannerShown={false}
        >
          {children}
        </Content>
      </Wrapper>
    </PageContainer>
  );
};

export default DashboardPagesTemplate;
