import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { Container, Link } from 'Atoms';
import { TextField } from 'Molecules';
import { PRIVACY_POLICY_URL } from 'Client/constants/urls';
import { cpBrandName } from 'Client/constants/brand';
import {
  Wrapper,
  Content,
  PrivacyPolicy,
  IconList,
  IconListIcon,
  IconListText,
} from './EmailPanel.styles';
import { EmailPanelProps } from './types';
import { DrawerText } from './DrawerText';
import { Lock } from './icons/Lock';
import { Checkbox } from './icons/Checkbox';
import { Envelope } from './icons/Envelope';

export const EmailPanel: React.FC<EmailPanelProps> = ({
  emailProvided,
  emailInputValue,
  onEmailInputChange,
  emailValidationStatus,
  openEmailPanel,
  toggleOpenEmailPanel,
}: EmailPanelProps) => {
  const { t } = useTranslation();

  const FocusedEmailField: React.FC = () => {
    const [input, setInput] = React.useState(emailInputValue);
    const [debouncedText] = useDebounce<string>(input, 1000);

    React.useEffect(() => {
      onEmailInputChange(debouncedText);
    }, [debouncedText]);

    return (
      <TextField
        id="email"
        placeholder={t('e.g. sam@smith.com')}
        label={t('E-mail')}
        width="100%"
        type="email"
        status={emailValidationStatus}
        value={!input ? emailInputValue : input}
        handleChange={(e) => setInput(String(e.target.value))}
        autoFocus={openEmailPanel}
      />
    );
  };

  return (
    <Wrapper isPanelOpen={openEmailPanel} data-testid="EmailPanel">
      <Container>
        <DrawerText
          toggleOpenEmailPanel={toggleOpenEmailPanel}
          isPanelOpen={openEmailPanel}
          emailProvided={emailProvided}
        />
        <Content hidden={!openEmailPanel}>
          <h3>
            <img
              height="100"
              src="https://res.cloudinary.com/commonplace-digital-limited/image/upload/h_100/v1652371515/email-drawer-illustration_xqfuhj.png"
            />
            <span>{t(`Make sure your voice is heard`)}</span>
            {emailProvided ? (
              <b data-testid="email-provided-title">
                {t(`Confirm your email address`)}
              </b>
            ) : (
              <b>{t(`Add your email address`)}</b>
            )}
          </h3>
          <FocusedEmailField />
          <IconList>
            <IconListIcon icon={<Lock />} />
            <IconListText>
              <b>
                {t(`9% of comments on {{cpBrandName}} are never published.`, {
                  cpBrandName,
                })}
              </b>
              <br />
              <b>
                {t(
                  `We verify that all responses are genuine before publishing.`
                )}
              </b>
            </IconListText>
            <IconListIcon icon={<Checkbox />} />
            <IconListText>
              {' '}
              {t(`We’ll send you an email to confirm your response.`)}
            </IconListText>
            <IconListIcon icon={<Envelope />} />
            <IconListText>
              {t(`Your email address will never be shared publicly.`)}
            </IconListText>
          </IconList>
          <Trans>
            <PrivacyPolicy data-testid="privacy-policy-text">
              {'Read our '}
              <Link
                to={PRIVACY_POLICY_URL}
                noPadding
                color="CommonplaceBrand"
                newTab
                external
              >
                {'privacy policy'}
              </Link>
              {'.'}
            </PrivacyPolicy>
          </Trans>
        </Content>
      </Container>
    </Wrapper>
  );
};
