import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { BreakPageLine } from './BreakPage.styles';

interface BreakPageProps {
  onClick?: () => void;
}

export const BreakPage: React.FC<BreakPageProps> = ({
  ...props
}: BreakPageProps) => {
  const { t } = useTranslation('customer');
  return (
    <BreakPageLine data-testid="BreakPageId" {...props}>
      {t('Page break')}
    </BreakPageLine>
  );
};
