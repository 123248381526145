import * as React from 'react';
import { ArrowBackIos } from '@material-ui/icons';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { LoginFooter } from './components/LoginFooter';
import {
  Content,
  PageContainer,
  Wrapper,
  ImageWrapper,
  LogoWrapper,
  CommonplaceLogo,
  BackButton,
} from './OnboardingLogin.styles';

export const OnboardingLoginTemplate: React.FC<any> = ({
  children,
  image,
  backButton,
}: any) => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <PageContainer>
      <Wrapper>
        <Content isBannerShown={false}>
          <LogoWrapper>
            <CommonplaceLogo />
          </LogoWrapper>
          {backButton && (
            <BackButton onClick={() => router.back()}>
              <ArrowBackIos /> {t('Back')}
            </BackButton>
          )}
          {children}
        </Content>
        {image.src && (
          <ImageWrapper>
            <img src={image.src} width={image.width} />
          </ImageWrapper>
        )}
      </Wrapper>
      <LoginFooter />
    </PageContainer>
  );
};

export default OnboardingLoginTemplate;
