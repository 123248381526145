import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from 'Molecules';

export const Footer = ({
  onCancelClick,
  onSaveClick,
  isEmbed,
  disableSave,
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      {!isEmbed && (
        <Button onClick={onCancelClick} borderRadius={'0.1875rem'} inverse>
          {t('Cancel')}
        </Button>
      )}
      <Button
        onClick={onSaveClick}
        borderRadius={'0.1875rem'}
        style={{ marginLeft: 'auto' }}
        disabled={disableSave}
      >
        {t('Save')}
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.proposals.footer.height};
  background-color: ${({ theme }) => theme.proposals.footer.backgroundColor};
  display: flex;
  align-content: center;
  align-items: center;
  padding: 1rem 2rem;
  position: fixed;
  bottom: 0;
`;
