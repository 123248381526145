import * as React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@material-ui/core';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiTimelineItem from '@material-ui/lab/TimelineItem';
import MuiTimelineSeparator from '@material-ui/lab/TimelineSeparator';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PlayIcon from '@material-ui/icons/PlayArrow';
import CheckIcon from '@material-ui/icons/Check';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { StartEnd, Typography } from 'Atoms';
import { HtmlDescription } from 'Molecules';
import { MilestoneItemProps, MilestoneStageType } from '../types';
import { getDefaultEndDate } from '../utils';
import {
  Paper,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
  Accordion,
  AccordionSummary,
} from './MilestoneItem.styles';

const MilestoneItem: React.FC<MilestoneItemProps> = ({
  name,
  description,
  stage,
  startDate,
  endDate,
}: MilestoneItemProps) => {
  const router = useRouter();
  const [expanded, setExpanded] = React.useState<string>();
  const isEditMode = router.asPath.includes('/edit');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')) || isEditMode;
  const { t } = useTranslation();

  React.useEffect(() => {
    if (stage === MilestoneStageType.ACTIVE) {
      setExpanded(name);
    }
  }, [stage]);

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Paper stage={stage}>
      <MuiTimelineItem>
        <MuiTimelineSeparator>
          <TimelineDot stage={stage}>
            {stage === MilestoneStageType.ACTIVE && (
              <PlayIcon data-testid="active-icon" />
            )}
            {stage === MilestoneStageType.COMPLETED && (
              <CheckIcon data-testid="completed-icon" />
            )}
            {stage === MilestoneStageType.PLANNED && (
              <MoreHorizIcon data-testid="planned-icon" />
            )}
          </TimelineDot>
          <TimelineConnector
            expanded={expanded === name}
            completed={stage === MilestoneStageType.COMPLETED}
          />
        </MuiTimelineSeparator>
        <TimelineContent>
          <Accordion
            expanded={expanded === name || !isMobile}
            onChange={handleChange(name)}
          >
            <AccordionSummary expandIcon={isMobile ? <ExpandMoreIcon /> : null}>
              <Typography variant="h2">{name}</Typography>
              {startDate && (
                <StartEnd
                  startsOn={startDate}
                  endsOn={endDate || getDefaultEndDate(t)(stage)}
                />
              )}
            </AccordionSummary>
            <MuiAccordionDetails>
              <HtmlDescription>{description}</HtmlDescription>
            </MuiAccordionDetails>
          </Accordion>
        </TimelineContent>
      </MuiTimelineItem>
    </Paper>
  );
};

export { MilestoneItem };
