import { gql } from '@apollo/client';

export const GET_PROJECTS_LIST_QUERY = gql`
  query GetProjectsFromAdminV2($getProjectsFromAdmin: GetProjectsFromAdmin) {
    getProjectsFromAdminV2(getProjectsFromAdmin: $getProjectsFromAdmin) {
      projects {
        _id
        id
        name
        stage
        launchDate
        endDate
        brandingOptions {
          navColour
          customerLogoUrl
        }
        openGraph {
          image
        }
        visitorCount
        commentCount
        agreeCount
        isProgramme
        isChildProject
        sumOfCounts {
          visitorCount
          commentCount
          agreeCount
        }
        populatedChildProjects {
          _id
          id
          name
          stage
          launchDate
          endDate
          brandingOptions {
            navColour
            customerLogoUrl
          }
          openGraph {
            image
          }
          visitorCount
          commentCount
          agreeCount
          isProgramme
          isChildProject
        }
      }
      count
    }
  }
`;
