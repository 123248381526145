import styled, { css } from 'styled-components';
import * as React from 'react';
import { Autocomplete } from 'Client/components/molecules';

export const Container = styled.div<{ isMenuOpen?: boolean }>`
  ${({ theme, isMenuOpen }) => css`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: ${isMenuOpen ? '100%' : 'unset'};

    > label {
      color: ${theme.colors.black[500]};
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.125rem; /* 112.5% */
    }
  `};
`;

export const RelativeContainer = styled.div`
  position: relative;

  > svg {
    position: absolute;
    top: 50%;
    left: 0.75rem;
    z-index: 1;
    transform: translateY(-50%);
    pointer-events: none;
  }
`;

export const StyledAutocomplete = styled((props) => (
  <Autocomplete {...props} />
))`
  ${({ theme, search, width }) => css`
    width: ${width ? `${width}rem` : '100%'};

    .react-select__control {
      cursor: pointer;
      border-radius: 2.875rem;
      border: 0.0625rem solid ${theme.colors.white[300]};
      min-height: 2.5rem;
      width: unset;
      ${search &&
      css`
        padding-left: 1.25rem;
      `}

      &:hover {
        border-color: ${theme.colorMappings.formFieldBorder};
      }

      ${theme.breakpoints.down('xs')} {
        width: ${width ? `${width}rem` : '100%'};
      }

      &.react-select__control--is-focused {
        border: 0.0625rem solid
          ${({ theme }) => theme.colorMappings.formFieldBorder};
        outline: none;
        box-shadow: none;
      }

      &.react-select__control--menu-is-open {
        &.react-select__control--is-focused:after {
          display: none;
        }
      }

      .react-select__placeholder {
        font-size: ${theme.fontSizes.m};
      }

      .react-select__value-container {
        min-height: 2.5rem;
        padding: 0.125rem 1.25rem;
        padding-left: 2.75rem;

        .react-select__single-value {
          display: flex;
        }
      }

      .react-select__indicator-separator {
        display: none;
      }
      .react-select__clear-indicator,
      .react-select__dropdown-indicator {
        z-index: 10;
        &:hover {
          cursor: pointer;
        }
      }
    }

    .react-select__menu {
      border-radius: 0.375rem;
      margin-top: 0;
      z-index: 1500;
      ${theme.breakpoints.down('md')} {
        width: 100%;
      }

      .react-select__option {
        display: flex;
        white-space: break-spaces;
        word-break: break-word;
        padding: 0.5rem 1.25rem;
        min-height: 2.5rem;
        line-height: 2;

        &:hover {
          cursor: pointer;
        }

        &.react-select__option--is-focused {
          background-color: ${theme.colorMappings.hoverHighlight};
        }

        &.react-select__option--is-selected {
          background-color: ${theme.colorMappings.selectedHighlight};
        }
      }
    }

    .react-select__control--is-disabled {
      pointer-events: all;
      cursor: not-allowed;

      .react-select__dropdown-indicator {
        &:hover {
          cursor: not-allowed;
        }
      }
    }
  `}
`;

export const LabelContainer = styled.div`
  display: flex;
`;

export const Label = styled.label`
  margin-right: 0.625rem;
`;
