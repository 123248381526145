import { captureException } from '@sentry/node';
import fetch from 'isomorphic-unfetch';
import { ProjectProps } from 'Shared/types';
import { AllNewsItemsEmail } from 'Shared/types/news';

type Params = {
  newsItems: AllNewsItemsEmail; // we actually need all newsItems so that we send them based on the lang of the email!
  project: ProjectProps;
  lang?: string; // fallback in case user lang not found
};

export const sendProjectNewsEmail = async ({
  newsItems,
  project,
  lang = 'en-GB',
}: Params): Promise<{
  success: boolean;
  message?: string;
}> => {
  try {
    const res = await fetch(`/api/email/projectNews`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        project,
        newsItems,
        lang,
      }),
    });
    const jsonRes = await res.json();
    return jsonRes;
  } catch (error) {
    captureException(
      `Error in sendProjectNewsEmail @ sendProjectNewsEmail.ts: ${error}`
    );
    return {
      success: false,
      message: `Unable to send PROJECT_NEWS email: ${error}`,
    };
  }
};
