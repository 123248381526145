import * as React from 'react';
import { useRouter } from 'next/router';
import { fetchContributionsCount } from 'Client/services/contributions';
import { ContributionType } from 'Shared/types/contribution';
import { Cta, CounterComments, CounterReactions, Heading } from '.';
import { BannerProps as Props } from '../types';
import { Content } from './Banner.styles';

export const Banner: React.FC<Props> = ({
  project,
  proposal = undefined,
  showHeading = true,
  hideCta = false,
  children,
}: Props) => {
  const router = useRouter();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [commentCount, setCommentCount] = React.useState<number>(0);
  const [agreementCount, setAgreementCount] = React.useState<number>(0);

  React.useEffect(() => {
    // Get & set counts for comments/agreements
    fetchData();
  }, []);

  const agreeEnabled = project.features.agreeComments;
  // The banner will be a Call To Action when no contribution yet exists.
  const isCallToAction = !commentCount && !isLoading && !hideCta;
  // Provides the title for headings/text.
  const title = proposal?.title || project.name;
  // Navigate to `/proposals` if route is `/contributions`
  // Navigate to `/proposals/<slug>` if route is `/contributions/proposal/<slug>`
  const handleCtaClick = () => {
    proposal
      ? router.push(`/proposals/${proposal.slug}`)
      : router.push('/proposals');
  };

  const fetchData = async () => {
    const config: [
      ContributionType,
      React.Dispatch<React.SetStateAction<number>>
    ][] = [
      [ContributionType.COMMENT, setCommentCount],
      [ContributionType.AGREEMENT, setAgreementCount],
    ];
    await Promise.all(
      config.map(([type, cb]) =>
        fetchContributionsCount({
          projectId: project._id,
          surveySlug: proposal?.slug || undefined,
          type,
        })
          .then(cb)
          .catch(() => cb(0))
      )
    );
    setIsLoading(false);
  };

  return (
    <>
      {showHeading && (
        <Heading cta={isCallToAction} agree={agreeEnabled} title={title} />
      )}
      <Content
        cta={isCallToAction}
        data-testid="ContributionsBanner-counter-container"
      >
        <CounterComments
          cta={isCallToAction}
          count={commentCount}
          loading={isLoading}
        />
        {agreeEnabled && (
          <CounterReactions
            cta={isCallToAction}
            count={agreementCount}
            loading={isLoading}
          />
        )}
      </Content>
      {isCallToAction && <Cta title={title} onClick={handleCtaClick} />}
      {commentCount > 0 && children}
    </>
  );
};
