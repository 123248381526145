import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ImageContainer,
  InlineImageWrapper,
} from './DefaultImageVisualizer.styles';
import { VisualizerComponentProps } from './types';
import { getImageUrlWidthBased } from './utils';

const DefaultImageVisualizer: React.FC<VisualizerComponentProps> = ({
  files,
  windowWidth,
  windowHeight,
}) => {
  const { t } = useTranslation();

  const imageContainerRef = React.useRef<HTMLDivElement>(null);
  const WrapperRef = React.useRef<HTMLDivElement>(null);

  const [pageFullyLoaded, setPageFullyLoaded] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState('');

  const containerWidth = imageContainerRef?.current?.clientWidth;
  const wrapperWidth = WrapperRef?.current?.clientWidth;

  React.useEffect(() => {
    return setPageFullyLoaded(windowHeight > 0 && windowWidth > 0);
  }, [windowHeight, windowWidth, WrapperRef]);

  /* Needed to get size after changes (container resize) */
  setTimeout(() => {
    if (file?.url) {
      setImageUrl(
        getImageUrlWidthBased({
          width: Math.ceil(containerWidth || wrapperWidth),
          height: null,
          url: file?.url || '',
        })
      );
    }
  }, 1000);

  if (!files.length) return <p>{t('Default')}</p>;
  const file = files[0];
  return (
    <InlineImageWrapper key={file?.url} ref={WrapperRef}>
      {pageFullyLoaded && (
        <ImageContainer ref={imageContainerRef}>
          <img alt={file?.alt} src={imageUrl} />
        </ImageContainer>
      )}
      {file?.description && <p>{file?.description}</p>}
    </InlineImageWrapper>
  );
};

export default DefaultImageVisualizer;
