import * as React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { ConditionalWrapper } from 'Client/utils/wrappers';
import { useBanner } from 'Client/utils/hooks/useBanner';
import { Pattern } from './Pattern';
import { HeroProps, ReadableHeroTypes } from './types';
import { Subscription } from './components';
import {
  Wrapper,
  Overlay,
  Content,
  Link,
  TextIcon,
  Title,
  Description,
  HeroContainer,
} from './Hero.styles';
import { setStylesToHighlightedText } from './utils';

export const Hero: React.FC<HeroProps> = ({
  title,
  description,
  backlinkProps,
  learnMoreProps,
  hasNews,
  backgroundImage,
  layout = {
    type: ReadableHeroTypes.DEFAULT,
    overlayImage: false,
    transparentBackground: false,
  },
  overlayImage,
  hideSubscription,
  userHasSubscribed,
}: HeroProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const sideImage = [
    ReadableHeroTypes.SQUARE_IMAGE_AND_SOLID_COLOUR,
    ReadableHeroTypes.SHAPED_IMAGE,
  ].includes(layout.type);

  React.useEffect(() => {
    if (!layout || !layout.type) return;
    setStylesToHighlightedText(layout);
  }, [layout.type, layout.transparentBackground, layout]);

  const { errorBannerHeight, stageBannerHeight } = useBanner();

  return (
    <Wrapper
      data-testid="Hero"
      data-onboarding="hero-section"
      backgroundImage={backgroundImage}
      overlayImage={layout.overlayImage}
      fixedMasthead={isMobile}
      errorBannerHeight={errorBannerHeight}
      stageBannerHeight={stageBannerHeight}
    >
      <ConditionalWrapper
        condition={overlayImage?.src}
        wrapper={(children) => (
          <Overlay backgroundImage={overlayImage}>{children}</Overlay>
        )}
      >
        <HeroContainer hasSideImage={sideImage} layout={layout}>
          <Content data-onboarding="text-container" layout={layout}>
            {backlinkProps && (
              <Link
                bgColorMapping={'brand'}
                to={backlinkProps.href}
                tracking={backlinkProps.tracking}
              >
                <TextIcon variant={'h4'}>
                  <ArrowBackIcon color="action" fontSize="small" />
                  {backlinkProps.text}
                </TextIcon>
              </Link>
            )}
            <Title variant="h1" className="hub-hero-title">
              {title}
            </Title>
            {description && (
              <Description className="hub-hero-description">
                {description}
              </Description>
            )}
            {learnMoreProps.text && (
              <Link
                bgColorMapping={'brand'}
                to={learnMoreProps.href}
                tracking={learnMoreProps.tracking}
              >
                {learnMoreProps.text}
              </Link>
            )}
            {!hideSubscription && (
              <Subscription
                userHasSubscribed={userHasSubscribed}
                hasNews={hasNews}
              />
            )}
          </Content>
        </HeroContainer>
      </ConditionalWrapper>
      {!backgroundImage && <Pattern />}
    </Wrapper>
  );
};
