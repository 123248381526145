import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useEditModeLayout } from 'Client/context/editModeLayoutContext';
import { useEditModeContext } from 'Client/utils/hooks';
import {
  GridImage,
  GridVisualizerWrapper,
  ImagesContainer,
} from './GridVisualizer.styles';
import { VisualizerComponentProps } from './types';
import { getImageUrlWidthBased } from './utils';

const GridVisualizer: React.FC<VisualizerComponentProps> = ({
  files,
  windowWidth,
  windowHeight,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isEditModeLayout] = useEditModeLayout();
  const [editModeState] = useEditModeContext();
  const editModeMobileLayout =
    isEditModeLayout && editModeState.editModeLayout.previewMode === 'mobile';

  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const imageContainerRef = React.useRef<HTMLDivElement>(null);
  const WrapperRef = React.useRef<HTMLDivElement>(null);

  const [pageFullyLoaded, setPageFullyLoaded] = React.useState(false);
  const [imageWidth, setImageWidth] = React.useState(null);

  const containerWidth = imageContainerRef?.current?.clientWidth;
  const wrapperWidth = WrapperRef?.current?.clientWidth;

  React.useEffect(() => {
    return setPageFullyLoaded(windowHeight > 0 && windowWidth > 0);
  }, [windowHeight, windowWidth, WrapperRef]);

  React.useEffect(() => {
    setImageWidth(
      (prevValue: number) =>
        Math.ceil(containerWidth || wrapperWidth) || prevValue
    );
  }, [containerWidth, wrapperWidth, pageFullyLoaded]);

  if (!files.length) return <p>{t('Default')}</p>;

  return (
    <GridVisualizerWrapper ref={WrapperRef}>
      {pageFullyLoaded && (
        <ImagesContainer ref={imageContainerRef}>
          {files.map((file, index) => {
            const fullWidth =
              isMobile ||
              editModeMobileLayout ||
              (index === files.length - 1 && files.length % 2 === 1);
            return (
              <GridImage
                fullWidth={fullWidth}
                key={file.url}
                alt={file.alt}
                src={getImageUrlWidthBased({
                  width: fullWidth ? imageWidth : Math.ceil(imageWidth / 2),
                  height: fullWidth ? null : Math.ceil(imageWidth / 2),
                  url: file.url,
                })}
              />
            );
          })}
        </ImagesContainer>
      )}
    </GridVisualizerWrapper>
  );
};

export default GridVisualizer;
