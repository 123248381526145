import styled, { css } from 'styled-components';

export const Container = styled.button<{ active: boolean }>`
  ${({ theme, active }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    border-radius: 2.875rem;
    border: 0.063rem solid
      ${active ? theme.colors.green[400] : theme.colors.white[300]};
    background-color: ${active
      ? theme.colorMappings.lightGreenFocus
      : theme.colors.white[500]};
    padding: 0.5rem 1.5rem;
    cursor: pointer;

    color: ${theme.colors.black[50]};
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.125rem; /* 112.5% */
  `}
`;

export const Indicator = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.white[500]};
    border-radius: 1.5rem;
    background-color: ${theme.colors.green[400]};
    text-align: center;
    font-size: 0.875rem;
    font-weight: 700;
    padding: 0.188rem 0.5rem;
  `}
`;
