import { SHARING_ACTION_TYPES } from 'Client/context/sharingReducer';
import { SharingContextTypes } from 'Client/components/organisms/FloatingShareButton/types';
import { ProjectRowProps } from '../types';

interface HandleShareContextParams {
  dispatch: (arg0: { [key: string]: any }) => void;
  project: ProjectRowProps;
}

export const handleShareContext = ({
  dispatch,
  project,
}: HandleShareContextParams) => {
  // open the modal/drawer
  dispatch({ type: SHARING_ACTION_TYPES.SET_OPEN, open: true });

  // Setting new project id for the url
  dispatch({
    type: SHARING_ACTION_TYPES.SET_OTHER_PROJECT,
    otherProjectParams: {
      useOtherProjectParams: true,
      project,
    },
  });

  const context = SharingContextTypes.PAGE;
  dispatch({
    type: SHARING_ACTION_TYPES.SET_CONTEXT,
    context,
  });
};
