import React from 'react';

const Undefined = ({ width = 52, height = 78, color = '#B0B0B0' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14.8797" cy="10.7137" r="10.7137" fill={color} />
      <g clipPath="url(#clip0_17_1052)">
        <path
          d="M8 23C6.14348 23 4.36301 22.2625 3.05025 20.9497C1.7375 19.637 1 17.8565 1 16C1 14.1435 1.7375 12.363 3.05025 11.0503C4.36301 9.7375 6.14348 9 8 9C9.85652 9 11.637 9.7375 12.9497 11.0503C14.2625 12.363 15 14.1435 15 16C15 17.8565 14.2625 19.637 12.9497 20.9497C11.637 22.2625 9.85652 23 8 23ZM8 24C10.1217 24 12.1566 23.1571 13.6569 21.6569C15.1571 20.1566 16 18.1217 16 16C16 13.8783 15.1571 11.8434 13.6569 10.3431C12.1566 8.84285 10.1217 8 8 8C5.87827 8 3.84344 8.84285 2.34315 10.3431C0.842855 11.8434 0 13.8783 0 16C0 18.1217 0.842855 20.1566 2.34315 21.6569C3.84344 23.1571 5.87827 24 8 24V24Z"
          fill="black"
        />
        <path
          d="M5.25497 13.786C5.2536 13.8183 5.25886 13.8505 5.2704 13.8807C5.28195 13.9109 5.29956 13.9384 5.32213 13.9615C5.3447 13.9847 5.37177 14.003 5.40167 14.0152C5.43156 14.0275 5.46366 14.0336 5.49597 14.033H6.32097C6.45897 14.033 6.56897 13.92 6.58697 13.783C6.67697 13.127 7.12697 12.649 7.92897 12.649C8.61497 12.649 9.24297 12.992 9.24297 13.817C9.24297 14.452 8.86897 14.744 8.27797 15.188C7.60497 15.677 7.07197 16.248 7.10997 17.175L7.11297 17.392C7.11402 17.4576 7.14083 17.5202 7.1876 17.5662C7.23437 17.6122 7.29736 17.638 7.36297 17.638H8.17397C8.24028 17.638 8.30387 17.6117 8.35075 17.5648C8.39763 17.5179 8.42397 17.4543 8.42397 17.388V17.283C8.42397 16.565 8.69697 16.356 9.43397 15.797C10.043 15.334 10.678 14.82 10.678 13.741C10.678 12.23 9.40197 11.5 8.00497 11.5C6.73797 11.5 5.34997 12.09 5.25497 13.786V13.786ZM6.81197 19.549C6.81197 20.082 7.23697 20.476 7.82197 20.476C8.43097 20.476 8.84997 20.082 8.84997 19.549C8.84997 18.997 8.42997 18.609 7.82097 18.609C7.23697 18.609 6.81197 18.997 6.81197 19.549Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_17_1052">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Undefined;
