import * as React from 'react';
import { Button } from 'Client/components/molecules';
import { FileRowInfoMobileProps } from '../types';
import {
  Wrapper,
  Info,
  InfoContainer,
  MobileViewLink,
  Title,
} from './FileRowInfoMobile.styles';

export const FileRowInfoMobile: React.FC<FileRowInfoMobileProps> = ({
  info,
  url,
  isClosing,
}) => {
  return (
    <Wrapper isClosing={isClosing}>
      <InfoContainer>
        {info.map(({ value, header }, i) => (
          <Info key={i}>
            <Title>{header}:</Title>
            <span>{value}</span>
          </Info>
        ))}
      </InfoContainer>
      <MobileViewLink href={url} target="_blank" rel="noreferrer">
        <Button>View</Button>
      </MobileViewLink>
    </Wrapper>
  );
};
