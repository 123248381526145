import * as React from 'react';
import { useRouter } from 'next/router';
import { Typography } from 'Atoms';
import { CloseIcon } from 'Atoms/Icons';
import { IconButtonSimple } from 'Molecules/IconButton';
import { PROPOSAL_ACTION_TYPES } from 'Client/context/proposalReducer';
import { useProposalContext } from 'Client/utils/hooks';
import { Footer } from './components';
import { Wrapper, Header, Content } from './QuestionPages.styles';

interface QuestionPagesTemplateProps {
  headerLabel?: string;
  questionId: string;
  onSaveClick: () => void;
  children: React.ReactNode;
  disableSave?: boolean;
}

export const QuestionPagesTemplate: React.FC<QuestionPagesTemplateProps> = ({
  headerLabel,
  questionId,
  onSaveClick,
  children,
  disableSave,
}: QuestionPagesTemplateProps) => {
  const router = useRouter();
  const [{ answers }, dispatch] = useProposalContext();
  const isEmbed = router.query?.embed === 'true';

  const cancelAndGoBack = () => {
    // clear saved answer (pinned location) and go back to the correct proposal step
    // Note: in the future if he handle more full-page questions we might not want
    // to clear the answers on 'cancel'. In that case you can pass questionId as null to the template.
    // For now the check below will always be true.
    if (questionId) {
      dispatch({
        type: PROPOSAL_ACTION_TYPES.SET_ANSWERS,
        answers: { ...answers, [questionId]: '' },
      });
    }
    router.back();
  };

  return (
    <Wrapper>
      <Header>
        <Typography fontSize="1.375rem" bold variantMapping={{ body1: 'span' }}>
          {headerLabel}
        </Typography>
        <IconButtonSimple
          iconComponent={<CloseIcon />}
          color="black"
          onClick={cancelAndGoBack}
        />
      </Header>
      <Content>{children}</Content>
      <Footer
        onCancelClick={cancelAndGoBack}
        onSaveClick={onSaveClick}
        isEmbed={isEmbed}
        disableSave={disableSave}
      />
    </Wrapper>
  );
};

export default QuestionPagesTemplate;
