import styled, { css } from 'styled-components';
import MuiCard from '@material-ui/core/Card';
import MuiTypography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { editModeMobile } from 'Client/components/theme/utils';

export const StakeholderWrapper = styled(Grid)`
  margin-top: 2.5rem;

  ${({ theme }) => css`
    ${editModeMobile(
      theme.breakpoints.down('xs'),
      css`
        > div {
          max-width: 50%;
          flex-basis: 50%;
        }
      `
    )};
  `}
`;

export const Card = styled(MuiCard)`
  padding: 0 10%;
  height: 8.125rem;
  align-items: center;
  display: flex;
`;

export const Title = styled(MuiTypography)`
  margin-top: 0.9375rem;
  text-align: center;
`;

export const Image = styled.img`
  width: 100%;
`;
