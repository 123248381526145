import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'Atoms';

interface ConfirmationPageHeaderProps {
  editComment: boolean;
}

const ConfirmationPageHeader: React.FC<ConfirmationPageHeaderProps> = ({
  editComment,
}: ConfirmationPageHeaderProps) => {
  const { t } = useTranslation();
  return (
    <Typography
      align={editComment ? 'center' : 'inherit'}
      variant="h2"
      data-testid="ConfirmationPageHeader"
    >
      {editComment ? t('Edit your comment') : t('Review your comment')}
    </Typography>
  );
};

export { ConfirmationPageHeader };
