import * as React from 'react';
import styled, { css } from 'styled-components';
import { WrapperProps } from '.';

export const Wrapper = styled('div')<WrapperProps>`
  height: 100%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.grey[50]};
`;

export const Content = styled(({ isBannerShown, ...props }) => (
  <div {...props} />
))`
  margin: 0 auto;

  ${({ theme, isBannerShown, errorBannerHeight, stageBannerHeight }) => css`
    height: calc(
      100% -
        ${isBannerShown
          ? '7rem'
          : `${
              Number(theme.proposals.masthead.height.replace('rem', '')) +
              errorBannerHeight +
              stageBannerHeight
            }rem`}
    );
    position: absolute;
    width: 100%;
    overflow: hidden;

    ${theme.breakpoints.down('sm')} {
      margin-top: initial;
    }
  `}
`;
