import styled, { css } from 'styled-components';
import { darken } from 'polished/lib';

export const Wrapper = styled.div<{ disabled: boolean }>`
  ${({ theme }) => css`
    display: flex;
    width: 9.25rem;
    position: relative;
    color: ${theme.colors.white[500]};
    background-color: ${theme.colorMappings.CommonplaceBrand};
    border: solid 0.125rem ${theme.colorMappings.CommonplaceBrand};
    border-radius: 0.25rem;

    &:hover {
      color: ${theme.colors.white[500]};
      background-color: ${darken(0.1, theme.colorMappings.CommonplaceBrand)};
      border: solid 0.125rem
        ${darken(0.1, theme.colorMappings.CommonplaceBrand)};
    }
  `}

  height: 2.8125rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${({ disabled, theme }) =>
    disabled &&
    `
    background-color: ${theme.colors.grey[500]};
    border-color:  ${theme.colors.grey[500]};
    cursor: not-allowed;
    p {
      color: ${({ theme }) => theme.colors.grey[300]};
    }

    &:hover {
      background-color: ${theme.colors.grey[500]};
      border-color:  ${theme.colors.grey[500]};
    }
    `}
`;

export const Text = styled.p`
  margin: 0;
  padding: 0;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.4375rem;
  text-align: center;
`;

export const Modal = styled.div`
  font-size: 0.8rem;
  background-color: ${({ theme }) => theme.colors.white[500]};
  color: ${({ theme }) => theme.colors.black[500]};
  padding: 1rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.grey[700]};
  z-index: 999;
  position: absolute;
  top: 25%;
  right: 50%;
  width: 135%;
  box-shadow: 0 0 0.4375rem 0.0625rem rgba(0 0 0 / 15%);
`;
