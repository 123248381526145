import * as React from 'react';
import { useDispatch } from 'react-redux';
import { makeRedirectDomain } from 'Client/utils/url';
import { useSharingContext } from 'Client/context/sharingHooks';
import { theme } from 'Client/components/theme';
import { useUser } from 'Client/utils/hooks/useUser';
import {
  checkLengthAndSetModal,
  HTMLElementEvent,
  MouseEventCustom,
} from 'Client/utils/dashboard/checkLengthAndSetModal';
import { SimpleHoverModal } from 'Atoms/SimpleHoverModal/SimpleHoverModal.styles';
import { setDeleteProjectModal } from 'Client/utils/reduxReducers/projectCentre/projectCenterReducer';
import { ProjectRowProps } from '../../types';
import {
  Cell,
  ColorBar,
  ImageWrapper,
  Name,
  NameWrapper,
  StatusCell,
  StatusDot,
  Wrapper,
  ArrowIcon,
  ArrowIconWrapper,
  ActionDot,
  ActionModal,
  ActionButton,
} from './ProjectRow.styles';
import { imageCheck } from '../../utils/imageCheck';
import { formatProjectRowDate } from '../../utils/formatProjectRowDate';
import { actions } from '../../constants/actions';
import { handleShareContext } from '../../utils/handleShareContext';

export const ProjectRow: React.FC<ProjectRowProps> = (
  props: ProjectRowProps
) => {
  const {
    id,
    name,
    stage,
    _id,
    launchDate,
    endDate,
    brandingOptions,
    openGraph,
    visitorCount,
    isProgramme,
    commentCount,
    agreeCount,
    componentType,
    sumOfCounts,
  } = props;
  const color = brandingOptions?.navColour || theme.colorMappings.brand;
  const image = imageCheck(openGraph, brandingOptions);
  const { user } = useUser();
  const [, dispatch] = useSharingContext();
  const projectCentreDispatch = useDispatch();

  const [openActions, setOpenActions] = React.useState<boolean>(false);
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  const handleClick = (e) => {
    if (e.stopPropagation) e.stopPropagation();
    if (e?.target !== e?.currentTarget) return;
    setOpenActions((previous) => !previous);
  };

  const handleShareModal = () => {
    handleShareContext({ dispatch, project: props });
  };

  const handleDeleteModal = () => {
    projectCentreDispatch(
      setDeleteProjectModal({ open: true, projectName: name })
    );
  };

  return (
    <Wrapper
      color={color}
      componentType={componentType}
      data-testid="ProjectRow-Wrapper"
      onClick={(e) => {
        handleClick(e);
        const baseUrl = makeRedirectDomain(id);
        const newWindow = window.open(
          `http://${baseUrl}/v5/dashboard/overview`,
          '_blank',
          'noopener,noreferrer'
        );
        if (newWindow) newWindow.opener = null;
      }}
    >
      <NameWrapper componentType={componentType}>
        <ImageWrapper>
          <ColorBar color={color} componentType={componentType} />
          <img src={image} width={70} height={70} />
        </ImageWrapper>
        <Name
          onMouseOver={(
            e:
              | React.MouseEvent<HTMLDivElement, MouseEvent>
              | MouseEventCustom<HTMLElementEvent<HTMLDivElement>>
          ) => {
            setOpenModal(() =>
              checkLengthAndSetModal(
                e as MouseEventCustom<HTMLElementEvent<HTMLDivElement>>
              )
            );
          }}
          onMouseOut={() => setOpenModal(false)}
        >
          <p>{name}</p>
          {openModal && <SimpleHoverModal>{name}</SimpleHoverModal>}
        </Name>
        <StatusCell>
          <StatusDot status={`OnboardingProjectStatus${stage}`} /> {stage}
        </StatusCell>
      </NameWrapper>
      <Cell componentType={componentType}>
        {formatProjectRowDate(launchDate)}
      </Cell>
      <Cell componentType={componentType}>{formatProjectRowDate(endDate)}</Cell>
      <Cell componentType={componentType}>
        {isProgramme && (
          <b>{sumOfCounts?.visitorCount ? sumOfCounts.visitorCount : '-'}</b>
        )}
        {visitorCount}
      </Cell>
      <Cell componentType={componentType}>
        {isProgramme && (
          <b>{sumOfCounts?.commentCount ? sumOfCounts.commentCount : '-'}</b>
        )}
        {commentCount}
      </Cell>
      <Cell componentType={componentType}>
        {isProgramme && (
          <b>{sumOfCounts?.agreeCount ? sumOfCounts.agreeCount : '-'}</b>
        )}
        {agreeCount}
      </Cell>
      <ArrowIconWrapper>
        <ActionDot
          color={color}
          onClick={(e) => {
            handleClick(e);
            setOpenActions(true);
          }}
        >
          ...
        </ActionDot>
        <ArrowIcon />
      </ArrowIconWrapper>
      {openActions && (
        <ActionModal onClick={(e) => handleClick(e)}>
          {actions.map((a) => {
            if (
              a.permissionsCheck({
                user,
                project: { _id, stage },
              })
            ) {
              return (
                <ActionButton
                  key={a.name}
                  onClick={(e) => {
                    handleClick(e);
                    a.action({
                      projectId: id,
                      handleShareModal,
                      handleDeleteModal,
                    });
                  }}
                >
                  {a.icon}
                  <p>{a.name}</p>
                </ActionButton>
              );
            }
          })}
        </ActionModal>
      )}
    </Wrapper>
  );
};
