import * as React from 'react';

const Pattern = (): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="734"
    height="383"
    className="SVGPattern"
  >
    <g
      transform="translate(.176 .24)"
      stroke="#FFF"
      fill="none"
      fillRule="evenodd"
    >
      <path d="M314.578 263.992h0c22.604 0 37.422-18.802 37.422-41.995C352 198.804 337.182 180 314.578 180v19.198c-17.438 0-31.578 14.504-31.578 32.401C283 249.496 297.136 264 314.578 264M654.863 242.005h0c-14.414 0-23.863 12.087-23.863 26.997S640.449 296 654.863 296v-12.342c11.12 0 20.137-9.324 20.137-20.829S665.986 242 654.863 242M585.678 16.002h0c-5.242 0-8.678 4.7-8.678 10.499C577 32.299 580.436 37 585.678 37v-4.8c4.043 0 7.322-3.626 7.322-8.1s-3.278-8.1-7.322-8.1M522.762 118.002h0c-5.897 0-9.762 4.253-9.762 9.499s3.865 9.499 9.762 9.499v-4.342c4.55 0 8.238-3.281 8.238-7.33 0-4.047-3.688-7.328-8.238-7.328" />
      <path
        d="M254.899 159.003h0c8.518 0 14.101 6.715 14.101 14.998S263.417 189 254.899 189v-6.857c-6.57 0-11.899-5.18-11.899-11.571 0-6.392 5.327-11.572 11.899-11.572"
        opacity=".474"
      />
      <path
        d="M612.56 41.004h0c11.14 0 18.44 9.176 18.44 20.498C631 72.822 623.699 82 612.56 82v-9.37c-8.592 0-15.56-7.08-15.56-15.815C597 48.079 603.966 41 612.56 41"
        opacity=".566"
      />
      <path
        d="M484.798 137.006h0c17.035 0 28.202 14.326 28.202 31.997 0 17.67-11.167 31.997-28.202 31.997v-14.627c-13.142 0-23.798-11.051-23.798-24.687C461 148.051 471.653 137 484.798 137"
        opacity=".765"
      />
      <path
        d="M635.104 60.01h0c30.139 0 49.896 25.07 49.896 55.994S665.243 172 635.104 172v-25.598c-23.25 0-42.104-19.339-42.104-43.2C593 79.338 611.848 60 635.104 60M105.544 275.007h0c19 0 31.456 15.892 31.456 35.496C137 330.106 124.545 346 105.544 346v-16.227c-14.658 0-26.544-12.26-26.544-27.387S90.882 275 105.544 275"
        opacity=".566"
      />
      <path d="M447.103 302.004h0c10.81 0 17.897 8.953 17.897 19.998 0 11.044-7.087 19.998-17.897 19.998v-9.142c-8.34 0-15.103-6.907-15.103-15.429S438.76 302 447.103 302M574.56 159.004h0c11.139 0 18.44 9.177 18.44 20.498 0 11.32-7.301 20.498-18.44 20.498v-9.37c-8.592 0-15.56-7.08-15.56-15.815 0-8.736 6.966-15.815 15.56-15.815M80.643 300.997h0c-8.845 0-14.643-7.163-14.643-15.998C66 276.163 71.798 269 80.643 269v7.314c6.824 0 12.357 5.525 12.357 12.343S87.469 301 80.643 301" />
      <circle cx="648.5" cy="115.5" r="6" />
      <ellipse cx="334" cy="328.5" rx="6.5" ry="6" />
      <circle cx="685.5" cy="4.5" r="4" />
      <circle cx="434.5" cy="149.5" r="4" />
      <circle cx="4.5" cy="324.5" r="4" />
      <path d="M346.618 381.76c96.28-167.98 225.015-194.566 386.206-79.76m-269.857 79.76v-10.325C426.664 374.395 362.21 346.241 366 264c3.79-82.24 89.054-93.338 109.328-90 53.871 5.5 103.526 56.898 103.526 113.022s-7.519 58.569-22.145 94.737" />
    </g>
  </svg>
);

export { Pattern };
