import styled, { css } from 'styled-components';

export const Wrapper = styled.header`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      width: 2.125rem;
      height: 2.125rem;
      ${theme.direction === 'rtl' ? 'margin-left' : 'margin-right'}: 0.25rem;
    }

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;

      h2 {
        text-align: center;
      }

      svg {
        margin: 0;
      }
    }
  `}
`;
