import * as React from 'react';
import styled from 'styled-components';
import { HubHeader } from 'Client/components/organisms/HubHeader';
import { ImageMapProContent } from 'Client/pages/hub/types';
import { loadScript } from 'Client/utils/loadScript';

export interface ImageMapProProps {
  content: ImageMapProContent;
  isEditMode?: boolean;
}

export const ImageMapPro: React.FC<ImageMapProProps> = ({
  content: { code, title },
  isEditMode,
}) => {
  const loadImageMapPro = () => {
    Promise.all([
      loadScript({
        url: 'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js',
        id: 'jquery.min.js',
      }),
    ]).then(() => {
      Promise.all([
        loadScript({
          url: '/static/js/image-map-pro.min.js',
          id: 'image-map-pro.min.js',
        }),
      ]).then(loadImage);
    });
  };

  React.useEffect(() => {
    loadImageMapPro();
  }, []);

  React.useEffect(() => {
    if (typeof window == 'undefined' || !isEditMode) return;

    loadImageMapPro();
  }, [code]);

  const loadImage = () => {
    if (
      !document.getElementById('image-map-pro.min.js') ||
      !document.getElementById('jquery.min.js')
    )
      return;

    const script = document.createElement('script');
    script.id = 'image-map-pro-script';
    script.type = 'text/javascript';
    script.innerHTML = `
    (function ($, window, document, undefined) {
      $(document).ready(function () {
        // Launch the plugin
        $("#image-map-pro-container").imageMapPro(${code});
      });
    })(jQuery, window, document);
    `;
    document.body.appendChild(script);
  };

  return (
    <Wrapper>
      <HubHeader title={title} />

      <div id={`image-map-pro-container`}></div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 1rem;
`;
