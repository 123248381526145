import * as React from 'react';
import { differenceInCalendarDays, format } from 'date-fns';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getPlanningAppTitle } from 'Client/pages/planningapps/utils';
import {
  glaPlanningAppsCategories,
  glaPlanningAppsStatuses,
} from 'Client/pages/planningapps/constants/glaConstants';
import { pluralizeWord } from 'Client/utils/stringManipulations';
import { getPlanningAppsCategoryIcon } from 'Atoms/Icons/PlanningApps';
import {
  OutlinedCommentIcon,
  CustomActiveCircleIcon,
  FilledCircleIcon,
} from 'Icons';
import { useProject, useHubContext } from 'Client/utils/hooks';
import { LOADER_ACTION_TYPES } from 'Client/context/loaderReducer';
import {
  PlanningAppPageAndContent,
  Image,
  PlanningAppStage,
} from 'Shared/types/planningapp';
import {
  Card,
  CardTitle,
  CardCommentContainer,
  StatusWrapper,
  CategoryWrapper,
  CommentsWrapper,
  HorizontalProposalsReelWrapper,
  ItemsContainer,
  CardWrapper,
  PlanningAppImage,
  PlanningAppCardLeftSide,
  AddressContainer,
  DescriptionContainer,
  StatusLabel,
  DaysLeft,
  PlanningAppCardRightSide,
  CardCategory,
  IconContainer,
  CardTopSection,
  CommentSection,
  AdditionalInfo,
  StageInfo,
  ReferenceInfo,
  CommentsText,
} from './HorizontalProposalsReel.styles';
import { ProposalCardComments } from '../ProposalCardComments';

interface HorizontalProposalsReelProps {
  tiles: PlanningAppPageAndContent[];
}
const HorizontalProposalsReel = ({ tiles }: HorizontalProposalsReelProps) => {
  const { t } = useTranslation();
  const { dispatch } = useHubContext();
  const project = useProject();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const mousePos = React.useRef({ x: 0, y: 0 });
  const clickTime = React.useRef(0);

  const getPlanAppMainImage = (images: Image[]) => {
    if (!images?.length) return null;
    return images.find((img) => img?.isMainImage);
  };

  const getDaysLeft = (consultationEnd: Date): number => {
    if (!consultationEnd) return null;
    return differenceInCalendarDays(new Date(consultationEnd), new Date());
  };

  const getColorByType = (
    type: string,
    label: string,
    intensity?: string
  ): string => {
    if (!intensity) intensity = 'primary';
    const trimmedLabel = label.includes(',')
      ? label.split(',')[0]
      : label.trimEnd();
    const defaultCategoryColor = glaPlanningAppsCategories.find(
      (category) => category.label === 'Undefined'
    );
    switch (type) {
      case 'status':
        return (
          glaPlanningAppsStatuses.find(
            (status) => status.label === trimmedLabel
          )?.color || '#767676'
        );
      case 'category':
        const categoryItem = glaPlanningAppsCategories.find(
          (category) => category.label === trimmedLabel
        );
        const toReturn = categoryItem?.colors?.[intensity];

        return toReturn || defaultCategoryColor.colors[intensity];
      default:
        return '#767676';
    }
  };

  const CommentContent = ({ planApp }) => {
    if (planApp.content.stage !== PlanningAppStage.stage3) {
      return null;
    }

    return (
      <CommentSection href={`/planningapps/${planApp.slug}?tab=3`}>
        <CardCommentContainer>
          {planApp.contributionsNumber > 0 ? (
            <ProposalCardComments
              stage={planApp.stage}
              count={planApp.contributionsNumber}
              hideCount={planApp.content.stage !== PlanningAppStage.stage3}
              isPlanningApp={true}
              customColor="black"
            />
          ) : (
            <CommentsWrapper
              withText={planApp.content.status === 'Consultation open'}
            >
              <OutlinedCommentIcon color="black" />
              <CommentsText>{'0'}</CommentsText>
            </CommentsWrapper>
          )}
        </CardCommentContainer>
      </CommentSection>
    );
  };

  const OnMouseDown = (e: React.MouseEvent) => {
    /* Register stats */
    clickTime.current = Date.now();
    mousePos.current.x = e.nativeEvent.x;
    mousePos.current.y = e.nativeEvent.y;
  };
  const OnMouseUp = (e: React.MouseEvent) => {
    if (
      Date.now() - clickTime.current < 200 &&
      mousePos.current.x === e.nativeEvent.x &&
      mousePos.current.y === e.nativeEvent.y
    ) {
      handleTileClick(e);
    }
    /* Reset stats */
    clickTime.current = 0;
    mousePos.current = { x: 0, y: 0 };
  };

  const handleTileClick = (event: KeyboardEvent | React.MouseEvent) => {
    if (
      !event.metaKey &&
      !event.ctrlKey &&
      (event as React.MouseEvent).button !== 1
    ) {
      dispatch({
        type: LOADER_ACTION_TYPES.SET_LOADING,
        loading: true,
        from: 'hub',
      });
    }
  };

  return (
    <>
      <HorizontalProposalsReelWrapper>
        <ItemsContainer data-testid="horizontal-proposals-items-container">
          {tiles.map((tile) => (
            <Card
              tabIndex={0}
              key={tile.slug}
              onMouseDown={(e: React.MouseEvent) => OnMouseDown(e)}
              onMouseUp={(e: React.MouseEvent) => OnMouseUp(e)}
              href={`/planningapps/${tile.slug}`}
            >
              <CardWrapper>
                <CardTopSection aria-label={tile.content.name}>
                  <PlanningAppImage
                    alt={
                      getPlanAppMainImage(tile?.content.images)?.name ||
                      'placeholder'
                    }
                    src={
                      getPlanAppMainImage(tile?.content.images)?.url ||
                      'https://res.cloudinary.com/commonplace-digital-limited/image/upload/v1686227526/generic_application_image_1_jrtbly.jpg'
                    }
                  />
                  <PlanningAppCardLeftSide
                    hasDescription={!!tile.content.shortDesc}
                  >
                    <CardTitle>
                      {tile.content?.name ||
                        getPlanningAppTitle(tile.content.address)}
                    </CardTitle>
                    <AddressContainer hasDescription={!!tile.content.shortDesc}>
                      {tile.content.postcode}
                    </AddressContainer>
                    {isMobile && (
                      <StageInfo>
                        <strong>
                          {t('Stage')}:&nbsp;<p>{tile.content.stage}</p>
                        </strong>
                      </StageInfo>
                    )}
                  </PlanningAppCardLeftSide>
                  {!isMobile && (
                    <DescriptionContainer
                      hasDescription={!!tile.content.shortDesc}
                    >
                      {tile.content.shortDesc}
                    </DescriptionContainer>
                  )}
                  <PlanningAppCardRightSide
                    hasDaysLeft={!!getDaysLeft(tile.content.consultationEnd)}
                  >
                    {getDaysLeft(tile.content.consultationEnd) && (
                      <DaysLeft
                        color={getColorByType('status', tile.content.status)}
                      >
                        <strong>{`${getDaysLeft(
                          tile.content.consultationEnd
                        )} ${pluralizeWord(
                          'Day',
                          getDaysLeft(tile.content.consultationEnd)
                        )} `}</strong>
                        <p> left</p>
                      </DaysLeft>
                    )}
                    <StatusWrapper>
                      {
                        <CustomActiveCircleIcon
                          color={getColorByType('status', tile.content.status)}
                          width={20}
                          height={20}
                          radius={3}
                          extraCircles={1}
                        />
                      }
                      <StatusLabel>{tile.content.status}</StatusLabel>
                    </StatusWrapper>
                    {isMobile && (
                      <ReferenceInfo>
                        <strong>{t('Ref:')}&nbsp;</strong>
                        {project?.features?.glaPlanningApps
                          ? tile.content?.glaReference
                          : tile.content.reference}
                      </ReferenceInfo>
                    )}
                  </PlanningAppCardRightSide>
                  {!isMobile && <CommentContent planApp={tile} />}
                </CardTopSection>
                {isMobile && tile?.content?.shortDesc && (
                  <DescriptionContainer>
                    {tile.content.shortDesc}
                  </DescriptionContainer>
                )}
                <CategoryWrapper
                  color={getColorByType(
                    'category',
                    tile.content.category,
                    'tertiary'
                  )}
                >
                  <CardCategory>
                    <IconContainer
                      color={getColorByType(
                        'category',
                        tile.content.category,
                        'secondary'
                      )}
                    >
                      {getPlanningAppsCategoryIcon({
                        icon: tile.content.category,
                        width: 30,
                        height: 30,
                      })}
                    </IconContainer>
                    <FilledCircleIcon
                      color={getColorByType(
                        'category',
                        tile.content.category,
                        'primary'
                      )}
                      width={10}
                      height={10}
                    />

                    <strong>{tile.content.category}</strong>
                  </CardCategory>
                  {!isMobile && tile.content.validatedDate && (
                    <AdditionalInfo>
                      <strong>{t('Validated date:')}&nbsp;</strong>
                      <p>
                        {format(
                          new Date(tile.content.validatedDate),
                          'dd/MM/yyyy'
                        )}
                      </p>
                    </AdditionalInfo>
                  )}
                  {!isMobile && (
                    <>
                      <StageInfo>
                        <strong>
                          {t('Stage')}:&nbsp;<p>{tile.content.stage}</p>
                        </strong>
                      </StageInfo>
                      <ReferenceInfo>
                        <strong>{t('Ref:')}&nbsp;</strong>{' '}
                        {project?.features?.glaPlanningApps
                          ? tile.content?.glaReference
                          : tile.content.reference}
                      </ReferenceInfo>
                    </>
                  )}
                  {isMobile && <CommentContent planApp={tile} />}
                </CategoryWrapper>
              </CardWrapper>
            </Card>
          ))}
        </ItemsContainer>
      </HorizontalProposalsReelWrapper>
    </>
  );
};

export default HorizontalProposalsReel;
