import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SimpleSelectProps } from 'Client/components/molecules/SimpleSelect/types';
import { ToolTip } from 'Client/components/molecules';
import { Container, Dropdown, Label, LabelContainer } from './styles';

interface Props extends SimpleSelectProps {
  label?: string;
  isMenuOpen?: boolean;
}

enum ToolTipText {
  Ward = 'Wards will only appear in the list if a project exists within those wards.',
}

export const FilterDropdown: React.FC<Props> = ({
  label,
  isMenuOpen,
  value,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Container isMenuOpen={isMenuOpen}>
      <LabelContainer>
        {label && <Label>{label}</Label>}

        {label.includes('Ward') && (
          <ToolTip
            minHorizontalPosition={30}
            startPositionHorizontalMutation={-116}
            isMenuOpen={isMenuOpen}
          >
            <p>{t(ToolTipText[label])}</p>
          </ToolTip>
        )}
      </LabelContainer>

      <Dropdown value={value} {...props} />
    </Container>
  );
};
