import * as React from 'react';
import styled from 'styled-components';
import { ChevronIcon } from 'Atoms/Icons';

export const Wrapper = styled.div<{ isOpen: boolean }>`
  width: ${({ isOpen }) => (isOpen ? '15.625rem' : '3.75rem')};
  background-color: ${({ theme }) => theme.colorMappings.dashboardNavBarYellow};
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

export const Header = styled.div`
  background: black;
  height: 4.375rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonsDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ToggleButton = styled.div`
  height: 2.5625rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: ${({ theme }) =>
    theme.colorMappings.dashboardNavBarYellowHover};
`;

export const StyledChevron = styled(({ isOpen, ...props }) => (
  <ChevronIcon {...props} />
)).attrs({
  color: 'black',
  opacity: '1',
  width: 6,
  height: 11,
})<{ isOpen: boolean }>`
  margin-right: 1.5625rem;
  transform: ${({ isOpen }) => (isOpen ? '' : 'rotate(180deg)')};
`;

export const ClickableDivTop = styled.div`
  height: 3.25rem;
`;

export const ClickableDivBottom = styled.div`
  flex: 1;
`;
