import * as React from 'react';
import { Next, Back, Wrapper, ArrowIcon } from './Footer.styles';
import { FooterProps } from './types';

export const Footer: React.FC<FooterProps> = ({
  onClickNext,
  backHref,
}: FooterProps) => {
  return (
    <Wrapper data-testid="Footer-Wrapper">
      <Back href={backHref}>
        <ArrowIcon />
        <p>Back</p>
      </Back>
      <Next onClick={onClickNext}>
        <p>Next</p>
        <ArrowIcon />
      </Next>
    </Wrapper>
  );
};
