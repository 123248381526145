import { captureException } from '@sentry/node';
import fetch from 'isomorphic-unfetch';
import { EMAIL_TYPES } from 'Client/constants/emailTypes';
import { ProjectGaudi, ProjectProps } from 'Shared/types';
import { PageToBeTranslated } from 'Shared/types/translation';

interface SendTranslationEmailParams {
  user: { id: string };
  page: PageToBeTranslated;
  project: ProjectGaudi & ProjectProps;
  targetLanguage: string;
}

export const sendTranslationEmail = async ({
  user,
  page,
  project,
  targetLanguage,
}: SendTranslationEmailParams): Promise<{
  success: boolean;
  message: string;
}> => {
  try {
    await fetch(`/api/email/requestTranslation`, {
      method: 'POST',
      body: JSON.stringify({
        user,
        page,
        project,
        targetLanguage,
        date: new Date().toISOString(),
        type: EMAIL_TYPES.REQUEST_TRANSLATION,
      }),
    });

    return {
      success: true,
      message: 'Translation request dispatched',
    };
  } catch (error) {
    captureException(
      `error in sendTranslationEmail @ sendTranslationEmail.ts : ${error}`
    );

    return {
      success: false,
      message: 'Unable to send translation request',
    };
  }
};
