import * as React from 'react';
import styled from 'styled-components';
import { opacify, readableColor } from 'polished';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import { StatusDot as StatusDotBase } from 'Atoms/StatusDot';
import { IconProps } from 'Atoms/Icons/types';
import { ShareIcon as ShareIconBase } from 'Atoms/Icons';

export const Wrapper = styled.div<{ color: string; componentType?: string }>`
  display: flex;
  position: relative;
  background-color: ${({ theme }) => theme.colors.white[500]};
  border-radius: 0.25rem;
  width: 100%;
  height: 4.375rem;
  margin-bottom: 1.125rem;
  align-items: center;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 0.5rem 0.1875rem ${({ color }) => opacify(-0.53, color)};
  }

  ${({ componentType, color }) =>
    componentType === 'programme' && `border-top: 3px solid ${color}`};
`;

export const ImageWrapper = styled.div`
  display: flex;
  margin-right: 1.375rem;

  img {
    object-fit: contain;
  }
`;

export const ColorBar = styled.div<{ color: string; componentType?: string }>`
  background-color: ${({ color }) => color};
  border-radius: 0.25rem 0 0 0.25rem;
  height: 100%;
  width: 0.5625rem;

  ${({ componentType }) => componentType === 'programme' && 'display: none'};
`;

export const NameWrapper = styled.div<{ componentType?: string }>`
  display: flex;
  min-width: ${({ componentType }) =>
    componentType === 'child' ? '39%' : '40%'};
  max-width: ${({ componentType }) =>
    componentType === 'child' ? '39%' : '40%'};
`;

export const Name = styled.div`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.125rem;
  width: 31%;
  max-width: 31%;
  margin-right: 1.5625rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1.5625rem 0;

  & > p {
    margin: 0;
    display: inline;
  }
`;

export const Cell = styled.div<{ componentType?: string }>`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  width: ${({ componentType }) => (componentType === 'child' ? '12%' : '11%')};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StatusCell = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  display: flex;
  align-items: center;
  width: 26%;
  margin-right: 2%;
  text-transform: capitalize;

  span {
    align-self: center !important;
    margin-right: 0.9rem;
    border: none;
    width: 0.3125rem;
    height: 0.3125rem;

    &:before {
      filter: brightness(1.5) contrast(1.5) saturate(3);
    }
  }
`;

export const StatusDot = styled(({ ...props }) => (
  <StatusDotBase {...props} />
))``;

export const ArrowIconWrapper = styled.div`
  width: 8%;
  display: flex;
  justify-content: space-evenly;
  margin-left: auto;
`;

export const ArrowIcon = styled(({ isOpen, ...props }) => (
  <ArrowBackIcon {...props} />
))`
  color: ${({ theme }) => theme.colors.grey[700]};
  width: 1.6875rem;
  height: 1.6875rem;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(270deg)' : 'rotate(180deg)')};
  margin-right: 3%;
`;

export const ActionDot = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  width: 1.375rem;
  height: 1.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 600;
  font-size: 1.0625rem;
  line-height: 1.5rem;
  padding-bottom: 0.4375rem;
  cursor: pointer;
  color: ${({ color }) => readableColor(color)};
`;

export const ActionModal = styled.div`
  position: absolute;
  top: 50%;
  right: 4%;
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 0 1rem;
  background-color: ${({ theme }) => theme.colors.white[500]};
  z-index: 11;
  pointer-events: none;
  border: 0.0625rem solid ${({ theme }) => theme.colors.grey[100]};
  box-shadow: 0rem 0.25rem 0.25rem
    ${({ theme }) => opacify(-0.92, theme.colors.black[500])};
  border-radius: 0.125rem;
  :before {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    content: '';
    z-index: 10;
    pointer-events: auto;
  }
`;

export const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;
  height: 1.25rem;
  margin-bottom: 1rem;
  cursor: pointer;
  pointer-events: auto;
  z-index: 13;
  svg {
    margin: 0 0.3125rem 0 0;
  }
  p {
    margin: 0;
    padding: 0;
  }
  &:hover {
    color: ${({ theme }) => theme.colorMappings.teamMemberActionHover};
    svg {
      stroke: ${({ theme }) => theme.colorMappings.teamMemberActionHover};
    }
    g {
      stroke: ${({ theme }) => theme.colorMappings.teamMemberActionHover};
    }
    circle {
      stroke: ${({ theme }) => theme.colorMappings.teamMemberActionHover};
    }
    path {
      stroke: ${({ theme }) => theme.colorMappings.teamMemberActionHover};
    }
  }
`;

export const ShareIcon = styled(({ ...props }: IconProps) => (
  <ShareIconBase {...props} width={16} height={16} bold />
))`
  &:hover {
    stroke: ${({ theme }) =>
      theme.colorMappings.teamMemberActionHover} !important;
  }
  g {
    opacity: 1 !important;
  }
`;
