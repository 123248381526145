import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 5rem;
  background-color: ${({ theme }) => theme.colors.white[500]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1rem 6.625rem 1rem 5.5625rem;
  box-shadow: 0rem -0.125rem 0.9375rem 0rem rgba(204, 204, 204, 0.439);
`;

export const Next = styled.a`
  height: 100%;
  width: 12.3125rem;
  border-radius: 0.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.375rem;
  line-height: 1.75rem;
  color: ${({ theme }) => theme.colors.white[500]};
  background-color: ${({ theme }) => theme.colors.green[500]};
  cursor: pointer;

  p {
    margin: 0 2.625rem;
  }

  svg {
    color: ${({ theme }) => theme.colors.white[500]};
    transform: rotate(180deg);
  }
`;

export const Back = styled.a`
  height: 100%;
  width: 12.3125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.375rem;
  line-height: 1.75rem;
  color: ${({ theme }) => theme.colors.green[500]};
  cursor: pointer;

  p {
    margin: 0 0.625rem;
  }
`;

export const ArrowIcon = styled(ArrowBackIcon)`
  width: 1.125rem;
  height: 1.1875rem;
`;
