import { OpenGraphProps, GaudiBranding } from 'Shared/types';

export const imageCheck = (
  openGraph: OpenGraphProps,
  brandingOptions: GaudiBranding
) => {
  const openGraphImage = openGraph?.image;
  const customerLogo = brandingOptions?.customerLogoUrl;
  const defaultImage =
    'https://res.cloudinary.com/commonplace-digital-limited/image/upload/v1594937126/Commonplace/commonplace-icon-green.png';

  return openGraphImage || customerLogo || defaultImage;
};
