import * as React from 'react';
import { NavBar } from 'Client/pages/project-centre/components';
import { Content, PageContainer, Wrapper } from './OnboardingPage.styles';
import { OnboardingMasthead } from './components/OnboardingMasthead/OnboardingMasthead';

export const OnboardingPagesTemplate: React.FC = ({ children }) => {
  return (
    <PageContainer>
      <Wrapper>
        <OnboardingMasthead />
        <Content isBannerShown={false}>
          <NavBar />
          {children}
        </Content>
      </Wrapper>
    </PageContainer>
  );
};

export default OnboardingPagesTemplate;
