import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CardReel } from 'Organisms';
import { makeProposalCards } from 'Pages/proposals/utils';
import { HubHeader } from 'Client/components/organisms/HubHeader';
import { useUser } from 'Client/utils/hooks';
import { ProposalStage } from 'Client/pages/proposals/types';
import { canUserSeeDrafts } from 'Client/services/proposals';
import { ProposalsProps } from './types';

const Proposals: React.FC<ProposalsProps> = ({
  proposals,
  title,
}: ProposalsProps) => {
  const { user } = useUser();
  const { t } = useTranslation();

  if (!proposals) return null;

  const visibleProposals = proposals.filter(({ stage }) => {
    const isDraft = stage === ProposalStage.DRAFT;
    return !isDraft || canUserSeeDrafts(user);
  });

  if (!visibleProposals.length) return null;

  return (
    <>
      <HubHeader
        title={title || t('Have your say')}
        anchorText={t('View all')}
        path="proposals"
      />
      {visibleProposals && (
        <CardReel cards={makeProposalCards(visibleProposals)} />
      )}
    </>
  );
};

export { Proposals };
