import * as React from 'react';
import { CustomHeroProps } from './types';
import { Hero } from '.';

export const CustomHero: React.FC<CustomHeroProps> = ({
  heroContent,
  ...props
}: CustomHeroProps) => (
  <Hero
    title={heroContent?.title}
    description={heroContent?.description}
    learnMoreProps={{
      href: heroContent?.learnMore?.link?.url,
      text: heroContent?.learnMore?.link?.text,
    }}
    backgroundImage={heroContent?.backgroundImage}
    layout={heroContent?.layout}
    overlayImage={heroContent?.overlayImage}
    {...props}
  />
);
