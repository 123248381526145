import * as React from 'react';
import styled, { css } from 'styled-components';
import {
  NewsIcon as NewsIconBase,
  ChevronDownIcon,
  CircleCheckIcon as CircleCheckIconBase,
} from 'Atoms/Icons';

export const HeaderContainer = styled.div<{ isPanelOpen: boolean }>`
  padding: 0.5rem 0;
  cursor: pointer;
  text-align: center;
  ${({ theme, isPanelOpen }) =>
    isPanelOpen &&
    css`
      font-weight: ${theme.fontWeights.bold};
    `}
`;

export const Title = styled.span<{ isPanelOpen?: boolean }>`
  ${({ theme, isPanelOpen }) => css`
    font-size: 1.1rem;
    strong {
      font-weight: ${theme.fontWeights.bold};
    }
    ${isPanelOpen &&
    css`
      .no-mobile {
        display: none;
      }
    `}
    ${theme.breakpoints.down('xs')} {
      .no-mobile {
        display: none;
      }
    }
  `}
`;

export const ChevronDown = styled(ChevronDownIcon)`
  float: right;
  margin-top: 0.3rem;
  g {
    opacity: 1;
  }
`;

const EnhancedIconStyles = ({ isPanelOpen }) =>
  isPanelOpen
    ? css`
        vertical-align: text-top;
        display: block;
        margin: 1rem auto;
        height: 2.3rem;
        width: 2.3rem;
      `
    : css`
        vertical-align: text-top;
        height: 1rem;
        margin-right: 0.2rem;
      `;

const NewsIcon = ({ isPanelOpen, ...props }) => (
  <NewsIconBase bold {...props} />
);
export const EnhancedNewsIcon = styled(NewsIcon)`
  ${EnhancedIconStyles}
`;

const CircleCheckIcon = ({ isPanelOpen, ...props }) => (
  <CircleCheckIconBase bold {...props} />
);
export const EnhancedCircleCheckIcon = styled(CircleCheckIcon)`
  ${EnhancedIconStyles}

  color: ${({ theme }) => theme.colorMappings.success};
`;
