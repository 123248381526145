import * as React from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import {
  ProjectNavigation,
  Footer,
  GamingBanner,
  Masthead as ProjectMasthead,
} from 'Organisms';
import { PROPOSAL_ACTION_TYPES } from 'Client/context/proposalReducer';
import { useProject, useMap, useProposalContext } from 'Client/utils/hooks';
import { ProposalStage } from 'Client/pages/proposals/types';
import { OpenGraphProps } from 'Shared/types';
import { MenuItem } from 'Client/components/organisms/ProjectNavigation/types';
import { useBanner } from 'Client/utils/hooks/useBanner';
import { HeadMeta } from './components/HeadMeta';
import { Masthead as ProposalMasthead } from './ProposalPages/components';

interface HubPagesTemplateProps extends WrapperProps {
  customHero?: React.ReactNode;
  children: React.ReactElement | React.ReactElement[];
  showProjectNavigation?: boolean;
  showFooter?: boolean;
  showGamingBanner?: boolean;
  shouldShowContent?: boolean;
  openGraphInfo?: OpenGraphProps;
  title?: string;
  stage?: ProposalStage;
  showProposalMasthead?: boolean;
  showProjectMasthead?: boolean;
  slug?: string;
  isMap?: boolean;
  navbarItems?: MenuItem[];
}

interface WrapperProps {
  greyBackground?: boolean;
}

export const HubPagesTemplate: React.FC<HubPagesTemplateProps> = ({
  customHero,
  greyBackground,
  children,
  showGamingBanner,
  showFooter = true,
  showProjectNavigation = true,
  shouldShowContent = true,
  title,
  openGraphInfo,
  stage,
  showProposalMasthead = false,
  showProjectMasthead = true,
  slug,
  isMap = false,
  navbarItems,
}: HubPagesTemplateProps) => {
  const project = useProject();
  const router = useRouter();

  const [{ isGamingBannerOpen }, dispatch] = useProposalContext();
  const {
    state: { proposal },
  } = useMap();
  const hideCommentsCount = proposal?.featureFlags?.notCommentable;

  const isEmbed = router.query?.embed === 'true';
  const _showHeader = !isEmbed;
  const _showFooter = isEmbed ? false : showFooter;
  const _showProjectNavigation = isEmbed ? false : showProjectNavigation;
  const { errorBannerHeight, stageBannerHeight } = useBanner();

  return (
    <Wrapper greyBackground={greyBackground} data-testid="HubPagesTemplate">
      <HeadMeta openGraphInfo={openGraphInfo} />
      {_showHeader && (
        <>
          {showProposalMasthead && (
            <ProposalMasthead
              title={title}
              stage={stage}
              slug={slug}
              hideCommentsCount={hideCommentsCount}
            />
          )}
          {showProjectMasthead && <ProjectMasthead project={project} />}
        </>
      )}
      {customHero}
      {shouldShowContent && (
        <>
          {_showProjectNavigation && (
            <ProjectNavigation menuItems={navbarItems} />
          )}
          <Content
            isMap={isMap}
            data-id="HubPagesTemplate__Content"
            showFooter={showFooter}
            showProjectNavigation={showProjectNavigation}
            errorBannerHeight={errorBannerHeight}
            stageBannerHeight={stageBannerHeight}
          >
            {children}
          </Content>
        </>
      )}
      {showGamingBanner && isGamingBannerOpen && (
        <GamingBanner
          onClose={() => {
            dispatch({
              type: PROPOSAL_ACTION_TYPES.SET_GAMING_BANNER_IS_OPEN,
              isOpen: false,
            });
          }}
        />
      )}
      {_showFooter && <Footer />}
    </Wrapper>
  );
};

const Wrapper = styled('div')<WrapperProps>`
  min-height: 100%;
  background-color: ${({ theme, greyBackground }) =>
    greyBackground
      ? theme.colorMappings.pageBackgroundColor
      : theme.colors.white[500]};
`;

const Content = styled(({ ...props }) => <div {...props} />)`
  margin: 0 auto;
  min-height: calc(
    100vh - (${({ showFooter }) => (showFooter ? '11rem' : '0rem')}) -
      (
        ${({ showProjectNavigation }) =>
          showProjectNavigation ? '3.5rem' : '0rem'}
      ) -
      ${({ theme, errorBannerHeight, stageBannerHeight }) =>
        `${
          Number(theme.proposals.masthead.height.replace('rem', '')) +
          errorBannerHeight +
          stageBannerHeight
        }rem`}
  );
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: initial;
  }
`;

export default HubPagesTemplate;
