import * as React from 'react';

export const Checkbox = () => (
  <svg
    width="18"
    height="18"
    viewBox="3 3 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.9165 6.6665C2.9165 4.59544 4.59544 2.9165 6.6665 2.9165H13.3332C15.4042 2.9165 17.0832 4.59544 17.0832 6.6665V13.3332C17.0832 15.4042 15.4042 17.0832 13.3332 17.0832H6.6665C4.59544 17.0832 2.9165 15.4042 2.9165 13.3332V6.6665ZM6.6665 3.74984C5.05567 3.74984 3.74984 5.05567 3.74984 6.6665V13.3332C3.74984 14.944 5.05567 16.2498 6.6665 16.2498H13.3332C14.944 16.2498 16.2498 14.944 16.2498 13.3332V6.6665C16.2498 5.05567 14.944 3.74984 13.3332 3.74984H6.6665ZM14.4611 7.20521C14.6239 7.36793 14.6239 7.63175 14.4611 7.79447L8.62778 13.6278C8.46507 13.7905 8.20125 13.7905 8.03853 13.6278L5.53849 11.1278C5.37577 10.9651 5.37577 10.7013 5.53849 10.5385C5.70121 10.3758 5.96503 10.3758 6.12775 10.5385L8.33315 12.7439L13.8719 7.20521C14.0346 7.04249 14.2984 7.04249 14.4611 7.20521Z"
      fill="black"
    />
  </svg>
);
