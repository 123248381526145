import { format } from 'date-fns';

export const formatProjectRowDate = (date: string) => {
  if (!date) {
    return '-';
  }

  try {
    const trim = date.replace(/\s/g, '');
    if (!isNaN(Number(trim))) {
      return format(new Date(Number(trim)), 'dd MMM yyyy');
    }
    return format(new Date(trim), 'dd MMM yyyy');
  } catch (e) {
    return '-';
  }
};
