/**
 * This page is for Google crawlers only.
 * Users can't access this, everyone is immediately redirected to the first step
 * of the proposal instead.
 */
import * as React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { readableColor } from 'polished';
import Box from '@material-ui/core/Box';
import { PROPOSAL_ACTION_TYPES } from 'Client/context/proposalReducer';
import { ProposalPagesTemplate as Template } from 'Templates';
import { Link, SeoMetaInfo, Typography } from 'Atoms';
import { useProposalContext } from 'Client/utils/hooks';
import { cpBrandName } from 'Client/constants/brand';
import { StartPageProps, AllProposalStepsComponent } from './';

export const StartPage: React.FC<StartPageProps> = ({
  proposalTitle,
  proposalSlug,
  steps,
  projectName,
  projectStage,
  ...props
}: StartPageProps) => {
  const [, dispatch] = useProposalContext();
  const { t } = useTranslation();

  React.useEffect(() => {
    dispatch({
      type: PROPOSAL_ACTION_TYPES.SET_FOOTER,
      showFooter: false,
    });
  }, []);

  return (
    <Template
      proposalTitle={proposalTitle}
      proposalSlug={proposalSlug}
      {...props}
    >
      <SeoMetaInfo
        projectStage={projectStage}
        projectName={projectName}
        page="proposal"
      />
      <Box display="flex" m={1}>
        <Box flex={1} m={0}>
          <Typography>
            {t('Discover {{projectName}}, {{proposalTitle}}. ', {
              projectName,
              proposalTitle,
            })}
            {t(
              'Have your say today and share your views on the future of your community. '
            )}
            {t(
              'Powered by {{cpBrandName}}, the leading community engagement platform.',
              { cpBrandName }
            )}
          </Typography>
        </Box>
        <SecondaryLinkWrapper>
          <Link to="/">{t('More info')}</Link>
        </SecondaryLinkWrapper>
        <PrimaryLinkWrapper>
          <Link to={`/proposals/${proposalSlug}/step1`}>
            {t('Get started')}
          </Link>
        </PrimaryLinkWrapper>
      </Box>
      <DragDropContext onDragEnd={() => null}>
        <AllProposalStepsComponent steps={steps} />
      </DragDropContext>
    </Template>
  );
};

const PrimaryLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  background-color: ${({ theme }) =>
    theme.colorMappings.primaryButtonBackground};
  > a {
    color: ${({ theme }) =>
      readableColor(theme.colorMappings.primaryButtonBackground)};
  }
`;

const SecondaryLinkWrapper = styled(PrimaryLinkWrapper)`
  margin-left: auto;
  margin-right: 1rem;
  background-color: transparent;
  border: 0.125rem solid
    ${({ theme }) => theme.colorMappings.primaryButtonBackground};
  > a {
    color: ${({ theme }) => theme.colorMappings.hyperlink};
  }
`;
