import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectCard } from 'Atoms';
import { useEditModeContext } from 'Client/utils/hooks';
import { useEditModeLayout } from 'Client/context/editModeLayoutContext';
import { ResultsIcon } from 'Atoms/Icons';
import {
  Counter,
  ResultsContainer,
  ResultsDescription,
  ResultsTitle,
  Wrapper,
} from './ProgrammeReel.styles';
import { ProgrammeReelProps } from './types';
import { Reel } from '../Reel';
import { FiltersMenu } from './components';

export const ProgrammeReel: React.FC<ProgrammeReelProps> = ({
  projects,
  showCardsCounter = true,
  handleChanges,
  handleClearFilters,
  filters,
  displayFilters = false,
  projectsWards,
  customFiltersValues,
  customFiltersOptions,
}: ProgrammeReelProps) => {
  const { t } = useTranslation();
  const [isEditModeLayout] = useEditModeLayout();
  const [editModeState] = useEditModeContext();
  const editModeDesktopLayout =
    isEditModeLayout &&
    editModeState?.editModeLayout?.previewMode === 'desktop';

  const hasActiveFilters =
    filters && Object.values(filters)?.filter(Boolean)?.length > 0;

  return (
    <Wrapper data-testid="ProgrammeReel">
      {displayFilters && !isEditModeLayout && (
        <FiltersMenu
          filters={filters}
          handleChanges={handleChanges}
          handleClearFilters={handleClearFilters}
          projectsWards={projectsWards}
          customFiltersValues={customFiltersValues}
          customFiltersOptions={customFiltersOptions}
        />
      )}

      {showCardsCounter && (
        <Counter data-testid="ProgrammeReel-Counter">
          {t('{{numOfProjects}} projects', {
            numOfProjects: projects.length,
          })}
        </Counter>
      )}

      {hasActiveFilters && projects.length === 0 && (
        <ResultsContainer>
          <ResultsIcon />
          <div>
            <ResultsTitle>{t('No results found')}</ResultsTitle>
            <ResultsDescription>
              {t(
                'Whoops... No projects match your search, try expanding your criteria'
              )}
            </ResultsDescription>
          </div>
        </ResultsContainer>
      )}

      <Reel editModeDesktopLayout={editModeDesktopLayout}>
        {projects.map((project) => (
          <ProjectCard project={project} key={project._id} />
        ))}
      </Reel>
    </Wrapper>
  );
};
