import i18next from 'i18next';
import { ProjectStage } from 'Shared/types';

export const projectStages = [
  {
    value: ProjectStage.ACTIVE,
    label: i18next.t('Active'),
  },
  {
    value: ProjectStage.ARCHIVED,
    label: i18next.t('Archived'),
  },
  {
    value: ProjectStage.CLOSED,
    label: i18next.t('Closed'),
  },
  {
    value: ProjectStage.COMPLETED,
    label: i18next.t('Completed'),
  },
  {
    value: ProjectStage.TESTING,
    label: i18next.t('Testing'),
  },
];
