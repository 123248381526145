import * as React from 'react';
import { ExpandMoreIcon } from 'Client/components/molecules/AddFeaturePanel/AddFeaturePanel.styles';
import { AccordionContent as AccordionContentType } from '../../types';
import {
  AccordionComponentWrapper,
  AccordionContent,
  AccordionHeader,
  AccordionItemsSection,
  AccordionTitle,
  AccordionVisualizerWrapper,
  BlockWrapper,
} from './AccordionVisualizer.styles';

type AccordionVisualizerProps = {
  accordionContent: AccordionContentType;
};
interface AccordionComponentProps {
  title: string;
  description: string;
}

const AccordionVisualizer = ({
  accordionContent,
}: AccordionVisualizerProps) => {
  const { mainTitle, accordionItems } = accordionContent;
  const AccordionComponent = ({
    title,
    description,
  }: AccordionComponentProps) => {
    const [expanded, setExpanded] = React.useState(false);
    return (
      <AccordionComponentWrapper
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.code === 'Enter') {
            setExpanded(!expanded);
          }
        }}
      >
        <AccordionHeader
          expanded={expanded}
          data-testid="accordion-component-header"
          onClick={() => setExpanded(!expanded)}
        >
          <AccordionTitle>{title}</AccordionTitle>
          <ExpandMoreIcon />
        </AccordionHeader>
        <AccordionContent
          data-testid="accordion-component-content"
          expanded={expanded}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </AccordionComponentWrapper>
    );
  };

  return (
    <BlockWrapper>
      <AccordionVisualizerWrapper>
        <h2>{mainTitle}</h2>
        <AccordionItemsSection>
          {accordionItems.map(({ title, description }, idx) => (
            <AccordionComponent
              key={`${title}-${idx}`}
              title={title}
              description={description}
            />
          ))}
        </AccordionItemsSection>
      </AccordionVisualizerWrapper>
    </BlockWrapper>
  );
};

export default AccordionVisualizer;
