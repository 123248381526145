import * as React from 'react';
import { ListViewIcon, MapViewIcon } from 'Atoms/Icons';

export type Icons = 'listView' | 'mapView';

export const getTabIcon = (icon: Icons): JSX.Element => {
  const icons: Record<Icons, JSX.Element> = {
    listView: <ListViewIcon color="black" />,
    mapView: <MapViewIcon color="black" />,
  };

  return icons[icon];
};
