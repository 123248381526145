import styled from 'styled-components';

export const FullWidthWrapper = styled.div<{ scaledDownLayout?: boolean }>`
  width: 100%;
  text-align: center;
  height: auto;
  ${({ scaledDownLayout }) => `
    ${
      scaledDownLayout &&
      `
      width: 120%;
    `
    }
`}
`;
