import * as React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import { Button } from 'Molecules';
import { ProjectGaudi } from 'Shared/types';
import { LanguageSelector } from 'Client/components/organisms';
import {
  Wrapper,
  Content,
  HomeLink,
} from '../../ProposalPages/components/Masthead/Masthead.styles';

interface Props {
  pageTitle?: string;
  project: ProjectGaudi;
}

export const Masthead: React.FC<Props> = ({ pageTitle, project }: Props) => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <Wrapper data-testid="SurveyPagesTemplate-Masthead">
      <Content>
        <HomeLink aria-label="menu" onClick={() => router.push('/')}>
          <ArrowBackIcon fontSize="small" />
          <Button
            inverse
            link
            aria-label="masthead-back-button"
            className="survey-masthead-back-home-button"
            data-testid="SurveyMastheadBackHomeButton"
          >
            {pageTitle || t('Survey')}
          </Button>
        </HomeLink>
        {project?.features?.i18n && <LanguageSelector />}
      </Content>
    </Wrapper>
  );
};
