import * as React from 'react';
import { useFormik } from 'formik';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  useAnalytics,
  MixpanelEventTypes,
  useProject,
  useUser,
  useProposalContext,
} from 'Client/utils/hooks';
import { FormHelperText } from 'Atoms';
import { Button as ButtonBase } from 'Molecules';
import { QuestionRenderer } from 'Organisms';
import { PROPOSAL_ACTION_TYPES } from 'Client/context/proposalReducer';
import { updateContribution } from 'Client/services/contributions';
import type { EditFormProps } from '..';
import { areNoQuestionsAnswered } from '../utils';

/**
 * @deprecated
 * Used only in /confirm page when editting the user's comment before submitting.
 * Code is so outdated it should be rewritten from scatch.
 */
const EditCommentForm: React.FC<EditFormProps> = ({
  exitEditComment,
  answers,
  proposalSlug,
  contributionId,
}: EditFormProps) => {
  const [loading, setLoading] = React.useState(false);
  const [{ answers: proposalContextAnswers }, dispatch] = useProposalContext();
  const { t } = useTranslation();
  const { trackEvent } = useAnalytics();
  const { user } = useUser();
  const project = useProject();
  const [validationError, setValidationError] = React.useState('');

  React.useEffect(() => {
    trackEvent(MixpanelEventTypes.EDIT_RESPONSE, {
      url: `/proposals/${proposalSlug}/confirm`,
    });
  }, []);

  const formik = useFormik({
    initialValues: proposalContextAnswers,
    onSubmit: async (formValues) => {
      setLoading(true);
      const noQuestionsAnswered = areNoQuestionsAnswered(formValues);
      if (noQuestionsAnswered) {
        setValidationError(t('You need to respond to at least one question.'));
      } else {
        trackEvent(MixpanelEventTypes.SAVE_EDITED_RESPONSE, {
          url: `/proposals/${proposalSlug}/confirm`,
          hasEditedResponse: formValues !== proposalContextAnswers,
        });
        dispatch({
          type: PROPOSAL_ACTION_TYPES.SET_ANSWERS,
          answers: formValues,
        });
        const acornContributionData = {
          answers: formValues,
        };
        await updateContribution(contributionId, acornContributionData);
        if (project?.features?.trackContributionFlow) {
          trackEvent(MixpanelEventTypes.TRACK_CONTRIBUTION_FLOW, {
            fileName:
              'src/client/pages/proposals/components/EditCommentForm.tsx',
            functionName: 'EditCommentForm',
            database: 'acorn',
            fieldToBeUpdated: Object.keys(acornContributionData),
            gaudiUpdate: null,
            acornUpdate: acornContributionData,
            userId: user._id,
            demographicsId: 'none',
            contributionId: contributionId,
          });
        }
        setLoading(false);
        exitEditComment(false);
      }
    },
  });

  const cancelEditMode = () => {
    exitEditComment(areNoQuestionsAnswered(proposalContextAnswers));
  };

  const handleFieldChange = (e) => {
    setValidationError('');
    formik.handleChange(e);
  };

  return (
    <FullWidthContainer>
      <Form onSubmit={formik.handleSubmit} data-testid="EditCommentForm">
        {answers.map((answer) => {
          return (
            <QuestionWrapper key={answer.label}>
              <QuestionRenderer
                question={answer}
                edit
                value={formik.values[answer.id]}
                onChange={handleFieldChange}
                setFieldValue={formik.setFieldValue}
              />
            </QuestionWrapper>
          );
        })}
        {validationError && (
          <FormHelperText error={true} textAlign="center">
            {validationError}
          </FormHelperText>
        )}
        <ButtonsContainer>
          <CancelButton link onClick={cancelEditMode}>
            {t('Cancel')}
          </CancelButton>
          <SaveButton type="submit">
            {loading ? t('Updating...') : t('Save comment')}
          </SaveButton>
        </ButtonsContainer>
      </Form>
    </FullWidthContainer>
  );
};

export { EditCommentForm };

const FullWidthContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Form = styled.form`
  width: 100%;
  text-align: center;
`;

const CancelButton = styled(ButtonBase)`
  margin: 0 1rem;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin: 0.5rem 0;
  }
`;

const SaveButton = styled(ButtonBase)`
  margin: 0 1rem;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin: 0.5rem 0;
  }
`;

const QuestionWrapper = styled.div`
  text-align: center;
  margin: 1rem auto;
  max-width: 43.75rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
    margin-bottom: 1rem; /* 1rem plus the footer */
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 3rem;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      margin: 0.5rem 0;
      flex-direction: column-reverse;
      position: sticky;
      bottom: 0;
      background: ${theme.colors.white[500]};
      z-index: 2;
    }
  `}
`;
