import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { CommentCardOld, Container, Typography } from 'Atoms';
import { Button } from 'Molecules';
import { ConfirmationPageContentProps } from './types';
import { EditCommentForm, ConfirmationPageHeader } from './';

/**
 * @deprecated
 * See comment in ConfirmationPage.tsx
 */
export const ConfirmationPageContent: React.FC<
  ConfirmationPageContentProps
> = ({
  editComment,
  handleEditComment,
  mappedAnswers,
  proposalSlug,
  contributionId,
  exitEditComment,
}: ConfirmationPageContentProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      <ConfirmationPageHeader editComment={editComment} />
      {!editComment && (
        <Typography variant="body2">
          <Trans>
            {'Noticed any typos or missed a question? '}
            <Button onClick={handleEditComment} link removeDefaultStyling>
              Review your comment
            </Button>
            {' before continuing.'}
          </Trans>
        </Typography>
      )}
      {editComment ? (
        <EditCommentForm
          exitEditComment={exitEditComment}
          answers={mappedAnswers}
          proposalSlug={proposalSlug}
          contributionId={contributionId}
        />
      ) : (
        <>
          <CommentCardOld
            answers={mappedAnswers}
            contributionId={contributionId}
            isEditing={true} // passed in order to show the selected answers
          />
          <Button link onClick={handleEditComment}>
            {t('Edit comment')}
          </Button>
        </>
      )}
    </Container>
  );
};
