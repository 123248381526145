import * as React from 'react';
import styled, { css } from 'styled-components';
import { LogoTransparent } from 'Atoms/Icons';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.white[500]};

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
    }
  `};
`;

export const Content = styled(({ isBannerShown, ...props }) => (
  <div {...props} />
))`
  padding: 0 6rem 1.625rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.white[500]};
  font-family: Source Sans Pro, sans-serif;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      padding: 0 1rem 1.625rem;
    }
  `};
`;

export const ImageWrapper = styled.div`
  width: 50%;
  background-color: ${({ theme }) =>
    theme.colorMappings.loginBannerImageBackground};
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  `};
`;

export const LogoWrapper = styled.div`
  width: 100%;
  margin-top: 1.125rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      justify-content: center;
    }
  `};
`;

export const CommonplaceLogo = styled(({ ...props }) => (
  <LogoTransparent {...props} width={220} height={87} />
))``;

export const ButtonWrapper = styled.div`
  width: 100%;
  margin: 5rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const BackButton = styled.div`
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.4375rem;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.green[500]};
  margin: 1.375rem 0.75rem;
  cursor: pointer;

  svg {
    color: ${({ theme }) => theme.colors.green[500]};
    width: 1.125rem;
    height: 1.125rem;
    margin-right: 0.625rem;
  }
`;
