import * as React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { Link, Typography } from 'Atoms';
import { PageTypes } from 'Shared/types/page';
import { useProject } from 'Client/utils/hooks';
import { Banner } from 'Client/pages/contributions/components/Banner';
import { FinishedEngagementNoticeProps } from './types';

export const FinishedEngagementNotice: React.FC<
  FinishedEngagementNoticeProps
> = ({ proposal }) => {
  const project = useProject();

  return (
    <Description data-testid="FinishedEngagementNoticeId">
      <h3>
        <Trans>This engagement phase has finished</Trans>
      </h3>
      <Banner project={project} showHeading={false} proposal={proposal} hideCta>
        <p>
          <Trans>
            {'Check out the '}
            <Link
              noPadding
              to={`/contributions/${PageTypes.PROPOSAL}/${proposal.slug}`}
            >
              {'responses'}
            </Link>
          </Trans>
        </p>
      </Banner>
    </Description>
  );
};

const Description = styled((props) => <Typography {...props} />)`
  padding: 0 1.5rem;
  background: ${({ theme }) => theme.colorMappings.emailPanelBackground};
  font-size: 1.25rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;

  h3 {
    max-width: 18rem;
    margin: 3rem auto 0;
  }

  > * {
    justify-content: center;
    text-align: center;
  }

  p {
    margin-top: 0;
  }
`;
