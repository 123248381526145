import styled from 'styled-components';

export const SimpleHoverModal = styled.div`
  font-size: 0.8rem;
  background-color: ${({ theme }) => theme.colors.white[500]};
  width: 18rem;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.grey[700]};
  z-index: 999;
  position: absolute;
  box-shadow: 0 0 0.4375rem 0.0625rem rgba(0 0 0 / 15%);
`;
