import * as React from 'react';
import { Wrapper, Title, CurrentBar, Modal } from './Button.styles';
import { ButtonProps } from '../../types';

export const Button: React.FC<ButtonProps> = ({
  name,
  current,
  isOpen,
  children,
  onClick,
  isActive,
}: ButtonProps) => {
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  return (
    <Wrapper
      current={current}
      isOpen={isOpen}
      onClick={(e) => isActive && onClick(e)}
      data-testid={`Navbar-Button-${name}`}
      onMouseOver={() => setOpenModal(true)}
      onMouseOut={() => setOpenModal(false)}
    >
      <CurrentBar isOpen={isOpen} />
      {children}
      {isOpen && <Title current={current}>{name}</Title>}
      {openModal && !isActive && <Modal>Please select a customer</Modal>}
    </Wrapper>
  );
};
