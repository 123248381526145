import styled, { css } from 'styled-components';
import { editModeMobile } from 'Client/components/theme/utils';

export const Wrapper = styled.div<{ isEditModeLayout?: boolean }>`
  ${({ theme, isEditModeLayout }) => css`
    width: 100%;
    padding: ${theme.screenPadding};
    padding-top: 3.5rem;
    max-width: ${theme.container.width};

    p {
      margin: 0;
    }

    ${isEditModeLayout
      ? editModeMobile(
          theme.breakpoints.down('sm'),
          css`
            padding-top: 1rem;
            padding-bottom: 1rem;
          `
        )
      : editModeMobile(
          theme.breakpoints.down('sm'),
          css`
            padding-top: 2.5rem;
          `
        )}
  `}
`;

export const TextContent = styled.div<{ isMobile: boolean }>`
  ${({ isMobile }) => `
  font-size: 1.125rem; //18px
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.625rem;
  ${
    isMobile
      ? `
      flex-direction: column;
      & > div {
        width: 100%;
      }
    `
      : `
      flex-direction: row;
      & > div {
        width: calc(50% - 0.625rem);
      }
    `
  }
  `};
`;
