import * as React from 'react';
import styled from 'styled-components';
import { ChevronIcon } from 'Atoms/Icons';

export const Wrapper = styled.div<{
  type: string;
  current: boolean;
  isOpen: boolean;
}>`
  width: 100%;
  height: ${({ type }) => (type === 'nested' ? '2.5rem' : '3rem')};
  /* !important needed because appcues overwrites display */
  display: flex !important;
  align-items: center;
  ${({ theme, current }) =>
    current
      ? `background-color: ${theme.colorMappings.dashboardNavBarYellowHover}`
      : ''}
  ${({ theme, type }) =>
    type === 'nested' &&
    `background-color: ${theme.colorMappings.dashboardNavBarYellowHover}`}
`;

export const Title = styled.div<{ type: string }>`
  margin-left: ${({ type }) => (type === 'nested' ? '2.875rem' : ' 0.875rem')};
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.1875rem;
  color: black;
`;

export const CurrentBar = styled.div<{
  current: boolean;
  isOpen: boolean;
  isAppcues: boolean;
  type: string;
}>`
  background-color: ${({ current, isOpen }) =>
    current && !isOpen ? 'black' : 'transparent'};
  margin-right: ${({ isAppcues }) => (isAppcues ? '0.7rem' : '1.125rem')};
  width: 0.25rem;
  height: 100%;
`;

export const StyledArrow = styled((props) => <ChevronIcon {...props} />)<{
  current: boolean;
}>`
  transform: ${({ current }) => (current ? 'rotate(90deg)' : 'rotate(270deg)')};
  color: black;
  width: 0.625rem;
  height: 0.625rem;
`;

export const ArrowWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: right;
  margin-right: 0.8125rem;
`;
