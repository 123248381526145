import * as React from 'react';
import { Droppable, DroppableStateSnapshot } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { DraggableSection } from 'Organisms';
import { ProposalSection, Question } from '..';
import { BreakPageElement } from './PageBreak';
import { ProposalSectionRender } from './ProposalSectionRender';
import {
  getAllPreviousSectionsLength,
  getContentTypeBySection,
} from '../utils';

interface Props {
  steps: Array<ProposalSection<Question>>;
  onSectionClick?: (t: string, i: number) => void;
}

const hasOneEmptyStep = (steps: Array<ProposalSection<Question>>): boolean =>
  steps &&
  steps.length === 1 &&
  (!steps[0]?.sections || steps[0]?.sections.length === 0);

const hasEmptyArrayOfSteps = (
  steps: Array<ProposalSection<Question>>
): boolean => steps && steps.length === 0;

export const AllProposalStepsComponent: React.FC<Props> = ({
  steps,
  onSectionClick,
}: Props) => {
  return (
    <Droppable droppableId="existing-proposal-sections">
      {(providedDrop, snapshotDrop: DroppableStateSnapshot) => (
        <div
          ref={providedDrop.innerRef}
          data-testid="all-steps-proposal-wrapper"
        >
          {(!steps ||
            hasOneEmptyStep(steps) ||
            hasEmptyArrayOfSteps(steps)) && (
            <DraggableSection
              key="step0"
              id="step0"
              index={0}
              isDraggingOver={snapshotDrop.isDraggingOver}
            >
              <span>Drag a new section block here</span>
            </DraggableSection>
          )}
          {[...steps]
            ?.sort((a, b) => (a.order > b.order ? 1 : -1))
            .map(
              (
                {
                  title,
                  description,
                  question,
                  sections: nestedSections,
                  image,
                  imageComparisonSlider,
                }: ProposalSection<Question>,
                index: number
              ) => {
                const previousSectionsLength = getAllPreviousSectionsLength(
                  steps,
                  index
                );
                const sortedSections = [...nestedSections]?.sort((a, b) =>
                  a.order > b.order ? 1 : -1
                );
                const id = `step${index + 1}`;
                const currentSectionsLength = sortedSections.length;
                return sortedSections ? (
                  <Step id={id} key={id}>
                    {sortedSections.map(
                      (
                        nestedSection: ProposalSection<Question>,
                        nestedIndex: number
                      ) => {
                        const naturalIndex =
                          previousSectionsLength + nestedIndex;
                        return (
                          <DraggableSection
                            key={`step${nestedIndex + 1}`}
                            id={id}
                            index={naturalIndex}
                            isDraggingOver={snapshotDrop.isDraggingOver}
                          >
                            <ProposalSectionRender
                              backgroundColour={nestedSection.backgroundColour}
                              key={`step${nestedIndex + 1}`}
                              onClick={
                                onSectionClick
                                  ? () =>
                                      onSectionClick(
                                        getContentTypeBySection(nestedSection),
                                        naturalIndex
                                      )
                                  : () => null
                              }
                              title={nestedSection.title}
                              description={nestedSection.description}
                              question={nestedSection.question}
                              steps={steps}
                              image={nestedSection.image}
                              imageComparisonSlider={
                                nestedSection.imageComparisonSlider
                              }
                              accordionContent={nestedSection.accordionContent}
                              imageAndGrid={nestedSection.imageAndGrid}
                              twoColumns={nestedSection.twoColumns}
                              navMap={nestedSection.navMap}
                              showProgressBar={nestedIndex === 0}
                              files={nestedSection.files}
                              pagePreview={nestedSection.pagePreview}
                              imageMapPro={nestedSection.imageMapPro}
                              sectionId={
                                question?.id ??
                                nestedSection?.question?.id ??
                                nestedSection?._id
                              }
                            />
                          </DraggableSection>
                        );
                      }
                    )}
                    <BreakPageElement
                      renderCondition={index < steps.length - 1}
                      id={id}
                      naturalIndex={
                        previousSectionsLength + currentSectionsLength
                      }
                      onSectionClick={onSectionClick}
                      isDraggingOver={snapshotDrop.isDraggingOver}
                    />
                  </Step>
                ) : (
                  // old sections schema/deprecated:
                  <Step id={id} key={id}>
                    <ProposalSectionRender
                      key={id}
                      title={title}
                      description={description}
                      question={question}
                      steps={steps}
                      image={image}
                      imageComparisonSlider={imageComparisonSlider}
                      showProgressBar
                    />
                  </Step>
                );
              }
            )}
        </div>
      )}
    </Droppable>
  );
};

const Step = styled.div`
  width: 100%;
  height: fit-content;

  &:last-child {
    margin-bottom: 5rem;
  }
`;
