import * as React from 'react';
import NextHead from 'next/head';
import { useProject } from 'Client/utils/hooks';
import { OpenGraphProps } from 'Shared/types';

interface HeadProps {
  openGraphInfo?: OpenGraphProps;
}

const HeadMeta: React.FC<HeadProps> = ({ openGraphInfo }) => {
  const project = useProject();
  if (!project) return;

  const info = openGraphInfo ||
    project?.openGraph || { title: '', description: '' };

  return (
    <NextHead>
      {info?.title && (
        <meta key="og:title" property="og:title" content={info?.title} />
      )}
      {info?.description && (
        <meta
          property="og:description"
          key="og:description"
          content={info?.description}
        />
      )}
      {info?.image && (
        <meta property="og:image" key="og:image" content={info?.image} />
      )}
    </NextHead>
  );
};

export { HeadMeta };
