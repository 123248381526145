import styled from 'styled-components';

export const BlockWrapper = styled.div`
  width: 100%;
  max-width: unset;
  padding: 0;
  display: flex;
  justify-content: center;
  padding: 1rem 1.5rem;

  .keep-padding {
    padding: 0 1.5rem;
  }
`;
export const AccordionVisualizerWrapper = styled.div`
  text-align: left;
  max-width: 45rem;
  width: 100%;
  & > h2 {
    width: 100%;
    word-break: break-word;
  }
`;

export const AccordionItemsSection = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => `
    ${theme.breakpoints.down('sm')}{
      gap: 1rem;
    }
    ${theme.breakpoints.up('sm')}{
      gap: 1.5rem;
    }
  `}
`;

export const AccordionComponentWrapper = styled.div`
  ${({ theme }) => `
  width: 100%;
  box-shadow: 0rem 0.125rem 0.5rem rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  border: 1px solid black;
  transition: height 0.5s ease;
  &:focus {
    outline-color: ${theme.colorMappings.focusHighlight};
    outline-style: solid;
    outline-offset: 0.5rem;
    outline-width: 0.1875rem;
  }
  `}
`;

export const AccordionHeader = styled.div<{ expanded: boolean }>`
  ${({ theme, expanded }) => `
    cursor: pointer;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    ${theme.breakpoints.down('sm')}{
      padding: 1rem 1.5rem;
    }
    ${theme.breakpoints.up('sm')}{
      padding: 1.5rem 1rem;
    }

    justify-content: space-between;
    background: ${theme.colorMappings.white};
    position: relative;

    & > svg {
      transition: 0.5s ease;
    }

    ${
      expanded
        ? `
          & > svg{
            transform: rotate(180deg);
          }
          ${theme.breakpoints.down('sm')}{
            &::after {
              content: '';
              position: absolute;
              width: 100%;
              height: 0.125rem;
              background: linear-gradient(90deg, ${
                theme.colors.black[500]
              } 11.01%, #E8E8E8 100%);
              bottom: 0;
              left: 0;
            }
          }
        `
        : ``
    }
  `}
`;

export const AccordionTitle = styled.label`
  ${({ theme }) => `
  width: 100%;
  word-break: break-word;
  font-size: 1rem;
  font-weight: 700;
  color: ${theme.colorMappings.formFieldLabel};
  cursor: pointer;
  font-weight: 700;
  `}
`;

export const AccordionContent = styled.div<{
  expanded: boolean;
}>`
  ${({ theme, expanded }) => `
    box-sizing: border-box;
    max-height: 0rem;
    transition: 0.5s ease;
    overflow: hidden;
    ${theme.breakpoints.up('sm')} {
      padding: 0 1rem;
    }
    ${theme.breakpoints.down('sm')} {
      padding: 0 1.5rem;
    }
    border-radius: 0.5rem;
    & > p:first-child {
      margin-top: 0;
    }
    & > p, pre {
      word-break: break-word;
      font-size: 1rem;
    }
    .fr-green{
      color: green;
    }
    .fr-strong{
      font-weight: 700;
    }
    ${
      expanded
        ? `
          ${theme.breakpoints.up('sm')} {
            padding: 0 1rem 0.75rem 1rem;
            background: ${theme.colorMappings.white};
          }
          max-height: fit-content;

          ${theme.breakpoints.down('sm')} {
            padding: 1rem 1.5rem;
            background: ${theme.colorMappings.controlCenterContentBackground};
          }
        `
        : `

        `
    }
  `}
`;
