import { MenuItem } from 'Client/components/organisms/ProjectNavigation/types';
import { OpenGraphProps } from 'Shared/types';

export enum MilestoneStageType {
  ACTIVE = 'active',
  COMPLETED = 'completed',
  PLANNED = 'planned',
  DRAFT = 'draft', // deprecated, not used
}
export interface MilestoneItemProps {
  order: number;
  name: string;
  description: string;
  stage: MilestoneStageType;
  startDate: string;
  endDate: string;
  icon?: string;
  _id: string;
}

export interface MilestonesPageProps {
  title: string;
  description: string;
  milestones: Array<MilestoneItemProps>;
  openGraphInfo: OpenGraphProps;
  navbarItems?: MenuItem[];
}
