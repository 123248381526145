import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import MuiBox from '@material-ui/core/Box';
import MuiTypography from '@material-ui/core/Typography';
import { Container, FullWidthUnderline } from 'Atoms';
import {
  CustomProposalCard,
  ProposalsReel,
} from 'Client/pages/hub/components/DynamicProposals/components';
import { PlanningApps } from '../../hub/components';
import { OtherProposalsReelProps } from '../';

export const OtherProposalsReel: React.FC<OtherProposalsReelProps> = ({
  proposals,
  planningApps,
  showUnderline = true,
  isMultiStep = false,
  ...props
}: OtherProposalsReelProps) => {
  const { t } = useTranslation();
  const [filteredPlanApps, setFilteredPlanApps] = React.useState(planningApps);

  return (
    <Wrapper hideOnPrint={!isMultiStep}>
      {showUnderline && <FullWidthUnderline mb={2} mt={3} />}
      <Container data-testid="OtherProposalsReelContainer" {...props}>
        <MuiBox textAlign="center">
          <ReelHeader variant="h2">{t('Discover more')}</ReelHeader>
        </MuiBox>
        {planningApps ? (
          <PlanningApps
            planningApps={filteredPlanApps}
            hideHubHeader
            setFilteredPlanningApps={setFilteredPlanApps}
          />
        ) : (
          <ProposalsReel
            proposalsCount={proposals.length}
            showCardsCounter={false}
          >
            {proposals.map((proposal, index) => (
              <CustomProposalCard
                key={index}
                proposal={proposal}
                data-testid="dynamic-proposal-card"
              />
            ))}
          </ProposalsReel>
        )}
      </Container>
    </Wrapper>
  );
};

export const Wrapper = styled.div<{ hideOnPrint?: boolean }>`
  ${({ hideOnPrint }) =>
    hideOnPrint &&
    css`
      @media print {
        display: none;
      }
    `}
`;

export const ReelHeader = styled(MuiTypography)`
  padding: 1rem 0;
  margin-top: 0.5rem;
  display: inline-block;
  font-size: 1.5rem;
`;
