import * as React from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { useAnalytics, MixpanelEventTypes } from 'Client/utils/hooks';
import { setCurrentTab } from 'Client/utils/reduxReducers/projectCentre/projectCenterReducer';
import { RootState } from 'Client/redux-store';
import { formatCustomerNameForURL } from 'Client/components/organisms/Masthead/utils/formatCustomerNameForURL';
import { Button } from './components';
import { buttonsObj } from './constants/buttons';
import {
  Wrapper,
  ToggleButton,
  ButtonsDiv,
  StyledChevron,
  ClickableDivTop,
  ClickableDivBottom,
} from './NavBar.styles';

export const NavBar: React.FC = () => {
  const router = useRouter();
  const { query } = router;
  const { trackEvent } = useAnalytics();
  const dispatch = useDispatch();
  const { currentTab, selectedCustomer } = useSelector(
    (state: RootState) => state.projectCentre
  );
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    // Specially for charts
    // a window resize event needs to be triggered when changing navbar width
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 200);
  }, [isOpen]);

  const handleOpenNavBar = () => {
    setIsOpen((previous) => !previous);
  };

  const handleRedirect = (
    e: React.MouseEvent<HTMLElement>,
    url: string,
    name: string
  ) => {
    e.preventDefault();
    if (url) {
      dispatch(setCurrentTab(name));
      trackEvent(MixpanelEventTypes.CLICKED_LINK, { path: url });

      if (typeof window !== 'undefined') {
        const param = formatCustomerNameForURL(query.customer);
        window.location.href = `${window.location.origin}/${url}?customer=${param}`;
      }
    }
  };

  return (
    <Wrapper isOpen={isOpen}>
      <ButtonsDiv>
        <ClickableDivTop onClick={() => handleOpenNavBar()} />
        {buttonsObj.map((button) => {
          return (
            <Button
              key={button.name}
              onClick={(e) => handleRedirect(e, button.url, button.name)}
              name={button.name}
              isOpen={isOpen}
              current={currentTab === button.name}
              isActive={button.isActive(selectedCustomer)}
            >
              {button.icon}
            </Button>
          );
        })}
        <ClickableDivBottom onClick={() => handleOpenNavBar()} />
      </ButtonsDiv>
      <ToggleButton isOpen={isOpen} onClick={() => handleOpenNavBar()}>
        <StyledChevron isOpen={isOpen} />
      </ToggleButton>
    </Wrapper>
  );
};
