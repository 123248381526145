import * as React from 'react';
import Select from 'react-select';
import { Wrapper, Text, Filter } from './InfoRow.styles';
import { InfoRowProps } from './types';

export const InfoRow: React.FC<InfoRowProps> = ({
  showFilter,
  onFilterChange,
  filterOptions,
  defaultValue,
}: InfoRowProps) => {
  return (
    <Wrapper data-testid="InfoRow-Wrapper">
      {showFilter && (
        <Filter>
          <Text bold={true}>Sort by:</Text>
          <Select
            classNamePrefix="react-select"
            defaultValue={defaultValue}
            options={filterOptions}
            isClearable={false}
            onChange={(e) => onFilterChange(e)}
          />
        </Filter>
      )}
    </Wrapper>
  );
};
