export enum PLANNING_APP_STATUS {
  ACTIVE = 'active',
  DONE = 'done',
  PLANNED = 'planned',
}

export const colorByStatus = {
  'Consultation open': 'planningAppConsultation',
  'Decision granted': 'planningAppDecisionGranted',
  'Decision refused': 'planningAppDecisionRefused',
  'Awaiting decision': 'planningAppAwaiting',
  'Awaiting committee': 'planningAppAwaiting',
  Appeal: 'planningAppAppeal',
  Withdrawn: 'planningAppWithdrawn',
};
