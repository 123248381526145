import styled, { css } from 'styled-components';
import {
  StyledButtonProps,
  getButtonColors,
} from 'Client/components/theme/utils/getButtonColors';

export const StyledButton = styled.button<StyledButtonProps>`
  ${({ theme, color = 'default' }) => css`
    font-weight: 700;
    padding: 0.625rem 1.25rem;
    border-radius: 4px;
    border: none;
    cursor: pointer;

    ${getButtonColors(theme, color)}
  `}
`;
