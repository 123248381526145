import * as React from 'react';
import {
  Wrapper,
  Title,
  CurrentBar,
  StyledArrow,
  ArrowWrapper,
} from './Button.styles';
import { ButtonProps } from '../../types';

export const Button: React.FC<ButtonProps> = ({
  name,
  current,
  isOpen,
  children,
  onClick,
  id,
  type,
}: ButtonProps) => {
  return (
    <Wrapper
      current={current}
      isOpen={isOpen}
      onClick={(e) => onClick(e)}
      data-testid={`Navbar-Button-${name}`}
      id={id}
      type={type}
    >
      <CurrentBar
        isAppcues={name === 'Help'}
        isOpen={isOpen}
        current={current}
        type={type}
      />
      {children}
      {isOpen && <Title type={type}>{name}</Title>}
      {type === 'menu' && isOpen && (
        <ArrowWrapper>
          <StyledArrow current={current} />
        </ArrowWrapper>
      )}
    </Wrapper>
  );
};
