import * as React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import {
  useAnalytics,
  MixpanelEventTypes,
  useProject,
  useUser,
} from 'Client/utils/hooks';
import { LogoName } from 'Atoms/Icons';
import { makeRedirectDomain } from 'Client/utils/url';
import { cpBrandName } from 'Client/constants/brand';
import { formatCustomerNameForURL } from 'Client/components/organisms/Masthead/utils/formatCustomerNameForURL';
import { Button } from './components';
import { buttonsObj } from './constants/buttons';
import {
  Wrapper,
  Header,
  ToggleButton,
  ButtonsDiv,
  StyledChevron,
  ClickableDivTop,
  ClickableDivBottom,
} from './NavBar.styles';
import { NavBarProps, ButtonProps } from './types';

export const NavBar: React.FC<NavBarProps> = ({
  currentTab,
  navbarOpened,
  setNavbarOpened,
}: NavBarProps) => {
  const router = useRouter();
  const { user } = useUser();
  const project = useProject();
  const redirectDomain = makeRedirectDomain(project.id);

  const { trackEvent } = useAnalytics();
  const { t } = useTranslation();
  const [openButtonMenu, setOpenButtonMenu] = React.useState<string>(null);
  const [current, setCurrent] = React.useState<string>(currentTab);

  React.useEffect(() => {
    // Specially for charts
    // a window resize event needs to be triggered when changing navbar width
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 200);
  }, [navbarOpened]);

  const handleOpenNavBar = () => {
    setNavbarOpened((previous) => !previous);
  };

  const handleRedirect = (
    e: React.MouseEvent<HTMLElement>,
    url: string,
    name: string,
    type?: string
  ) => {
    e.preventDefault();
    trackEvent(MixpanelEventTypes.CLICKED_LINK, { path: url });

    if (type === 'nested') {
      if (name === 'Manage Team') {
        const customerForURL = formatCustomerNameForURL(project.customer);
        document.location.href = `http://${redirectDomain}${url}${customerForURL}`;
        return;
      }
      document.location.href = `http://${redirectDomain}${url}`;
      return;
    }

    setCurrent(name);
    router.push(url);
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>, name: string) => {
    e.preventDefault();
    if (!navbarOpened) {
      setNavbarOpened(true);
    }
    setOpenButtonMenu((previous) => (previous === name ? null : name));
  };

  return (
    <Wrapper isOpen={navbarOpened}>
      <Header onClick={() => handleOpenNavBar()}>
        <img
          src="https://res.cloudinary.com/commonplace-digital-limited/image/upload/q_auto,f_auto,w_25,h_29,c_fill/Commonplace/commonplace-icon-green.png"
          alt={t('{{cpBrandName}} logo', { cpBrandName })}
          width={25}
          height={29}
        />
        {navbarOpened && <LogoName />}
      </Header>
      <ButtonsDiv>
        <ClickableDivTop onClick={() => handleOpenNavBar()} />
        {buttonsObj.map((button: ButtonProps) => {
          if (button.type === 'menu') {
            return (
              <>
                <Button
                  key={button.name}
                  onClick={(e) => handleOpenMenu(e, button.name)}
                  name={button.name}
                  isOpen={navbarOpened}
                  current={current === button.name}
                  id={button.name}
                  type={button.type}
                >
                  {button.icon}
                </Button>

                {openButtonMenu === button.name &&
                  navbarOpened &&
                  button.nestedButtons.map((nb: ButtonProps) => {
                    if (nb?.checkPermissions && !nb?.checkPermissions(user)) {
                      return;
                    }

                    return (
                      <Button
                        key={nb.name}
                        onClick={(e) =>
                          handleRedirect(e, nb.url, nb.name, nb.type)
                        }
                        name={nb.name}
                        isOpen={navbarOpened}
                        current={current === nb.name}
                        id={nb.name}
                        type={nb.type}
                      />
                    );
                  })}
              </>
            );
          }

          if (
            // blocks contributions for PAPPs
            button?.checkPermissions &&
            button?.checkPermissions(user, project)
          ) {
            return;
          }

          return (
            <Button
              data-onboarding={`dashboard-left-nav-${button.name.toLocaleLowerCase()}-section`}
              key={button.name}
              onClick={(e) => handleRedirect(e, button.url, button.name)}
              name={button.name}
              isOpen={navbarOpened}
              current={current === button.name}
              id={button.name}
            >
              {button.icon}
            </Button>
          );
        })}
        <ClickableDivBottom
          data-onboarding="dashboard-left-nav"
          onClick={() => handleOpenNavBar()}
          key="clickable-div"
        />
      </ButtonsDiv>
      <ToggleButton onClick={() => handleOpenNavBar()}>
        <StyledChevron isOpen={navbarOpened} />
      </ToggleButton>
    </Wrapper>
  );
};
