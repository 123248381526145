import { darken } from 'polished';
import * as React from 'react';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ isPanelOpen: boolean }>`
  ${({ theme, isPanelOpen }) => css`
    transition: height 0.2s ease-out;
    background-color: ${theme.colorMappings.emailPanelBackground};
    height: ${isPanelOpen ? 'auto' : '2.75rem'};
    border-radius: 0.6rem 0.6rem 0 0;
    position: absolute;
    bottom: ${theme.proposals.footer.height};
    width: calc(100% - 3rem);
    left: 1.5rem;
    text-align: center;
    ${theme.breakpoints.down('xs')} {
      width: 100%;
      left: 0;
    }
  `}
`;

export const PrivacyPolicy = styled.p`
  text-align: center;
`;

export const IconList = styled.dl`
  display: grid;
  row-gap: 0.5rem;
`;

export const IconListIcon = styled(({ icon, ...props }) => (
  <dd {...props}>{icon}</dd>
))`
  margin: 0;
  grid-column: 1;
  padding: 0.125rem 0.5rem 0 0;
`;

export const IconListText = styled.dt`
  grid-column: 2;
`;

export const Content = styled.div<{ hidden: boolean }>`
  text-align: left;

  h3 {
    font-weight: normal;
    margin: 2rem 0;

    img {
      float: left;
      margin: -2.5rem 1rem 1rem;
    }

    > span,
    b {
      display: block;
    }

    b {
      color: ${({ theme }) =>
        darken(0.05, theme.colorMappings.CommonplaceBrand)};
    }
  }

  max-width: 27rem;
  margin: 1rem auto;

  ${({ hidden }) =>
    hidden
      ? css`
          display: none;
        `
      : css``}
`;
