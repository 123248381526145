import styled from 'styled-components';

export const Wrapper = styled.div<{ color?: string }>`
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 0.25rem 0 0 0.25rem;
  width: 100%;
  cursor: pointer;
`;

export const ChildProjects = styled.div`
  width: 100%;
  display: flex;

  & > div {
    width: 100%;
  }
`;

export const ChildProjectWrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const Dash = styled.span`
  border-top: 0.125rem dashed ${({ theme }) => theme.colors.grey[400]};
  width: 1%;
  height: 0.125rem;
  align-self: center;
`;

export const VerticalDash = styled.span<{ height: number }>`
  border-right: 0.125rem dashed ${({ theme }) => theme.colors.grey[400]};
  width: 3%;
  height: ${({ height }) => height}px;
  margin-top: -5.4375rem;
  align-self: center;
`;
