export const glaPlanningAppsStatuses = [
  { label: 'Awaiting validation', color: '#666666' },
  { label: 'Awaiting decision', color: '#666666' },
  { label: 'Awaiting hearing', color: '#666666' },
  { label: 'Awaiting Mayor comment', color: '#8860FA' },
  { label: 'Stage 1 issued', color: '#00AC5D' },
  { label: 'Awaiting Stage 2', color: '#000000' },
  { label: 'Called in for hearing', color: '#FFD000' },
  { label: 'Decision issued', color: '#00AC5D' },
  { label: 'Decision granted', color: '#00AC5D' },
  { label: 'Decision refused', color: '#DC2475' },
  { label: 'Public inquiry required', color: '#FFD000' },
  { label: 'Public inquiry not required', color: '#00AC5D' },
  { label: 'Under consultation', color: '#FFD000' },
  { label: 'Withdrawn', color: '#75C5EE' },
];

export const glaPlanningAppsStages = [
  'Stage 1',
  'Stage 2',
  'Stage 3',
  'No Strategic Issues',
  'Stopping up order',
];

export const glaPlanningAppsCategories = [
  {
    label: 'Amended application',
    colors: { primary: '#B0B0B0', secondary: '#DFDFDF', tertiary: '#F5F5F5' },
  },
  {
    label: 'Car parking',
    colors: { primary: '#FFA756', secondary: '#FFE2C8', tertiary: '#FFF3E9' },
  },
  {
    label: 'Green belt',
    colors: { primary: '#3BE46A', secondary: '#BCF9CD', tertiary: '#E0FBE7' },
  },
  {
    label: 'Impacts strategic site',
    colors: { primary: '#FF6D6D', secondary: '#FFC9C9', tertiary: '#FFF1F1' },
  },
  {
    label: 'Loss of employment use',
    colors: { primary: '#EA70E5', secondary: '#F3CEF2', tertiary: '#FFEEFE' },
  },
  {
    label: 'Loss of homes',
    colors: { primary: '#9990FF', secondary: '#D7D3FF', tertiary: '#F1F0FE' },
  },
  {
    label: 'Loss of playing field',
    colors: {
      primary: '#46B3EF',
      secondary: '#C8E9FC',
      tertiary: '#F2FAFF',
    },
  },
  {
    label: 'Mining operations',
    colors: { primary: '#10EAB5', secondary: '#BFFFF0', tertiary: '#E7FFF9' },
  },
  {
    label: 'Non-residential',
    colors: {
      primary: '#5891FF',
      secondary: '#D2E2FF',
      tertiary: '#EDF3FF',
    },
  },
  {
    label: 'Residential',
    colors: { primary: '#F8E116', secondary: '#FFF9C5', tertiary: '#FFFDE9' },
  },
  {
    label: 'See proposal',
    colors: { primary: '#B656CE', secondary: '#ECCEF3', tertiary: '#FAE8FF' },
  },
  {
    label: 'Tall buildings',
    colors: { primary: '#E9843B', secondary: '#FFD8BB', tertiary: '#FFECDE' },
  },
  {
    label: 'Transport infrastructure',
    colors: { primary: '#84F928', secondary: '#E6FFD1', tertiary: '#F0FFE3' },
  },
  {
    label: 'Waste / recycling',
    colors: { primary: '#FFC634', secondary: '#FFECBC', tertiary: '#FFF9E9' },
  },
  {
    label: 'Stopping up order',
    colors: { primary: '#D594BE', secondary: '#F5CAE6', tertiary: '#FFE7F6' },
  },
  {
    label: 'Undefined',
    colors: { primary: '#B0B0B0', secondary: '#F1F1F1', tertiary: '#F5F5F5' },
  },
];
