import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from 'Atoms';
import { MessageCircleLinesIcon } from 'Atoms/Icons';
import { Wrapper, Button } from './Cta.styles';
import { BannerCtaProps as Props } from '../types';

export const Cta: React.FC<Props> = (props: Props) => {
  const { title, onClick: handleClick } = props;
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Trans>
        <Typography>
          {'Be the first to have your say about'}
          <div>
            <strong>{title}</strong>
          </div>
          {''}
        </Typography>
      </Trans>

      <Button onClick={handleClick}>
        <MessageCircleLinesIcon bold />
        {t('Have your say')}
      </Button>
    </Wrapper>
  );
};
